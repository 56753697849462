import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import ModalDialog from "../meters/ModalDialog";
import Autocomplete from "@mui/material/Autocomplete";
import { store } from "../../redux/store";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import Select from "@mui/material/Select";
import CsvParser from "./CsvParser";
import { Typography, TextField } from "@mui/material";
const GatewayCommands = ({ id }) => {
  const { t } = useTranslation();
  const attributes = useSelector((state) => state?.meterSlice?.commands);
  const [open, setOpen] = React.useState(false);
  const commands = useSelector((state) => state?.meterSlice?.commands)
    ?.map((item) => ({
      label: item?.attributes?.name,
      type: item?.attributes?.type,
    }))
    ?.filter((cmd) => !window?.DISABLED_COMMANDS?.includes(cmd.label));

  const [getCommands] = store.useLazyGetMeterAttributesQuery();
  const dispatch = useDispatch();
  const [command, setCommand] = React.useState("");

  const handleChange = (e) => {
    setCommand(e.target.value);
  };

  const onSubmitClick = () => {
    if (command === t("set_white_list")) {
      setOpen(true);
    }
  };

  return (
    <>
      <ModalDialog
        open={open}
        close={() => setOpen(false)}
        title={"Carica csv"}
      >
        <Box height={240} width={400}>
          <CsvParser id={id} closeFn={() => setOpen(false)} />
        </Box>
      </ModalDialog>
      <Box sx={{ minWidth: 120, display: "flex", mt: 4 }}>
        <Autocomplete
          fullWidth
          disablePortal
          value={command}
          onChange={(event, newValue) => {
            setCommand(newValue);
          }}
          id="combo-box-demo"
          options={commands ?? []}
          renderInput={(params) => <TextField {...params} label="Command" />}
        />
        <Button
          disabled={!command}
          onClick={onSubmitClick}
          sx={{ ml: 3 }}
          variant={window.BUTTON_TYPE}
        >
          {t("send_command")}
        </Button>
      </Box>

      {/* {commands?.length === 0 && (
        <Box sx={{ minWidth: 120, display: "flex", mt: 4 }}>
          <FormControl sx={{ width: 1600 }}>
            <InputLabel id="demo-simple-select-label">
              {t("command")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={command}
              label={t("command")}
              onChange={handleChange}
            >
              <MenuItem value={10}>{t("open_valve")}</MenuItem>
              <MenuItem value={20}>{t("close_valve")}</MenuItem>
              <MenuItem value={30}>{t("sync_time")}</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={onSubmitClick} sx={{ ml: 3 }} variant={window.BUTTON_TYPE}>
            {t("send_command")}
          </Button>
        </Box>
      )} */}
    </>
  );
};
export default GatewayCommands;
