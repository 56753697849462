import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import GraficCard from "../../components/form/formComponents/GraphicCard";
import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import {
  PieChart,
  Tooltip,
  Cell,
  LineChart,
  BarChart,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  Brush,
  ReferenceArea,
  ReferenceDot,
} from "recharts";
import { Box } from "@mui/system";
import { Fullscreen } from "@material-ui/icons";
import { Typography } from "@mui/material";

const BalanceGraph = ({ unit }) => {
  const { t } = useTranslation();
  const balanceValues = useSelector(
    (state) => state?.balanceSlice?.balanceValues
  );
  const headerAppBarOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const data = balanceValues?.map((item) => ({
    date: t("date_val", { val: new Date(item?.date) }),
    value: item?.in_meter_sum - item?.out_meter_sum,
    computed: item?.computed,
  }));

  return (
    <>
      {
        <>
          {balanceValues?.length > 0 && (
            <LineChart
              width={headerAppBarOpen ? 1500 : 1775}
              height={530}
              margin={{
                left: 0,
                top: 10,
                right: 30,
              }}
              data={data}
            >
              <Tooltip
                content={({ active, payload, label }) => {
                  const clen = payload;

                  if (payload?.[0]?.payload?.computed === false) {
                    return (
                      <GraficCard
                        value={`${t("value")}: ${t("number", {
                          val: payload?.[0]?.payload?.value,
                          maximumFractionDigits: 3,
                        })}`}
                        icon={
                          <CalculateIcon
                            fontSize="large"
                            sx={{ fill: "green" }}
                          />
                        }
                        date={payload?.[0]?.payload?.date}
                      />
                    );
                  }
                  if (payload?.[0]?.payload?.computed === true) {
                    return (
                      <GraficCard
                        value={`${t("value")}: ${t("number", {
                          val: payload?.[0]?.payload?.value,
                          maximumFractionDigits: 3,
                        })}`}
                        icon={
                          <OnlinePredictionIcon
                            fontSize="large"
                            sx={{ fill: "#8884d8" }}
                          />
                        }
                        date={payload?.[0]?.payload?.date}
                      />
                    );
                  }
                }}
              />

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                label={{
                  value: t("date"),
                  position: "insideBottom",
                  offset: 5,
                }}
                dataKey="date"
                angle={45}
                interval="preserveStartEnd"
                height={90}
                stroke={theme.palette.group_and_district.main}
                tickMargin={30}
                tickFormatter={(value) => {
                  return value;
                }}
              />

              <YAxis
                type="number"
                label={{
                  value: unit,
                  angle: -45,
                  position: "insideLeft",
                }}
                style={{ fontSize: "10px" }}
                stroke={theme.palette.group_and_district.main}
              />
              <Line
                type="monotone"
                dataKey="value"
                stroke="blue"
                isAnimationActive={false}
              />
            </LineChart>
          )}
          {balanceValues?.length < 1 && (
            <Typography variant="h4" align="center">
              {t("no_available_data")}
            </Typography>
          )}
        </>
      }
    </>
  );
};

export default BalanceGraph;
