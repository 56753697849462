import { useParams } from "react-router-dom";
import { store } from "../../redux/store";
import { useEffect } from "react";
import { useState } from "react";
import validator from "@rjsf/validator-ajv6";
import { Button, Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Form } from "@rjsf/mui";
import { setMessage } from "../../redux/slices/snackbarSlice";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { useDispatch, useSelector } from "react-redux";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ShowNote = () => {
  const note = useSelector((state) => state?.notesSlice?.note);
  const gateway = useSelector((state) => state?.gatewaySlice?.gateway);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updateNote, { isSuccess }] = store.useUpdateNoteMutation();
  const [formData, setFormData] = useState({});
  const { id, noteId } = useParams();
  const [getNote] = store.useLazyGetShowNoteQuery();
  useEffect(() => {
    getNote(noteId);
  }, []);
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      note: {
        $id: "1",
        type: "string",
        title: t("note"),
        default: note?.note,
      },
    },
    required: ["note"],
  };
  useEffect(() => {
    if (gateway && note) {
      debugger;
      dispatch(
        setBreadCrumbs({
          id: gateway?.serial_number,
          noteId: note?.note,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [gateway, note]);
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    note: {
      "ui:widget": "textarea",
    },
  };
  const onSubmitClick = () => {
    const attributes = {
      device_id: id,
      note: formData.note,
      meta: { key: "value" },
    };
    updateNote({ attributes: attributes, id: noteId });
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("successfully_updated")));
      getNote(noteId);
    }
  }, [isSuccess]);
  return (
    <>
      <Typography variant="h4">{`${t("gateway")}: ${
        gateway?.serial_number
      }`}</Typography>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container sx={{ mb: 2 }}>
            <Grid itex xs={6}>
              <Typography>{t("inserted_at")}</Typography>
              <Typography>
                {" "}
                {t("dt", {
                  val: new Date(note?.inserted_at),
                })}
              </Typography>
            </Grid>
            <Grid itex xs={6}>
              <Typography>{t("updated_at")}</Typography>
              <Typography>
                {" "}
                {t("dt", {
                  val: new Date(note?.updated_at),
                })}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box sx={{ mt: 4 }}>
        <Form
          schema={schema}
          formData={formData}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          /*  onSubmit={onSubmitClick} */
          validator={validator}
          uiSchema={uiSchema}
          /* widgets={widgets} */
          showErrorList={false}
          /*   customValidate={customValidate} */
          noHtml5Validate
          children={true}
        >
          <Button
            variant={window.BUTTON_TYPE}
            onClick={() => navigate(`/gateways/${id}`)}
            sx={{ mt: 2 }}
          >
            {t("back")}
          </Button>
          <Button
            variant={window.BUTTON_TYPE}
            onClick={onSubmitClick}
            sx={{ float: "right", mt: 2 }}
          >
            {t("update")}
          </Button>
        </Form>
      </Box>
    </>
  );
};
export default ShowNote;
