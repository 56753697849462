import { selectType } from "../dashboard/widgets/utils/widgetConfigurations";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
const WidgetPreview = ({ widget }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ml: 13,
      }}
    >
      <Typography variant="h4">{t("widget_preview")}</Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 7 }}>
        {selectType(widget, false, false, false)}
      </Box>
    </Box>
  );
};

export default WidgetPreview;
