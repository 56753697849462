import React, { useEffect } from "react";
import L from "leaflet";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import theme from "../../../theme";

import {
  setPreferenceMapcenter,
  setPreferenceMapZoom,
} from "../../../redux/slices/userPreferenceSlice";

import { store } from "../../../redux/store";
import {
  setClusterId,
  unSetClusterId,
} from "../../../redux/slices/clusterSlice";
import ReactDOMServer from "react-dom/server";
import {
  TileLayer,
  Popup,
  MapContainer,
  ScaleControl,
  Marker,
  Polygon,
  useMap,
  Circle,
  Tooltip as LeafletTooltip,
} from "react-leaflet";
import ClusterIcon from "../../ClusterIcon";
import DeviceIcon from "../../DeviceIcon";
import { Box } from "@material-ui/core";
import DeviceSvg from "../../../statics/icons/device.svg";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { getDistance } from "../../../utils";
const FormMap = ({
  hideAccordion,
  setPreferenceLoading,
  classValue,
  params,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setPreference, { isLoading }] = store.useSetPreferenceMutation();
  const [bounds, setBounds] = useState();
  const [center, setCenter] = useState([]);
  const [zoom, setZoom] = useState();
  delete params.type;
  const clusters = useSelector(
    (state) => state?.clusterSlice?.clusters
  )?.filter((item) => item?.id !== null);
  const selectedClusterId = useSelector(
    (state) => state?.clusterSlice?.selectedClusterId
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const preference = useSelector(
    (state) => state?.userPreferenceSlice?.preference
  );
  const devices = useSelector(
    (state) => state?.clusterSlice?.devicesOnGlobalMap
  );
  const userPreferenceUpdating = useSelector(
    (state) => state?.userPreferenceSlice?.updating
  );
  const debouncedRequest = useDebouncedCallback(() => {
    setPreference(preference);
  }, 5000);
  useEffect(() => {
    if (userPreferenceUpdating) {
      debouncedRequest();
    }
  }, [userPreferenceUpdating]);
  const [getCluster] = store.useLazyGetClusterQuery();
  const [getSingleClusterDevices] = store.useLazyGetSingleClusterDevicesQuery();
  const [getDevices] = store.useLazyGetDevicesQuery();
  const [curPos, setCurPos] = useState(null);

  const debounced = useDebouncedCallback(() => {
    setBounds(map.getBounds());
    let tempMap = map.getCenter();

    dispatch(setPreferenceMapcenter(tempMap));

    setZoom(map.getZoom());

    dispatch(unSetClusterId());
  }, 500);
  useEffect(() => {}, [curPos]);
  useEffect(() => {
    setPreferenceLoading(isLoading);
  }, [isLoading]);

  let distance;
  const map = useMap();

  const clusterIcon = (count, itemId) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(
        <ClusterIcon
          count={count}
          itemId={itemId}
          selectedClusterId={selectedClusterId}
        />
      ),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });

  const deviceIcon = (color) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<DeviceIcon color={color} />),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });
  const onDetailClick = (id) => {
    classValue ? navigate(`/gateways/${id}`) : navigate(`/meters/${id}`);
  };
  useEffect(() => {
    setZoom(map.getZoom());
    setBounds(map.getBounds());
  }, []);

  useEffect(() => {
    if (map) {
      map.on("zoomend", () => debounced());
      map.on("moveend", () => debounced());
      map.on("click", (e) => setCurPos(e.latlng));
    }
  }, [map]);

  useEffect(() => {
    if (bounds) {
      distance = getDistance(bounds);

      getCluster({
        ne_lat: bounds._northEast.lat,
        ne_lng: bounds._northEast.lng,
        sw_lng: bounds._southWest.lng,
        sw_lat: bounds._southWest.lat,
        zoom: distance,
        params: {
          class: classValue,
          params: params && { ...params },
        },
      });

      getDevices({
        ne_lat: bounds._northEast.lat,
        ne_lng: bounds._northEast.lng,
        sw_lng: bounds._southWest.lng,
        sw_lat: bounds._southWest.lat,
        zoom: distance,
        params: {
          class: classValue,
          params: params && { ...params },
        },
      });
    }
  }, [bounds, params]);
  /*   useEffect(() => {
    
  }, []); */

  const onSingleClusterClick = (id) => {
    distance = getDistance(bounds);
    getSingleClusterDevices({
      ne_lat: bounds._northEast.lat,
      ne_lng: bounds._northEast.lng,
      sw_lng: bounds._southWest.lng,
      sw_lat: bounds._southWest.lat,
      zoom: distance,
      id: id,
    });
    dispatch(setClusterId(id));
  };
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }, [hideAccordion, appBarIsOpen]);

  useEffect(() => {}, [appBarIsOpen]);

  useEffect(() => {
    if (zoom) {
      dispatch(setPreferenceMapZoom(zoom));
    }
  }, [zoom]);
  useEffect(() => {}, [center]);
  useEffect(() => {}, [zoom]);

  return (
    <>
      <ScaleControl />

      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {clusters?.map((item, i) => (
        <Marker
          eventHandlers={{
            click: () => {
              onSingleClusterClick(item?.id);
            },
          }}
          icon={clusterIcon(item?.attributes?.count, item?.id)}
          kei={i}
          position={[
            item?.attributes?.center?.coordinates?.[1],
            item?.attributes?.center?.coordinates?.[0],
          ]}
        ></Marker>
      ))}
      {devices?.map((item, i) => (
        <Marker
          icon={deviceIcon(
            item?.attributes?.fields?.alarms?.length > 0 ? "red" : "black"
          )}
          kei={i}
          position={[
            item?.attributes?.coordinates?.coordinates?.[1],
            item?.attributes?.coordinates?.coordinates?.[0],
          ]}
        >
          <Popup>
            <Box style={{ display: "flex" }}>
              <Typography>{`Serial number:  ${item?.attributes?.serial_number}`}</Typography>
              <IconButton
                sx={{ ml: 1 }}
                onClick={() => onDetailClick(item?.id)}
              >
                <Tooltip title="go to device">
                  <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                </Tooltip>
              </IconButton>
            </Box>
          </Popup>
        </Marker>
      ))}
    </>
  );
};

export default FormMap;
