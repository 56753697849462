import { method } from "lodash";
import { prepareParams } from "../../utils";
import { prepareBody, prepareBodyWithoutId } from "../../utils/api_params";
import { queries } from "@testing-library/react";

export const condominiumApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      condominiumIndex: build.query({
        query: (params) => {
          return `v1/plugin/condominiums?${prepareParams(params)}`;
        },
      }),
      condominiumShow: build.query({
        query: (id) => {
          return `v1/plugin/condominiums/${id}`;
        },
      }),
      // deleteUser: build.mutation({
      //   query: (id) => ({
      //     method: "DELETE",
      //     url: `v1/user/${id}`,
      //   }),
      // }),
      createCondominium: build.mutation({
        query: (attributes) => ({
          url: "v1/plugin/condominiums",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "device")),
        }),
      }),
      updateCondominium: build.mutation({
        // <<<<<<< HEAD
        query: ({ attributes, id }) => ({
          url: `v1/plugin/condominiums/${id}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, id, "device")),
          //TODO: verificare se togliere
          // =======
          //       query: (attributes) => ({
          //         url: `v1/altior/digital_twin/6/instance/${attributes.id}`,
          //         method: "PATCH",
          //         body: JSON.stringify(prepareBody(attributes, "device")),
          // >>>>>>> a040614ece5f526a3047f9b1156e9df1f981c7b9
        }),
      }),
      updateSubCondominum: build.mutation({
        // <<<<<<< HEAD
        query: ({ attributes, id }) => ({
          url: `v1/plugin/sub_condominiums/${id}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      updateApartment: build.mutation({
        // <<<<<<< HEAD
        query: ({ attributes, id }) => ({
          url: `v1/plugin/apartments/${id}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      createSubCondominium: build.mutation({
        query: (attributes) => ({
          url: "v1/plugin/sub_condominiums",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "device")),
        }),
      }),
      updateSubCondominium: build.mutation({
        query: ({ attributes, id }) => ({
          url: `v1/plugin/sub_condominiums/${id}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, id, "device")),
        }),
      }),
      createApartment: build.mutation({
        query: (attributes) => ({
          url: "v1/plugin/apartments",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      getCondominiumId: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/7/instance/${id}`;
        },
      }),
      getSubCondominiumId: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/7/instance/${id}`;
        },
      }),
      getSubCondominiumTopElement: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/7/instance/${id}`;
        },
      }),
      getSubCondominiumBotElement: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/7/instance/${id}`;
        },
      }),
      getApartamentId: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/7/instance/${id}`;
        },
      }),
      getMetertId: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/7/instance/${id}`;
        },
      }),
      associateMeter: build.mutation({
        query: ({ attributes, identifier }) => ({
          url: `v1/plugin/apartments/${identifier}/associate`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      associateGateway: build.mutation({
        query: ({ attributes, id }) => ({
          url: `v1/plugin/sub_condominiums/${id}/associate`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      updateCondominiumIfno: build.mutation({
        query: ({ instanceId, attributes, twinId }) => ({
          url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, instanceId, "devices")),
        }),
      }),
      createInstance: build.mutation({
        query: ({ twinId, attributes }) => ({
          url: `v1/altior/digital_twin/${twinId}/instance`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "devices")),
        }),
      }),
      getCondominiumReadings: build.query({
        query: (params) => ({
          url: `v1/message?${prepareParams(params)}`,
        }),
      }),
      getAssociatedUsers: build.query({
        query: (id) => ({
          url: `v1/altior/device/${id}/user?realtion_type=building_administrator`,
        }),
      }),
      getSubCondominiumReadings: build.query({
        query: (params) => ({
          url: `v1/message?${prepareParams(params)}`,
        }),
      }),
      getApartamentReadings: build.query({
        query: (params) => ({
          url: `v1/message?${prepareParams(params)}`,
        }),
      }),
      getCondominiumConsumptions: build.query({
        query: (params) => ({
          url: `v1/plugin/condominium_consumption?${prepareParams(params)}`,
        }),
      }),
      getSubcondominiumConsumtions: build.query({
        query: (params) => ({
          url: `v1/plugin/consumptions?${prepareParams(params)}`,
        }),
      }),
      getApartamentConsumtions: build.query({
        query: (params) => ({
          url: `v1/plugin/consumptions?${prepareParams(params)}`,
        }),
      }),
      updateCondominiumUpload: build.mutation({
        query: ({ id, attributes }) => ({
          url: `/v1/plugin/condominium_upload/${id}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      exportCondominiumReadings: build.mutation({
        query: (attributes) => ({
          url: `/v1/plugin/condominium_export`,
          method: "POST",
          body: JSON.stringify(prepareBody(attributes)),
          responseType: "blob",
        }),
        responseHandler: (response) =>
          response.blob().then((blob) => URL.createObjectURL(blob)),
      }),
      exportCondominiumConsumptions: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/condominium_consumption/${id}/export`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
          responseType: "blob",
        }),
        responseHandler: (response) =>
          response.blob().then((blob) => URL.createObjectURL(blob)),
      }),
      /* exportCondominiumConsumptions: build.query({
        query: ({ id, params }) => {
          return `/v1/plugin/condominium_consumption/${id}/export?${prepareParams(
            params
          )}`;
        },
      }), */
      deleteCondominium: build.mutation({
        query: (attributes) => ({
          url: "v1/plugin/condominium_delete",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      deleteApartment: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/apartments/${id}/delete_apartment`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      deleteSubblock: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/sub_condominiums/${id}/delete_sub_condominium`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      stopCondominiumImport: build.mutation({
        query: (id) => ({
          url: `v1/plugin/condominium_upload/${id}`,
          method: "DELETE",
        }),
      }),
      associateAdmin: build.mutation({
        query: ({ id, attributes }) => ({
          url: `/v1/altior/device/${id}/user`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "users")),
        }),
      }),
      disassocAdmin: build.mutation({
        query: (id) => ({
          url: `v1/plugin/condominium_administrator/${id}`,
          method: "DELETE",
        }),
      }),
      lockUsers: build.query({
        query: (id) => {
          return `v1/plugin/condominiums/${id}/locks`;
        },
      }),
      unlockUsers: build.query({
        query: (id) => {
          return `v1/plugin/condominiums/${id}/unlocks`;
        },
      }),
      getUsersStatus: build.query({
        query: (id) => {
          return `v1/plugin/condominiums/${id}/get_device_meta_data`;
        },
      }),
      disassociateApartment: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/association_code/${id}`,
        }),
      }),
      getFreeMeters: build.query({
        query: (params) => {
          return `v1/altior/device?${prepareParams(params)}`;
        },
      }),
      substitutionMeter: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/condominiums/${id}/substitution`,
          method: "POST",
          type: "plugin",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      getBlockStats: build.query({
        query: (id) => {
          return `v1/plugin/condominiums/${id}/stats`;
        },
      }),
      dissociateMeter: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/condominiums/${id}/dissociate_meter`,
          method: "POST",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      getTotalReadings: build.query({
        query: ({ id, params }) => ({
          url: `v1/plugin/condominiums/${id}/stats?${prepareParams(params)}`,
        }),
      }),
      getCalendatValues: build.query({
        query: ({ id, params }) => {
          return `v1/plugin/condominiums/${id}/montly_stats?${prepareParams(
            params
          )}`;
        },
      }),
      importHcaHistory: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/condominiums/${id}/import_hca_history`,
          method: "POST",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      createAssocMeter: build.mutation({
        query: (attributes) => ({
          url: `v1/plugin/condominium_meter`,
          method: "POST",
          body: JSON.stringify(attributes),
        }),
      }),
      createAndReplaceMeter: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/condominium_meter/${id}/replace`,
          method: "POST",
          body: JSON.stringify(attributes),
        }),
      }),
    }),
  });
/* export const {
  useLazyCondominiumIndexQuery,
  useLazyCondominiumShowQuery,
  useCreateCondominiumMutation,
  useCreateSubCondominiumMutation,
  useCreateApartmentMutation,
  useLazyGetCondominiumIdQuery,
  useLazyGetSubCondominiumIdQuery,
  useUpdateCondominiumMutation,
  useUpdateSubCondominiumMutation,
  useAssociateMeterMutation,
  useLazyGetCondominiumReadingsQuery,
  useLazyGetSubCondominiumReadingsQuery,
  useLazyGetApartamentReadingsQuery,
  useLazyGetCondominiumConsumptionsQuery,
  useLazyGetSubcondominiumConsumtionsQuery,
  useLazyGetApartamentConsumtionsQuery,
  useUpdateCondominiumIfnoMutation,
  useAssociateGatewayMutation,
  useLazyGetApartamentIdQuery,
  useLazyGetMetertIdQuery,
  useCreateInstanceMutation,
  useLazyGetSubCondominiumBotElementQuery,
  useLazyGetSubCondominiumTopElementQuery,
  useUpdateCondominiumUploadMutation,
} = condominiumApi; */
