import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import MapLeaflet from "./MapLeaflet";
import { deviseMessageCleanUp } from "../../redux/slices/deviceMessageSlice";
import { cleanData } from "../../redux/slices/meterReadingSlice";
import { consumptionsCleanUp } from "../../redux/slices/meterSlice";
import { setConsumRange } from "../../redux/slices/meterSlice";
import { useNavigate } from "react-router-dom";
import ManufacturerData from "./ManufacturerData";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { setMeterShow } from "../../redux/slices/meterSlice";
import { nameTransform } from "../../utils/utilsFunctions";
import LorawanCommandsTab from "./LorawanCommandsTab";
import { setHeatUnit, setColdUnit } from "../../redux/slices/meterSlice";
import MeterCommands from "./MeterCommands";
import ImpulsiveMeterRegistry from "./impulsiveMeter/ImpulsiveMeterRegistry";
import { setImpulseId } from "../../redux/slices/meterSlice";
import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import {
  setFieldsToUpdate,
  setMeterIdToUpdate,
} from "../../redux/slices/meterSlice";
import { setBtnDetector } from "../../redux/slices/condominiumSlice";
import { useDispatch } from "react-redux";
import theme from "../../theme";
import Registri from "./Registry";
import HeatCostTab from "./HeatCostTab";
import MeterConsumptions from "../form/MeterConsumptions";
import ReadignConsumptionsTab from "../form/ReadignConsumptionsTab";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import InfoCondominum from "./InfoCondominum";
import MeterReadingsTab from "../meters/MeterReadingsTab";
import MeterAlarmsTab from "./MeterAlarmsTab";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const LorawanServerShow = ({
  apartamentMeterId = null,
  enteredFromCondominium = false,
  impulsiveMeterId = false,
  enteredFromParent = false,
}) => {
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const fieldsToUpdate = useSelector(
    (state) => state?.meterSlice?.fieldsToUpdate
  );
  const impulsiveId = useSelector((state) => state?.meterSlice?.impulseMeterId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataShowQuery();
  const [getAttributes] = store.useLazyGetMeterAttributesQuery();
  const [value, setValue] = useState(0);
  const [impulsiveTabValue, setImpulsiveTabValue] = useState(0);
  const [meterId, setMeterId] = useState(null);
  const { t } = useTranslation();
  const a = "a";
  const b = null;
  const c = "c";
  const twinId = result?.data?.data?.attributes?.digital_twin_id;
  /*   const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const identifierTypeValue = result?.data?.data?.attributes?.fields?.[key]; */
  const serial = result?.data?.data?.attributes?.fields?.serial;
  const serialNum = result?.data?.data?.attributes?.fields?.serial_number;
  const meterClass = result?.data?.data?.attributes?.fields?.class;
  const fields = result?.data?.data?.attributes?.fields;
  useEffect(() => {
    trigger(
      `altior/digital_twin/0/instance/${
        apartamentMeterId ? apartamentMeterId : id
      }`
    )
      ?.unwrap()
      ?.then((data) => setMeterId(data?.data?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  }, [id, apartamentMeterId]);

  useEffect(() => {
    if (impulsiveMeterId) {
      trigger(`altior/digital_twin/0/instance/${impulsiveMeterId}`)
        ?.unwrap()
        ?.then((data) => setMeterId(data?.data?.id))
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  }, [impulsiveMeterId]);

  useEffect(() => {
    if (impulsiveMeterId && result?.data?.data) {
      dispatch(setImpulseId(result?.data?.data?.id));
    }
  }, [impulsiveMeterId, result]);

  const testFn = () => {};
  useEffect(() => {}, [twinId]);
  useEffect(() => {
    if (serial) {
      dispatch(setidentifierTypeValue(serial));
    }
  }, [serial]);
  useEffect(() => {
    if (result?.data?.data?.attributes?.fields?.heat_last_reading) {
      dispatch(
        setHeatUnit(
          result?.data?.data?.attributes?.fields?.heat_last_reading?.unit
        )
      );
    }
    if (result?.data?.data?.attributes?.fields?.cold_last_reading) {
      dispatch(
        setColdUnit(
          result?.data?.data?.attributes?.fields?.cold_last_reading?.unit
        )
      );
    }
  }, [result]);
  /*   useEffect(() =>{
    if(result?.data?.data?.attributes?.fields?.cold_last_reading){
      dispatch(setColdUnit(result?.data?.data?.attributes?.fields?.cold_last_reading?.unit))
    }
  },[result]) */
  useEffect(() => {}, [fields]);
  useEffect(() => {}, [value]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const impulsiveHandleChange = (event, newValue) => {
    setImpulsiveTabValue(newValue);
  };

  /*   useEffect(() => {
    if (twinId) {
      getAttributes(twinId);
    }
  }, [twinId]); */
  useEffect(() => {
    if (result?.data?.data) {
      const data = result?.data?.data?.attributes?.fields;
      const fieldsToUpdate = {
        digital_twin_id: result?.data?.data?.attributes?.digital_twin_id,
        key: data?.key,
        address: data?.address ?? "",
        coordinates: {
          lat: data?.coordinates?.coordinates?.[1],
          lng: data?.coordinates?.coordinates?.[0],
        },
        meter_type: result?.data?.data?.attributes?.fields.meter_type,
        security: result?.data?.data?.attributes?.fields?.security?.acl,
        pod: data?.pod ?? "",
        time_zone: data?.time_zone,
        tags: data?.tags,
        k: data?.k,
        kc: data?.kc,
        kq: data?.kq,
        room: data?.room,
        radiator_c: data?.radiator_c,
        radiator_depth: data?.radiator_depth,
        radiator_height: data?.radiator_height,
        radiator_width: data?.radiator_width,
        factory_converter: data?.factory_converter,
        project10200: data?.project10200,
        radiator_type: data?.radiator_type,
        working_mode: data?.working_mode,
        type: data?.type,
      };
      dispatch(setMeterIdToUpdate(result?.data?.data?.id));
      dispatch(setFieldsToUpdate(fieldsToUpdate));
    }
  }, [result?.data?.data]);

  useEffect(() => {
    if (enteredFromCondominium && fieldsToUpdate && result?.data?.data) {
      localStorage.setItem("fieldsToUpdate", JSON.stringify(fieldsToUpdate));
    }
  }, [enteredFromCondominium, fieldsToUpdate, result]);

  useEffect(() => {
    // breadCrumb value clean up function

    return () => {
      dispatch(unsetValue());
      dispatch(consumptionsCleanUp());
      dispatch(cleanData());
      dispatch(deviseMessageCleanUp());
    };
  }, []);
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return "80vw";
    }
    if (is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return "69vw";
    }
    if (is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return "92vw";
    }
    if (is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return "77vw";
    }
    if (!is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return "77vw";
    }
    if (!is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return "69vw";
    }
    if (!is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return "95vw";
    }
    if (!is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return "78vw";
    }
  };
  useEffect(() => {
    dispatch(setConsumRange(null));
  }, [impulsiveTabValue, value]);
  useEffect(() => {
    if (value == 3 || value == 4 || value == 5) {
      dispatch(setImpulseId(null));
    }
  }, [value]);

  useEffect(() => {}, [result]);
  return (
    <>
      {result?.data && (
        <Box sx={{ width: dinamicWidthSelector() }}>
          {(serial || serialNum) && meterClass !== "PulseCounter" && (
            <>
              {!apartamentMeterId && (
                <Typography variant="h4">{`Meter: ${nameTransform(
                  result?.data?.data?.attributes?.fields?.class ?? ""
                )} ${
                  result?.data?.data?.attributes?.fields?.serial ?? ""
                }`}</Typography>
              )}

              <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
                <Box
                  sx={{
                    gridRow: "1",
                    gridColumn: "span 2",
                    width: "100%",
                    width: dinamicWidthSelector(),
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ width: dinamicWidthSelector(), overflowX: "auto" }}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label={t("registry")} />

                    <Tab label={t("commands")} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <Registri
                      result={result}
                      id={id}
                      enteredFromCondominium={enteredFromCondominium}
                      impulsiveMeterId={impulsiveMeterId}
                      lora={true}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <LorawanCommandsTab id={id} fields={fields} />
                  </TabPanel>
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}

      {result?.error?.status === 404 && (
        <Typography align="center" variant="h4">
          {t("meter_not_exists")}
        </Typography>
      )}
    </>
  );
};

export default LorawanServerShow;
