import { prepareParams } from "../../utils";
import { store } from "../store";
export const jobsApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getJobs: build.query({
        query: (params) => {
          return `v1/job?${prepareParams(params)}`;
        },
      }),
      stopJob: build.mutation({
        query: (id) => ({
          url: `v1/job/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });
