import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
const NetworkAdrWindget = (props) => {
  const { t } = useTranslation();
  const [eirp, setEirp] = useState();
  const [maxPower, setMaxPower] = useState();
  const [minPower, setMinPower] = useState();
  const [dataRate, setDataRate] = useState();
  const [rxEnum, setRxEnum] = useState(null);
  const [dutyCycle, setDutyCycle] = useState();
  const region = useSelector((state) => state?.loraSlice?.networkRegion);
  const [rx1, setRx1] = useState();
  const [rx2, setRx2] = useState();
  const [rxf, setRxf] = useState();
  const powerOptions = [
    { label: "Max - 2dB" },
    { label: "Max - 4dB" },
    { label: "Max - 6dB" },
    { label: "Max - 8dB" },
    { label: "Max - 10dB" },
    { label: "Max - 12dB" },
    { label: "Max - 14dB" },
    { label: "Max - 16dB" },
    { label: "Max - 18dB" },
    { label: "Max - 20dB" },
  ];
  const dutyOptions = [
    { label: "1 (100%)" },
    { label: "1/2 (50%)" },
    { label: "1/4 (25%)" },
    { label: "1/8 (12.5%)" },
    { label: "1/16 (6.25%)" },
    { label: "1/32 (3.125%)" },
    { label: "1/64 (1.563%)" },
    { label: "1/128 (0.781%)" },
    { label: "1/256 (0.391%)" },
    { label: "1/512 (0.195%)" },
    { label: "1/1024 (0.098%)" },
    { label: "1/2048 (0.049%)" },
    { label: "1/4096 (0.024%)" },
    { label: "1/8192 (0.012%)" },
    { label: "1/16384 (0.006%)" },
    { label: "1/32768 (0.003%)" },
  ];
  useEffect(() => {
    if (
      region === "US 902-928MHz" ||
      region === "US 902-928MHz (Private Hybrid)" ||
      region === "US 902-928MHz (Private Hybrid)" ||
      region === "Australia 915-928MHz" ||
      region === "China 470-510MHz" ||
      region === "South Korea 920-923MHz" ||
      region === "India 865-867MHz"
    ) {
      setRxEnum([
        { label: "SF12 125KHz (250bit/s)" },
        { label: "SF11 125KHz (440bit/s)" },
        { label: "SF10 125KHz (980bit/s)" },
        { label: "SF9 125KHz (1760bit/s)" },
        { label: "SF8 125KHz (3125bit/s)" },
        { label: "SF7 125KHz (5470it/s)" },
      ]);
    } else {
      setRxEnum([
        { label: "SF12 125KHz (250bit/s)" },
        { label: "SF11 125KHz (440bit/s)" },
        { label: "SF10 125KHz (980bit/s)" },
        { label: "SF9 125KHz (1760bit/s)" },
        { label: "SF8 125KHz (3125bit/s)" },
        { label: "SF7 125KHz (5470it/s)" },
        { label: "SF7 250KHz (11000bit/s)" },
        { label: "50 kbps (50000bit/s)" },
      ]);
    }
  }, [region]);
  useEffect(() => {}, [rxEnum]);
  useEffect(() => {
    if (eirp) {
      props.onChange({
        ...props.formData,
        max_eirp: eirp,
      });
    }
  }, [eirp]);
  useEffect(() => {
    if (maxPower) {
      props.onChange({
        ...props.formData,
        max_power: maxPower,
      });
    }
  }, [maxPower]);
  useEffect(() => {
    if (minPower) {
      props.onChange({
        ...props.formData,
        min_power: minPower,
      });
    }
  }, [minPower]);
  useEffect(() => {
    if (dataRate) {
      props.onChange({
        ...props.formData,
        data_rate: dataRate,
      });
    }
  }, [dataRate]);

  useEffect(() => {
    if (dutyCycle) {
      props.onChange({
        ...props.formData,
        duty_cycle: dutyCycle,
      });
    }
  }, [dutyCycle]);

  useEffect(() => {
    if (rx1) {
      props.onChange({
        ...props.formData,
        initial_rx1: rx1,
      });
    }
  }, [rx1]);

  useEffect(() => {
    if (rx2) {
      props.onChange({
        ...props.formData,
        initial_rx2: rx2,
      });
    }
  }, [rx2]);

  useEffect(() => {
    if (rxf) {
      props.onChange({
        ...props.formData,
        initial_rx2f: rxf,
      });
    }
  }, [rxf]);
  useEffect(() => {}, [props]);
  useEffect(() => {}, [eirp]);
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>ADR</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={eirp}
                onChange={(e) => setEirp(e.target.value)}
                label={t("Max EIRP (dBm) *")}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={maxPower}
                onChange={(event, newValue) => {
                  setMaxPower(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={powerOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Max Power *" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={minPower}
                onChange={(event, newValue) => {
                  setMinPower(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={powerOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Min Power *" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={dataRate}
                onChange={(e) => setDataRate(e.target.value)}
                label={t("Max Data Rate *")}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={dutyCycle}
                onChange={(event, newValue) => {
                  setDutyCycle(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={dutyOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Initial Duty Cycle *" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={rx1}
                onChange={(e) => setRx1(e.target.value)}
                label={t("Initial RX1 DR Offset  *")}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={rx2}
                onChange={(event, newValue) => {
                  setRx2(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={rxEnum}
                renderInput={(params) => (
                  <TextField {...params} label="Initial RX2 DR *" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={rxf}
                onChange={(e) => setRxf(e.target.value)}
                label={t("Initial RX2 Freq (MHz) *")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default NetworkAdrWindget;
