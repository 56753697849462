import ImgUpload from "./ImgUpload";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import ImageBox from "./ImgBox";
import { useState, useEffect } from "react";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
const SwarmSettings = () => {
  const dispatch = useDispatch();
  const [setConfiguration, { isSuccess }] = store.useSetConfigurationMutation();
  const [getConfig, { isSuccess: configSuccess }] =
    store.useLazyGetShowConfigurationQuery();
  const favid = useSelector((state) => state?.configurationSlice?.favId);
  const logoId = useSelector((state) => state?.configurationSlice?.logoId);
  const [newFavId, setFavId] = useState(null);
  const [newLogoId, setLogoId] = useState(null);
  const onSaveClick = () => {
    const attributes = {
      favicon_id: newFavId ? +newFavId : +favid,
      logo_id: newLogoId ? +newLogoId : +logoId,
    };
    setConfiguration(attributes);
  };
  useEffect(() => {
    if (isSuccess) {
      getConfig();
    }
  }, [isSuccess]);
  useEffect(() => {
    dispatch(setBreadCrumbs("settings"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  return (
    <Box height={800}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={12} md={12} lg={12} xl={6}>
          <ImageBox
            success={configSuccess}
            msg={"Logo is successfully uploaded"}
            seveIdFn={setLogoId}
            imgId={logoId}
            text={"Logo"}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={12} lg={12} xl={6}>
          <ImageBox
            success={configSuccess}
            seveIdFn={setFavId}
            imgId={favid}
            msg={"Fav icon is successfully uploaded"}
            text={"Favicon"}
          />
        </Grid>
      </Grid>
      <Button
        onClick={onSaveClick}
        disabled={!newFavId && !newLogoId}
        sx={{ mt: 2, float: "right", mr: 2 }}
        variant={window.BUTTON_TYPE}
      >
        Save
      </Button>
    </Box>
  );
};

export default SwarmSettings;
