import { useEffect, useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLazyGetShowWidgetsQuery } from "../../redux/slices/widgetsApi";
import WidgetPreview from "./WidgetPreview";
import { setPreviewMode } from "../../redux/slices/widgetsSlice";
import IconButton from "@mui/material/IconButton";
import { store } from "../../redux/store";
import { setUpdating } from "../../redux/slices/widgetsSlice";
import { Button } from "@mui/material";
import GroupsSelector from "../statistics/GroupsSelector";
import { setMessage } from "../../redux/slices/snackbarSlice";
import StatisticAutocomolete from "./StatisticAutocomolete";
import theme from "../../theme";
import WidgetsAutocomplete from "./WidgetsAutocomplete";
import CustomSnackbar from "../../utils/Snackbars";
import { setWidgetTrashModal } from "../../redux/slices/widgetsSlice";
import { useNavigate } from "react-router-dom";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setTrashId as setWidgetTrashId } from "../../redux/slices/widgetsSlice";
import DeleteButton from "../../utils/DeleteButton";
import { Box } from "@material-ui/core";
import { getWidget } from "@rjsf/utils";
const ObjectFieldTemplate = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (element.name === "description" || element.name == "link") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button type="submit" variant={window.BUTTON_TYPE} color="primary">
              {t("update")}
            </Button>
          </Grid>
          <Grid item>
            <DeleteButton
              name={t("delete")}
              onClick={() => dispatch(setWidgetTrashModal(true))}
            />
            {/*  <Button
              type="button"
              variant={window.BUTTON_TYPE}
              color="secondary"
              onClick={() => dispatch(setWidgetTrashModal(true))}
              sx={{
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.main,
                },
              }}
            >
              {t("delete")}
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const tagsEnum = [
  { label: "network", value: "network" },
  { label: "device", value: "device" },
  { label: "group and district", value: "group_and_district" },
  { label: "condominium", value: "condominium" },
];
const typeEnum = [
  { label: "single value", value: "single_value" },
  { label: "pie chart", value: "pie_chart" },
  { label: "line chart", value: "line_chart" },
  { label: "map", value: "map" },
];
const WidgetShow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteWidget, { isSuccess: deleteSuccess }] =
    store.useDeleteWidgetMutation();
  const widget = useSelector((state) => state?.widgetsSlice?.widgetShow);
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const widgetTrashid = useSelector((state) => state?.widgetsSlice?.trashId);
  const { t } = useTranslation();
  const [updateWidget, { isSuccess: updateSuccess }] =
    store.useUpdateWidgetMutation();
  const { id } = useParams();
  const [rate, setRate] = useState(null);
  const trashModal = useSelector(
    (state) => state?.widgetsSlice?.widgetTrashModal
  );
  const [getShowWidget] = store.useLazyGetShowWidgetsQuery();
  const statistics = useSelector((state) => state?.statisticsSlice?.statistics);

  const statisticsEnum = statistics?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  /*   const rate = +widget?.attributes?.refresh_rate / 1000; */
  useEffect(() => {
    setRate(widget?.attributes?.refresh_rate / 1000);
  }, [widget, rate]);
  const [getStatistics] = store.useLazyGetStatisticsQuery();
  const [formData, setFormData] = useState({});
  useEffect(() => {
    dispatch(setUpdating(true));
    dispatch(setPreviewMode(true));
    /*   getStatistics(); */
    return () => {
      dispatch(setUpdating(false));
      dispatch(setPreviewMode(false));
    };
  }, []);
  useEffect(() => {
    getShowWidget(id);
  }, [id]);
  useEffect(() => {
    if (widget?.attributes?.statistic_id) {
      getStatistics({ id: widget?.attributes?.statistic_id });
    }
  }, [widget]);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (widget) {
      dispatch(
        setBreadCrumbs({
          id: widget?.attributes?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [widget]);
  const onSubmitClick = (val) => {
    const attributes = {
      ...val,
      refresh_rate: val.refresh_rate * 1000,
      tags: [val.tags],
    };

    updateWidget({ attributes: attributes, id: widget?.attributes?.id });
  };
  useEffect(() => {
    if (widgetTrashid) {
      deleteWidget(widgetTrashid);
      dispatch(setWidgetTrashId(null));
    }
  }, [widgetTrashid]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate(`/widgets`);
    }
  }, [deleteSuccess]);

  const schema = {
    title: "New statistic",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: widget?.attributes?.name,
      },

      refresh_rate: {
        id: 2,
        type: "number",
        title: t("refresh_rate"),
        default: rate,
      },
      type: {
        id: 3,
        type: "string",
        title: t("type"),
        data: [...typeEnum],
        default: widget?.attributes?.type,
      },
      tags: {
        id: 4,
        type: "string",
        title: t("tags"),
        data: [...tagsEnum],
        default: widget?.attributes?.tags[0],
      },
      statistic_id: {
        id: 5,
        type: "number",
        title: t("statistic"),
        data: [...statisticsEnum],
        default: widget?.attributes?.statistic_id,
      },
      groups: {
        id: 6,
        type: "string",
        title: t("groups"),
      },
      link: {
        id: 8,
        type: "string",
        title: t("link"),
        default: widget?.attributes?.link,
      },
      description: {
        id: 1,
        type: "string",
        title: t("description"),
        default: widget?.attributes?.description,
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    type: {
      "ui:field": "autocomplete",
    },
    tags: {
      "ui:field": "autocomplete",
    },
    statistic_id: {
      "ui:field": "statisctics",
    },
    groups: {
      "ui:field": "gropus",
    },
    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    /*     "ui:order": [
      "name",
      "type",
      "groups",
      "connection_type",
      "description",
      "sql",
      "connection_parameters",
    ], */
  };
  const fields = {
    autocomplete: WidgetsAutocomplete,
    gropus: GroupsSelector,
    statisctics: StatisticAutocomolete,
  };
  const widgetPreview = {
    ...widget.attributes,
    name: formData.name,
    description: formData.description,
    tags: [formData.tags],
    type: formData.type,
  };

  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?$/;
  const customValidate = (formData, errors) => {
    if (!formData?.name) {
      errors?.name?.addError("is a required property");
    }
    if (!formData?.description) {
      errors?.description?.addError("is a required property");
    }
    if (!formData?.type) {
      errors?.type?.addError("is a required property");
    }
    if (!formData?.refresh_rate) {
      errors?.refresh_rate?.addError("is a required property");
    }
    if (!formData?.statistic_id) {
      errors?.statistic_id?.addError("is a required property");
    }
    if (formData?.groups.length < 1) {
      errors?.groups?.addError("is a required property");
    }
    if (!formData?.tags) {
      errors?.tags?.addError("is a required property");
    }

    return errors;
  };
  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setWidgetTrashModal}
        reduxMode={true}
        text={t("statistic_delete_text")}
        title={t("statistic_delete_title")}
        deleteFunc={() => setWidgetTrashId(id)}
      />
      {widget && rate && typeof rate !== "object" ? (
        <>
          <Typography
            variant="h4"
            sx={{ color: theme?.palette?.primary?.main, mb: 4 }}
          >
            {`Widget: ${widget?.attributes?.name}`}
          </Typography>
          <CustomSnackbar />
          <Grid container>
            <Grid item xs={widget?.attributes?.type !== "map" ? 8 : 12}>
              <Form
                schema={schema}
                fields={fields}
                formData={formData}
                customValidate={customValidate}
                showErrorList={false}
                onChange={(changeEvent) => setFormData(changeEvent.formData)}
                onSubmit={(values) => {
                  onSubmitClick(values.formData);
                }}
                validator={validator}
                uiSchema={uiSchema}
                children={true}
              />
            </Grid>
            {widget?.attributes?.type !== "map" && (
              <Grid item xs={3}>
                <WidgetPreview widget={widgetPreview} />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default WidgetShow;
