import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTheme } from "@mui/material/styles"; // Import useTheme to access theme

const PasswordComponent = (props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false); // State to track focus
  const theme = useTheme(); // Get the theme object

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {}, [props]);

  return (
    <FormControl fullWidth>
      <InputLabel
        style={{
          color:
            props?.rawErrors?.length > 0
              ? "#d32f2f"
              : isFocused
              ? theme.palette.formField // Change to theme color when focused
              : undefined, // Keep the default color when not focused
        }}
        htmlFor="outlined-adornment-password"
      >
        {t("password")}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={props.formData || ""}
        error={props?.rawErrors?.length > 0}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={() => setIsFocused(true)} // Set focus state to true
        onBlur={() => setIsFocused(false)} // Set focus state to false
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff sx={{ fill: theme.palette.navIcon }} />
              ) : (
                <Visibility sx={{ fill: theme.palette.navIcon }} />
              )}
            </IconButton>
          </InputAdornment>
        }
        label={t("password")}
        sx={{
          width: "100%",
          height: 56,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.formField, // Default border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.formField, // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.formField, // Border color when focused
          },
        }}
      />
    </FormControl>
  );
};

export default PasswordComponent;
