import { Button } from "@mui/material";

const DeleteButton = ({ onClick, styles, name }) => {
  return (
    <Button
      onClick={onClick}
      variant={window.BUTTON_TYPE}
      sx={{
        ...styles,
        color: window.THEME.buttonDeleteText,
        background: window.THEME.buttonDeleteBackGround,
        "&:hover": {
          background: window.THEME.buttonDeleteHover,
        },
      }}
    >
      {name}
    </Button>
  );
};

export default DeleteButton;
