import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  errorAssociations: null,
  errorAssociationsCount: null,
  errorAssociation: null,
  trashId: null,
};

const errorAssociationSlice = createSlice({
  name: "errorAssociationSlice",
  initialState,
  reducers: {
    setErrorAssocTrashId(state, action) {
      state.trashId = action.payload;
    },
    showClean(state) {
      state.errorAssociation = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.errorAssociationEndpoints.getErrorAssociations.matchFulfilled,
      (state, action) => {
        state.errorAssociations = action.payload.data.attributes.data;
        state.errorAssociationsCount =
          action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.errorAssociationEndpoints.getErrorAssociationShow.matchFulfilled,
      (state, action) => {
        state.errorAssociation = action.payload.data.attributes;
      }
    );
  },
});
export const errorAssociationActions = errorAssociationSlice.actions;
export default errorAssociationSlice.reducer;
