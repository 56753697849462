import {
  prepareBody,
  prepareBodyWithoutId,
  prepareParams,
} from "../../utils/api_params";

export const errorAssociationApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getErrorAssociations: build.query({
        query: (params) => {
          return `v1/plugin/errorassociation?${prepareParams(params)}`;
        },
      }),
      getErrorAssociationShow: build.query({
        query: (id) => {
          return `v1/plugin/errorassociation/${id}`;
        },
      }),
      createErrorAssociation: build.mutation({
        query: (attributes) => ({
          url: `v1/plugin/errorassociation`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      updateErrorAssociation: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/errorassociation/${id}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      deleteErrorAssociation: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/plugin/errorassociation/${id}`,
        }),
      }),
    }),
  });
