import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Users from "../components/users/Users";
import UserShow from "../components/users/UserShow";
import Metrics from "../pages/Metrics";
import DigitalTwinShow from "../components/digitaltwin/DigitalTwinShow";
import DigitalTwinIndex from "../components/digitaltwin/DigitalTwinIndex";
import Meters from "../components/form/Meters";
import UserUpdate from "../components/users/UserUpdate";
import MeterCreate from "../components/form/MeterCreate";
import Uploader from "../components/FileUploader /Uploader";
import Campaigns from "../components/campaign/Campaigns";
import GatewayUpdate from "../components/gateway/GatewayUpdate";
import CampaignShow from "../components/campaign/CampaignShow";
import Jobs from "../components/jobs/Josb";
import CampaignCreate from "../components/campaign/CampaignCreate";
import ImportReadings from "../components/meters/ImportReadings";
import CreateLora from "../components/lora/CreateLora";
import MyAccount from "../pages/MyAccount";
import UserCreate from "../components/users/UserCreate";
import CreateBalance from "../pages/balances/CreateBalance";
import WorklistCreate from "../components/worklist/WorklistCreate";
import WorkListEditorCreate from "../components/worklist/WorkListEditorCreate";
import WorklistEditorShow from "../components/worklist/WorklistEditorShow";
import ShowMeterNote from "../components/metrics/ShowMeterNote";
import WorklistEditorIndex from "../components/worklist/WorklistEditorIndex";
import WorklistIndex from "../components/worklist/WorklistIndex";
import WorklistShow from "../components/worklist/WorklistShow";
import LoraIndex from "../components/lora/LoraIndex";
import LorawanServerShow from "../components/metrics/LorawanServerShow";
import DistrictsIndex from "../components/DeviceGroups/DistrictsIndex";
import GroupDeviceCreate from "../components/DeviceGroups/GroupDeviceCreate";
import GroupsDeviceShow from "../components/DeviceGroups/GroupsDeviceShow";
import Condominium from "../pages/Condominium";
import AuditIndex from "../components/audit/AuditIndex";
import DexShow from "../pages/DexShow";
import DexIndex from "../pages/DexIndex";
import EdgeShow from "../components/edges/EdgeShow";
import EdgeIndex from "../components/edges/EdgeIndex";
import GatewayIndex from "../components/gateway/GatewayIndex";
import GatewayCreate from "../components/gateway/GatewayCreate";
import { ApartmentShow } from "../pages/ApartmentShow";
import GatewayShow from "../components/gateway/GatewayShow";
import AllLastReadings from "../components/AllLastReadings";
import { SubCondominiumShow } from "../pages/SubCondominiumShow";
import TestDatePicker from "../components/form/formComponents/TestDatePicker";
import Messages from "../components/messages/Messages";
import LiveMessages from "../components/messages/LiveMessages";
import Dex from "../pages/Dex";
import UserGroupsIndex from "../components/userGroups/UserGroupsIndex";
import UserGroupsCreate from "../components/userGroups/UserGroupsCreate";
import CreateNote from "../components/gateway/CreateNote";
import { CondominiumShow } from "../pages/CondominiumShow";
import StatisticShow from "../components/statistics/StatisticShow";
import Statistics from "../components/statistics/Statistics";
import StatisticCreate from "../components/statistics/StatisticCreate";
import CreateWidget from "../components/widgets/CreateWidget";
import WidgetShow from "../components/widgets/WidgetShow";
import Widgets from "../components/widgets/Widgets";
import MeterShow from "../components/metrics/MeterShow";
import DashboardCreate from "../components/dashboard/DashboardCreate";
import ShowNote from "../components/gateway/ShowNote";
import CreateMeterNote from "../components/metrics/CreateMeterNote";
import NotFoundPage from "./NotfoundPage";
import LoginPage from "../components/Login";
import Logged from "../components/Logged";
import ApartamentMterShow from "../components/condominium/ApartamentMeterShow";
import UpdateMeter from "../components/metrics/UpdateMeter";
import AlarmsIndex from "../components/meterErrors/AlarmsIndex";
import ErrorTypeIndex from "../components/meterErrors/ErrorTypeIndex";
import CreateErrorType from "../components/meterErrors/CreateErrorType";
import ErrorTypeShow from "../components/meterErrors/ErrorTypeShow";
import ErrorAssociationIndex from "../components/meterErrors/ErrorAssociationIndex";
import CreateErrorAssociation from "../components/meterErrors/CreateErrorAssociation";
import Anomalies from "../components/anomalies/Anomalies";
import UserGroupShow from "../components/userGroups/UserGroupShow";
import ErrorAssociationShow from "../components/meterErrors/ErrorAssociationShow";
import NetworkEdgeCreate from "../components/network_edge/NetworkEdgeCreate";
import TrilliantMeters from "../components/trilliant/TrilliantMeters";
import CreateConfig from "../components/config/CreateConfig";
import SwarmSettings from "../components/settings/SwarmSettings";
import Balances from "../pages/balances/Balances";
import ConfigIndex from "../components/config/ConfigIndex";
import BalanceShow from "../pages/balances/BalanceShow";
import UpdateBalance from "../pages/balances/UpdateBalance";
import ConfigShow from "../components/config/ConfigShow";
import ActionShow from "../components/actions/ActionShow";
import AuthPage from "../components/AuthPage";
import ActionsIndex from "../components/actions/ActionsIndex";
import ConfirmRegister from "../components/ConfirmRegister";
import ResetPasswordPage from "../components/ResetPasswordPage";
import BalanceCreate from "../pages/balances/BalanceCreate";
import ConfirmResetPasswordPage from "../components/ConfirmResetPasswordPage";

const selectMainRoot = (str) => {
  debugger;
  switch (str) {
    case "Metrics":
      return <Metrics />;
    case "Meters":
      return <Meters />;
    case "Condominiums":
      return <Condominium />;
    case "Users":
      return <Users />;

    case "Gateways":
      return <GatewayIndex />;
  }
};
const RootSelector = () => {
  const findLowestPriorityRoot = (dataArray, object) => {
    let lowestPriority = Infinity;
    let lowestPriorityRoot = null;

    for (const element of dataArray) {
      if (
        object.hasOwnProperty(element) &&
        object[element].priority < lowestPriority
      ) {
        lowestPriority = object[element].priority;
        lowestPriorityRoot = object[element].root;
      }
    }

    return lowestPriorityRoot;
  };

  const rotes = window.ROOTES;

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const result = findLowestPriorityRoot(roles, rotes);
  useEffect(() => {}, [result]);

  return selectMainRoot(result ?? "Meters");
};
export const IwdRoutes = [
  {
    path: "/confirm-registration",
    auth: false,
    component: <ConfirmRegister />,
    section: "login",
  },
  {
    path: "/confirm-reset",
    auth: false,
    component: <ConfirmResetPasswordPage />,
    section: "login",
  },
  {
    path: "/login",
    auth: false,
    component: <AuthPage />,
    section: "login",
  },
  {
    path: "/reset_password",
    auth: false,
    component: <ResetPasswordPage />,
    section: "login",
  },
  {
    path: "/actions",
    auth: true,
    component: <ActionsIndex />,
    section: "actions",
  },
  {
    path: "/actions/:id",
    auth: true,
    component: <ActionShow />,
    section: "actions",
  },

  {
    path: "/test",
    auth: true,
    component: <TestDatePicker />,
    section: "import_readings",
  },
  {
    path: "/jobs",
    auth: true,
    component: <Jobs />,
    section: "jobs",
  },
  {
    path: "/readings",
    auth: true,
    component: <AllLastReadings />,
    section: "all_readings",
  },

  {
    path: "/readings/import",
    auth: true,
    component: <ImportReadings />,
    section: "import_readings",
  },
  {
    path: "/enduser",
    auth: true,
    component: <ConfigIndex />,
    section: "enduser_config",
  },

  {
    path: "/enduser/create",
    auth: true,
    component: <CreateConfig />,
    section: "enduser_config",
  },
  {
    path: "/enduser/:id",
    auth: true,
    component: <ConfigShow />,
    section: "enduser_config",
  },
  {
    path: "/trilliant",
    auth: true,
    component: <TrilliantMeters />,
    section: "trilliant",
  },
  {
    path: "/settings",
    auth: true,
    component: <SwarmSettings />,
    section: "settings",
  },

  {
    path: "/usergroups/create",
    auth: true,
    component: <UserGroupsCreate />,
    section: "usergroups",
  },
  {
    path: "/usergroups",
    auth: true,
    component: <UserGroupsIndex />,
    section: "usergroups",
  },
  {
    path: "/usergroups/:id",
    auth: true,
    component: <UserGroupShow />,
    section: "usergroups",
  },
  {
    path: "/anomalies",
    auth: true,
    component: <Anomalies />,
    section: "anomalies",
  },

  {
    path: "/worklists",
    auth: true,
    component: <WorklistIndex />,
    section: "worklist",
  },
  {
    path: "/worklists/create",
    auth: true,
    component: <WorklistCreate />,
    section: "worklist",
  },
  {
    path: "/worklists/:id",
    auth: true,
    component: <WorklistShow />,
    section: "worklist",
  },
  {
    path: "/worklisteditors/create",
    auth: true,
    component: <WorkListEditorCreate />,
    section: "worklist",
  },

  {
    path: "/worklisteditors",
    auth: true,
    component: <WorklistEditorIndex />,
    section: "worklist",
  },
  {
    path: "/worklisteditors/:id",
    auth: true,
    component: <WorklistEditorShow />,
    section: "worklist",
  },
  {
    path: "/groups",
    auth: true,
    component: <DistrictsIndex />,
    section: "groups_list",
  },

  {
    path: "/groups/create",
    auth: true,
    component: <GroupDeviceCreate />,
    section: "groups_list",
  },
  {
    path: "/groups/:id",
    auth: true,
    component: <GroupsDeviceShow />,
    section: "groups_list",
  },
  {
    path: "/lora",
    auth: true,
    component: <LoraIndex />,
    section: "lora",
  },
  {
    path: "/edge/create",
    auth: false,
    component: <NetworkEdgeCreate />,
    section: "edge",
  },
  {
    path: "/edge",
    auth: false,
    component: <EdgeIndex />,
    section: "edge",
  },
  {
    path: "/edge/:id",
    auth: false,
    component: <EdgeShow />,
    section: "edge",
  },

  {
    path: "/lora/create",
    auth: true,
    component: <CreateLora />,
    section: "lora",
  },
  {
    path: "/lora/:id",
    auth: true,
    component: <LorawanServerShow />,
    section: "lora",
  },
  {
    path: "/audit",
    auth: true,
    component: <AuditIndex />,
    section: "audit",
  },
  {
    path: "/balances",
    auth: true,
    component: <Balances />,
    section: "balance",
  },
  {
    path: "/condominiums/:id/balance/create",
    auth: true,
    component: <CreateBalance />,
    section: "balance",
  },
  {
    path: "/balances/create",
    auth: true,
    component: <BalanceCreate />,
    section: "balance",
  },
  {
    path: "/condominiums/:id/balance/:balanceId",
    auth: true,
    component: <BalanceShow />,
    section: "balance",
  },
  {
    path: "/condominiums/:id/balance/:balanceId/edit",
    auth: true,
    component: <UpdateBalance />,
    section: "balance",
  },
  {
    path: "/condominiums/:id/subcondominiums/:subCondominumName/apartments/:apartmentName/apartamentMeter/:serial",
    auth: true,
    component: <ApartamentMterShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/import",
    auth: true,
    component: <Uploader />,
    section: "devices_meters",
  },
  {
    path: "/condominiums/:id/subcondominiums/:subCondominumName/apartments/:apartmentName",
    auth: true,
    component: <ApartmentShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/:id/subcondominiums/:subCondominumName",
    auth: true,
    component: <SubCondominiumShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/new",
    auth: true,
    component: <CondominiumShow />,
    section: "condominums",
  },
  {
    path: "/digitaltwins",
    auth: true,
    component: <DigitalTwinIndex />,
    section: "digital_twins",
  },
  {
    path: "/digitaltwins/:id",
    auth: true,
    component: <DigitalTwinShow />,
    section: "digital_twins",
  },
  {
    path: "/condominiums/:id",
    auth: true,
    component: <CondominiumShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/:id/balance",
    auth: true,
    component: <CondominiumShow />,
    section: "condominums",
  },
  {
    path: "/condominiums",
    auth: true,
    component: <Condominium />,
    section: "condominums",
  },

  {
    path: "/alarms",
    auth: true,
    component: <AlarmsIndex />,
    section: "alarms",
  },
  {
    path: "/errortypes",
    auth: true,
    component: <ErrorTypeIndex />,
    section: "errors",
  },
  {
    path: "/errortypes/create",
    auth: true,
    component: <CreateErrorType />,
    section: "errors",
  },
  {
    path: "/errortypes/:id",
    auth: true,
    component: <ErrorTypeShow />,
    section: "errors",
  },
  {
    path: "/errorassociation",
    auth: true,
    component: <ErrorAssociationIndex />,
    section: "errors",
  },
  {
    path: "/errorassociation/create",
    auth: true,
    component: <CreateErrorAssociation />,
    section: "errors",
  },
  {
    path: "/gateways/:id/createnote",
    auth: true,
    component: <CreateNote />,
    section: "notes",
  },
  {
    path: "/meters/:id/createnote",
    auth: true,
    component: <CreateMeterNote />,
    section: "notes",
  },
  {
    path: "/gateways/:id/note/:noteId",
    auth: true,
    component: <ShowNote />,
    section: "notes",
  },
  {
    path: "/meters/:id/note/:noteId",
    auth: true,
    component: <ShowMeterNote />,
    section: "notes",
  },
  {
    path: "/gateways/:id/note",
    auth: true,
    component: <GatewayShow />,
    section: "notes",
  },
  {
    path: "/errorassociation/:id",
    auth: true,
    component: <ErrorAssociationShow />,
    section: "errors",
  },
  {
    path: "/me",
    auth: true,
    component: <MyAccount />,
    section: "account",
  },

  { path: "/users", auth: true, component: <Users />, section: "users" },
  {
    path: "/users/:id/edit",
    auth: true,
    component: <UserUpdate />,
    section: "user_update",
  },

  /*  {
    path: "/login",
    auth: false,
    component: <LoginPage />,
    section: "login",
  },
  {
    path: "/logged/:token",
    auth: false,
    component: <Logged />,
    section: "login",
  },
 */
  {
    path: "/users/create",
    auth: true,
    component: <UserCreate />,
    section: "user_create",
  },

  {
    path: "/users/:id",
    auth: true,
    component: <UserShow />,
    section: "userShow",
  },

  {
    path: "/metrics",
    auth: true,
    component: <Metrics />,
    section: "dashboard",
  },
  {
    path: "/metrics/create",
    auth: true,
    component: <DashboardCreate />,
    section: "dashboard",
  },

  {
    path: "/meters",
    auth: true,
    component: <Meters />,
    section: "devices_meters",
  },

  {
    path: "/meters/create",
    auth: true,
    component: <MeterCreate />,
    section: "devices_addMeter",
  },
  {
    path: "/meters/:id",
    auth: true,
    component: <MeterShow />,
    section: "devices_meters",
  },
  {
    path: "/meters/:id/edit",
    auth: true,
    component: <UpdateMeter />,
    section: "devices_meters",
  },
  /* 
  {
    path: "/import",
    auth: true,
    component: <Uploader />,
    section: "devices_import_readings",
  }, */

  {
    path: "/campaigns",
    auth: true,
    component: <Campaigns />,
    section: "campaign_list",
  },
  {
    path: "/campaigns/:id",
    auth: true,
    component: <CampaignShow />,
    section: "campaign_list",
  },
  {
    path: "/campaign/create",
    auth: true,
    component: <CampaignCreate />,
    section: "campaing_generator",
  },

  {
    path: "/groups",
    auth: true,
    component: <DistrictsIndex />,
    section: "groups_list",
  },
  {
    path: "/groups/:id",
    auth: true,
    component: <GroupsDeviceShow />,
    section: "groups_list",
  },
  {
    path: "/groups/create",
    auth: true,
    component: <GroupDeviceCreate />,
    section: "groups_create",
  },

  {
    path: "/gateways",
    auth: true,
    component: <GatewayIndex />,
    section: "list_LPWAN",
  },

  {
    path: "/gateways/create",
    auth: true,
    component: <GatewayCreate />,
    section: "list_LPWAN",
  },
  {
    path: "/gateways/:id/edit",
    auth: true,
    component: <GatewayUpdate />,
    section: "list_LPWAN",
  },
  {
    path: "/gateways/:id",
    auth: true,
    component: <GatewayShow />,
    section: "list_LPWAN",
  },
  {
    path: "/messages",
    auth: true,
    component: <Messages />,
    section: "events_alarms",
  },
  {
    path: "/messages/live",
    auth: true,
    component: <LiveMessages />,
    section: "live",
  },
  {
    path: "/dex",
    auth: true,
    component: <DexIndex />,
    section: "dex",
  },

  {
    path: "/dex/create",
    auth: true,
    component: <Dex />,
    section: "dex",
  },
  {
    path: "/dex/:id",
    auth: true,
    component: <DexShow />,
    section: "dex",
  },
  {
    path: "/statistics",
    auth: true,
    component: <Statistics />,
    section: "statistics_list",
  },
  {
    path: "/statistics/create",
    auth: true,
    component: <StatisticCreate />,
    section: "statistics_create",
  },
  {
    path: "/statistics/:id",
    auth: true,
    component: <StatisticShow />,
    section: "statistics_list",
  },

  {
    path: "/widgets",
    auth: true,
    component: <Widgets />,
    section: "widgets_list",
  },
  {
    path: "/widgets/create",
    auth: true,
    component: <CreateWidget />,
    section: "widgets_create",
  },
  {
    path: "/widgets/:id",
    auth: true,
    component: <WidgetShow />,
    section: "widgets_list",
  },
  {
    path: "/",
    auth: true,
    component: <RootSelector />,
    section: "main",
  },
  {
    path: "/*",
    auth: false,
    component: <NotFoundPage />,
    section: "notFoundPage",
  },
];
