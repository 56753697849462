import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const CreateErrorType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createdId, setCreatedId] = useState(null);
  const [formData, setFormData] = useState({});
  const [createErrorType, { isSuccess: createSuccess }] =
    store.useCreateErrorTypeMutation();
  const { t } = useTranslation();
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        title: t("name"),
      },
    },
    required: ["name"],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };
  useEffect(() => {
    if (createSuccess && createdId) {
      navigate(`/errortypes/${createdId}`);
    }
  }, [createSuccess, createdId]);
  useEffect(() => {
    dispatch(setBreadCrumbs("errors_types/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
    };
    createErrorType(attributes)
      .unwrap()
      .then((data) => setCreatedId(data?.data?.attributes?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t("new_error_type")}
      </Typography>
      <Form
        schema={schema}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={onSubmitClick}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        /*   customValidate={customValidate} */
        noHtml5Validate
      />
    </Box>
  );
};

export default CreateErrorType;
