import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Tabs,
  Tab,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
  Box,
  Button,
} from "@mui/material";
import {
  resetSubcondominiumReadings,
  resetApartmentReadings,
  resetCondominiumReadings,
  setReadingTableTab,
  resetConsuptions,
} from "../../../redux/slices/condominiumSlice";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const MetersTableTab = ({
  afs,
  heatMeter,
  setMeterType,
  colorMeter,
  resetFilter = false,
  /*  setDeviceType, */
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [colorMeterValue, setColorMeterValue] = useState(0);
  const [waterMeterValue, setWaterMeterValue] = useState(0);
  const handleChange = (event, newValue) => {
    dispatch(resetSubcondominiumReadings());
    dispatch(resetApartmentReadings());
    dispatch(resetCondominiumReadings());
    dispatch(resetConsuptions());
    resetFilter && resetFilter();
    /*  dispatch(resetConsuptions()); */
    setValue(newValue);
    if (newValue == 0) {
      setMeterType(["AFS"]);
      setWaterMeterValue(0);
      dispatch(setReadingTableTab("afs"));
      /* setDeviceType(["07"]); */
    }
    if (newValue == 1) {
      setMeterType(["RIPARTITORE"]);
      dispatch(setReadingTableTab("hca"));
      /*  setDeviceType(["08"]); */
    }
    if (newValue == 2) {
      setMeterType([
        "CALORIE / FRIGORIE",
        "CALORIE/FRIGORIE",
        "Calorie",
        "Frigorie",
      ]);
      dispatch(setReadingTableTab("heat"));
      setColorMeterValue(0);
      /*  setDeviceType(["13"]); */
    }
  };

  const colorMeterHandleChange = (event, newValue) => {
    setColorMeterValue(newValue);
    if (newValue == 0) {
      setMeterType(["CALORIE / FRIGORIE"]);
    }
    if (newValue == 1) {
      setMeterType(["CALORIE / FRIGORIE", "Calorie"]);
    }
    if (newValue == 2) {
      setMeterType(["CALORIE / FRIGORIE", "Frigorie"]);
    }
  };
  const waterMeterHandleChange = (event, newValue) => {
    setWaterMeterValue(newValue);
    if (newValue == 0) {
      setMeterType(["AFS"]);
      dispatch(setReadingTableTab("afs"));
    }
    if (newValue == 1) {
      setMeterType(["ACS"]);
      dispatch(setReadingTableTab("acs"));
    }
    /*  dispatch(resetConsuptions()); */
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t("AFS-ACS")} />
        <Tab label={t("rip")} />
        <Tab label={t("color_meter")} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Tabs value={waterMeterValue} onChange={waterMeterHandleChange}>
          <Tab label={"AFS"} />
          <Tab label={"ACS"} />
        </Tabs>
        <TabPanel value={waterMeterValue} index={0}>
          {afs}
        </TabPanel>
        <TabPanel value={waterMeterValue} index={1}>
          {afs}
        </TabPanel>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {heatMeter}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {colorMeter}
      </TabPanel>
    </Box>
  );
};
export default MetersTableTab;
