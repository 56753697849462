import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";

import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DoneIcon from "@mui/icons-material/Done";
import CustomSnackbar from "../../utils/Snackbars";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
const ParametersHandler = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const statistic = useSelector((state) => state?.statisticsSlice?.statistic);
  const statisticUpdating = useSelector(
    (state) => state?.statisticsSlice?.updating
  );
  const [showPasswordMap, setShowPasswordMap] = useState({});
  const isUpdating = useSelector((state) => state?.statisticsSlice?.updating);
  const [parameters, setParameters] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [parameterKey, setParameterKey] = useState("");
  const [open, setOpen] = useState(false);
  const [parameterValue, setParameterValue] = useState("");
  const [saved, setSaved] = useState(false);
  const [addParam, setAddParam] = useState(false);
  const transformParams = parameters?.map((item) => ({
    [item[0]]: item[1],
  }));
  const transformedParams = Object.assign({}, ...transformParams);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowPassword = (parameterKey) => {
    setShowPasswordMap((prevShowPasswordMap) => ({
      ...prevShowPasswordMap,
      [parameterKey]: !prevShowPasswordMap[parameterKey],
    }));
  };
  const onSaveClick = (props) => {
    if (parameterKey !== "" && parameterValue !== "") {
      setParameters((prev) => [...prev, [parameterKey, parameterValue]]);
      setParameterKey("");
      setParameterValue("");
    }
    dispatch(setMessage(t("saved_params")));
    setAddParam(false);

    setSaved(true);
  };
  useEffect(() => {
    if (statistic) {
      setParameters(Object.entries(statistic.connection_parameters));
    }
  }, [statistic]);
  useEffect(() => {
    if (!statisticUpdating) {
      setParameters([]);
    }
  }, [statisticUpdating]);
  useEffect(() => {
    if (saved || (isUpdating && parameters.length > 0)) {
      props.onChange(transformedParams);
      setSaved(false);
    }
  }, [saved, isUpdating, parameters.length]);

  const onAddClick = () => {
    if (parameterKey !== "" && parameterValue !== "") {
      setParameters((prev) => [...prev, [parameterKey, parameterValue]]);
      setParameterKey("");
      setParameterValue("");
    }
    if (!addParam) {
      setAddParam(true);
    }
  };
  const onDeleteClick = (index) => {
    setParameters((prev) => prev?.filter((item, i) => i !== index));
  };
  useEffect(() => {}, [parameters]);

  return (
    <>
      {isUpdating ? (
        statistic && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t("connection_parameters")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {parameters?.map((item, i) => (
                <Grid container>
                  <Grid item xs={5} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      label="key"
                      value={item[0]}
                    />
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      type={showPasswordMap[item[0]] ? "text" : "password"}
                      label="value"
                      value={item[1]}
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ mt: 3 }}>
                    <Tooltip /* title={t("delete_param")} */>
                      <IconButton onClick={() => toggleShowPassword(item[0])}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sx={{ mt: 3 }}>
                    <Tooltip title={t("delete_param")}>
                      <IconButton onClick={() => onDeleteClick(i)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
              {(addParam || parameters.length == 0) && (
                <>
                  <Grid container>
                    <Grid item xs={5} sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: "95%" }}
                        error={props?.rawErrors?.length > 0 && !parameterKey}
                        label="key"
                        value={parameterKey}
                        onChange={(e) => setParameterKey(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5} sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: "95%" }}
                        label="value"
                        error={props?.rawErrors?.length > 0 && !parameterValue}
                        value={parameterValue}
                        onChange={(e) => setParameterValue(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Box
                display={"flex"}
                justifyContent="space-between"
                sx={{ mt: 1, width: "90%" }}
              >
                <Tooltip title={t("add_param")}>
                  <IconButton
                    onClick={onAddClick}
                    disabled={
                      (parameterKey == "" || parameterValue == "") &&
                      addParam == true
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("save_params")}>
                  <IconButton onClick={onSaveClick} sx={{ mr: 2 }}>
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t("connection_parameters")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parameters?.map((item, i) => (
              <Grid container>
                <Grid item xs={5} sx={{ mt: 2 }}>
                  <TextField
                    sx={{ width: "95%" }}
                    label="key"
                    value={item[0]}
                  />
                </Grid>
                <Grid item xs={5} sx={{ mt: 2 }}>
                  <TextField
                    sx={{ width: "95%" }}
                    label="value"
                    type={showPasswordMap[item[0]] ? "text" : "password"}
                    value={item[1]}
                  />
                </Grid>
                <Grid item xs={1} sx={{ mt: 3 }}>
                  <Tooltip /* title={t("delete_param")} */>
                    <IconButton onClick={() => toggleShowPassword(item[0])}>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={1} sx={{ mt: 3 }}>
                  <Tooltip title={t("delete_param")}>
                    <IconButton onClick={() => onDeleteClick(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
            {(addParam || parameters.length == 0) && (
              <>
                <Grid container>
                  <Grid item xs={5} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      error={props?.rawErrors?.length > 0 && !parameterKey}
                      label="key"
                      value={parameterKey}
                      onChange={(e) => setParameterKey(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      error={props?.rawErrors?.length > 0 && !parameterValue}
                      label="value"
                      /*      type="password" */
                      value={parameterValue}
                      onChange={(e) => setParameterValue(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Box
              display={"flex"}
              justifyContent="space-between"
              sx={{ mt: 1, width: "90%" }}
            >
              <Tooltip title={t("add_param")}>
                <IconButton
                  onClick={onAddClick}
                  disabled={
                    (parameterKey == "" || parameterValue == "") &&
                    addParam == true
                  }
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("save_params")}>
                <IconButton onClick={onSaveClick} sx={{ mr: 2 }}>
                  <DoneIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default ParametersHandler;
