import { useDispatch, useSelector } from "react-redux";
import { hasCommonValue } from "../utils/api_params";
import React from "react";

export const IwdAuthWrapper = ({ section, children, ...props }) => {
  const permessions = window?.COMPONENTS?.[section];
  const groups = useSelector((state) => state?.userPreferenceSlice.groups);
  const roles = useSelector((state) => state?.userPreferenceSlice.roles);

  const render = () => {
    if (permessions?.groups?.length > 0) {
      return hasCommonValue(groups, permessions?.groups);
    }
    if (!permessions?.groups || permessions?.groups.length === 0) {
      return hasCommonValue(roles, permessions?.roles);
    }
    return false;
  };

  return permessions?.visibility && render()
    ? React.cloneElement(children, props)
    : null;
};
