import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  users: [],
  userShow: null,
  count: null,
  userRoles: null,
  grantId: null,
  selectedUser: "",
  condominumList: null,
  fieldsToUpdate: null,
};
const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    unsetUserRoles(state) {
      state.userRoles = null;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setUserUpdateFields(state, action) {
      state.fieldsToUpdate = action.payload;
    },
    resetUserShow(state) {
      state.userShow = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.userEndpoints.getUsers.matchFulfilled,
      (state, action) => {
        state.users = action.payload.data;
        state.count = action.payload.meta?.count;
      }
    );
    builder.addMatcher(
      store.userEndpoints.getUserShow.matchFulfilled,
      (state, action) => {
        state.userShow = action.payload.data;
      }
    );
    builder.addMatcher(
      store.userEndpoints.getGrant.matchFulfilled,
      (state, action) => {
        state.userRoles = action.payload.data[0].attributes.role_keys;
        state.grantId = action.payload.data[0].id;
      }
    );
    builder.addMatcher(
      store.userEndpoints.getCondominiumList.matchFulfilled,
      (state, action) => {
        state.condominumList = action?.payload?.data;
      }
    );
  },
});
export const {
  unsetUserRoles,
  setSelectedUser,
  setUserUpdateFields,
  resetUserShow,
} = userSlice.actions;
export default userSlice.reducer;
