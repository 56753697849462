import { useState, useEffect } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { store } from "../redux/store";
import { StyledDataGrid } from "./StyledDataGrid";
import MetersTableTab from "../components/form/formComponents/MetersTableTab";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import theme from "../theme";
import Chip from "@mui/material/Chip";
import DraftsIcon from "@mui/icons-material/Drafts";
import IconButton from "@mui/material/IconButton";
import ModalDialog from "../components/meters/ModalDialog";
import FormAccordion from "../components/form/formComponents/Accordion";
import MeterDatePicker from "../components/form/formComponents/MeterDatePicker";

import ApartamentConsumtionsGrafic from "./ApartamentConsumtionsGrafic";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (i == 4) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const ApartamentConsumtionsTab = ({ condominiumId }) => {
  const [getConsumptions] = store.useLazyGetApartamentConsumtionsQuery();
  const [firstRender, setFirstRender] = useState(true);
  const [meterType, setMeterType] = useState(["AFS"]);
  const [savedToDate, setSavedToDate] = useState(null);
  const [savedFromDate, setSavedFromDate] = useState(null);
  const apartamentConsumtions = useSelector(
    (state) => state?.condominiumSlice?.apartamentConsumtions
  );
  const apartamentConsumtionsCount = useSelector(
    (state) => state?.condomimiumSlice?.apartamentConsumtions
  );
  const subCondominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumIdentifier
  );
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const apartamentIdentifier = useSelector(
    (state) => state?.condominiumSlice?.apartamentIdentifier
  );
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const dates = {
    from: new Date(),
    to: new Date(),
  };
  const newFrom = new Date(
    Date.UTC(
      dates.from.getFullYear(),
      dates.from.getMonth(),
      dates.from.getDate() - 7
    )
  );
  const newTo = new Date(
    Date.UTC(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate())
  );
  const now = new Date();

  const schema = {
    type: "object",
    properties: {
      serial_number: {
        id: 2,
        title: t("serial_number"),
        type: "string",
      },
      condominium_name: {
        id: 4,
        title: t("condominium_name"),
        type: "string",
      },
      sub_condominium_name: {
        id: 5,
        title: t("sub_condominium_name"),
        type: "string",
      },
      apartment_name: {
        id: 1,
        title: t("apartment_name"),
        type: "string",
      },
      insertion_date: {
        id: 3,
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
            default: newFrom.toISOString(),
          },
          to: {
            type: "string",
            default: now.toISOString(),
          },
        },
      },
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
      props: {
        className: "bntListSearch",
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
  };
  const fields = {
    datepicker: MeterDatePicker,
  };
  useEffect(() => {
    if (condominiumId) {
      const messagesToDate = new Date(
        Date.UTC(
          dates.to.getFullYear(),
          dates.to.getMonth(),
          dates.to.getDate() + 1
        )
      );
      getConsumptions({
        condominium_id: condominiumId,
        from: savedFromDate ?? newFrom.toISOString(),
        to: savedToDate ?? messagesToDate.toISOString(),
        page: page,
        per_page: perPage,
        grouped: true,
        sub_condominium_identifier: subCondominiumIdentifier,
        apartment_identifier: apartamentIdentifier,
        meter_types: JSON.stringify(meterType),
      });
    }
    setFirstRender(false);
  }, [condominiumId, meterType, page]);
  const onSubmitClick = (obj) => {
    const messagesDateTo = new Date(formData.insertion_date.to);
    messagesDateTo.setDate(messagesDateTo.getDate() + 1);
    setSavedToDate(messagesDateTo.toISOString());
    setSavedFromDate(obj?.insertion_date?.from);
    getConsumptions({
      ...obj,
      condominium_id: condominiumId,
      from: obj?.insertion_date?.from,
      to: messagesDateTo.toISOString(),
      grouped: true,
      page: page,
      per_page: perPage,
      sub_condominium_identifier: subCondominiumIdentifier,
      apartment_identifier: apartamentIdentifier,
      meter_types: JSON.stringify(meterType),
    });
  };
  /*   useEffect(() => {
    if (!firstRender) {
      const messagesDateTo = new Date(formData.insertion_date.to);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      getConsumptions({
        condominium_id: condominiumId,
        from: newFrom.toISOString(),
        to: messagesDateTo.toISOString(),
        grouped: true,
        sub_condominium_identifier: subCondominiumIdentifier,
        apartment_identifier: apartamentIdentifier,
        meter_types: JSON.stringify(meterType),
        ...formData,
      });
    }
  }, [meterType, firstRender]); */
  const consumptionsColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "value",
      headerName: t("value"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
  ];
  const consumptionsRows = apartamentConsumtions?.map((item, i) => ({
    id: i,
    condominium_name: item?.meta?.condominium_name,
    sub_condominium_name: item?.meta?.sub_condominium_name,
    apartment_name: item?.meta?.apartment_name,
    serial_number: item?.meta?.serial_number,
    value:
      item.value !== null
        ? t("number", {
            val: item.value == "-0" ? "0" : item.value,
          })
        : "--",
  }));
  useEffect(() => {
    console.log("apcon", apartamentConsumtions);
  }, [apartamentConsumtions]);
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen) {
      return "63vw";
    }
    if (is1280x720 && !appBarIsOpen) {
      return "78vw";
    }
    if (!is1280x720 && appBarIsOpen) {
      return "69vw";
    }
    if (!is1280x720 && !appBarIsOpen) {
      return "79vw";
    }
  };
  return (
    <>
      <FormAccordion width={() => dinamicWidthSelector()}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          /*   customValidate={customValidate} */
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={(values) => onSubmitClick(values.formData)}
          validator={validator}
          uiSchema={uiSchema}
        />
      </FormAccordion>
      <MetersTableTab
        afs={
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
          >
            <Box
              sx={{
                height: 400,
                width: "35vw",
              }}
            >
              <StyledDataGrid
                disableColumnMenu
                rows={consumptionsRows ?? []}
                onSortModelChange={(model) => console.log("test")}
                columns={consumptionsColumns}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                rowCount={apartamentConsumtionsCount}
                /*        loading={isLoadingTable} */
                paginationMode="server"
                getRowHeight={() => "auto"}
              />
            </Box>
            {apartamentConsumtions?.length > 0 ? (
              <Box sx={{ ml: 2 }}>
                <ApartamentConsumtionsGrafic
                  meterData={apartamentConsumtions}
                />
              </Box>
            ) : (
              <Box>
                <Typography align="center" variant="h4">
                  {t("no_available_data")}
                </Typography>
              </Box>
            )}
          </Box>
        }
        heatMeter={
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
          >
            <Box
              sx={{
                height: 400,
                width: "35vw",
              }}
            >
              <StyledDataGrid
                disableColumnMenu
                rows={consumptionsRows ?? []}
                onSortModelChange={(model) => console.log("test")}
                columns={consumptionsColumns}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                rowCount={apartamentConsumtionsCount}
                /*        loading={isLoadingTable} */
                paginationMode="server"
                getRowHeight={() => "auto"}
              />
            </Box>
            {apartamentConsumtions?.length > 0 ? (
              <Box sx={{ ml: 2 }}>
                <ApartamentConsumtionsGrafic
                  meterData={apartamentConsumtions}
                />
              </Box>
            ) : (
              <Box>
                <Typography align="center" variant="h4">
                  {t("no_available_data")}
                </Typography>
              </Box>
            )}
          </Box>
        }
        setMeterType={setMeterType}
      />
    </>
  );
};
export default ApartamentConsumtionsTab;
