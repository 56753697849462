import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { setLiveMessages } from "../../redux/slices/messageSlice";
import theme from "../../theme";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import WsConnector from "../../utils/WsConnector";

const EdgeControllerMEssages = ({ idFromCondominium, id, twinId }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const messages = useSelector((state) => state?.gatewaySlice?.edgeMessages);
  const messagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.messagesFirstRender
  );
  const messagesMeta = useSelector(
    (state) => state?.gatewaySlice?.edgeMEssagesCount
  );
  const [getGatewayMessages, { isSuccess }] =
    store.useLazyGetEdgeMessagesQuery();
  const { t } = useTranslation();
  useEffect(() => {
    if (idFromCondominium || id) {
      getGatewayMessages({
        twinId: twinId,
        instanceId: idFromCondominium ? idFromCondominium : id,
        params: {
          page: page,
          per_page: perPage,
        },
      });
    }
  }, [idFromCondominium, id]);
  useEffect(() => {
    getGatewayMessages({
      twinId: twinId,
      instanceId: idFromCondominium ? idFromCondominium : id,
      params: {
        page: page,
        per_page: perPage,
      },
    });
  }, [page]);
  useEffect(() => {}, [id]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      /*   hide: true, */
      headerAlign: "left",
    },

    {
      field: "cpu_usage",
      headerName: t("cpu_usage"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "mem_usage",
      headerName: t("mem_usage"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "uptime",
      headerName: t("uptime"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      width: 100,
      wrapText: true,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Tooltip title={`${params?.value?.longTime}`}>
            <Typography>{params?.value?.shortTime}</Typography>
          </Tooltip>
        );
      },
    },
  ];
  useEffect(() => {}, [messages]);
  /*  const tableRows = messages?.map((item, i) => ({
    id: i,
    modem_mode: item?.attributes?.values?.values.modem_mode,
    modem_operator: item?.attributes?.values?.values.modem_operator,
    modem_rssi: item?.attributes?.values?.values.modem_rssi,
    uptime: secondsToDhms(item?.attributes?.values?.values.uptime),
    version_hw: item?.attributes?.values?.values.version_hw,
    version_sw: item?.attributes?.values?.values.version_sw,
    message_date: t("dt", { val: new Date(item?.attributes?.message_date) }),
  })); */
  const tableRows = messages?.map((item, i) => ({
    id: i,
    cpu_usage: item?.attributes?.values?.values?.cpu_usage,
    mem_usage: item?.attributes?.values?.values?.mem_usage,
    uptime: `${item?.attributes?.values?.values?.uptime} s`,
    message_date: {
      shortTime: t("date_val", {
        val: new Date(item?.attributes?.message_date),
      }),
      longTime: t("dt", {
        val: new Date(item?.attributes?.message_date),
      }),
    },
  }));

  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <WsConnector
        channelName={"message"}
        eventName={"messages_incoming"}
        setDataFunction={setLiveMessages}
        style={{ mr: 3 }}
      />
      <StyledDataGrid
        disableColumnMenu
        rows={tableRows ?? []}
        columns={columns ?? []}
        rowCount={messagesMeta}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          /*  !response.isFetching &&  */
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        /*  getRowHeight={() => "auto"} */
      />
    </Box>
  );
};
export default EdgeControllerMEssages;
