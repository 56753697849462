import { useState, useEffect } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import { prepareBodyParams } from "../utils/api_params";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
import { loadingOff, loadingOn } from "../redux/slices/loadingSlice";
import { StyledDataGrid } from "./StyledDataGrid";
import { IwdFormAuthWrapper } from "../utils/IwdFormAuthWrapper";
import ImportHistoryButton from "./ImportHistoryButton";
import { setErrors } from "../redux/slices/errorSlice";
import { useParams } from "react-router-dom";
import { sortBlockReadings } from "../utils/utilsFunctions";
import { Tooltip } from "@mui/material";
import { prepareParams } from "../utils/api_params";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import {
  setMessage as setMsg,
  setSeverity,
} from "../redux/slices/snackbarSlice";
import theme from "../theme";
import BadgeDatePicker from "../components/form/formComponents/BadgeDatePicker";
import { prepareBody } from "../utils/api_params";
import IwdDataGrid from "./IwdDataGrid";
import MetersTableTab from "../components/form/formComponents/MetersTableTab";
import Chip from "@mui/material/Chip";
import { setCondominiumReadings } from "../redux/slices/condominiumSlice";
import DraftsIcon from "@mui/icons-material/Drafts";
import FormAccordion from "../components/form/formComponents/Accordion";
import IconButton from "@mui/material/IconButton";
import {
  getDateDifferenceInDays,
  getDifferenceInDays,
} from "../utils/api_params";
import ModalDialog from "../components/meters/ModalDialog";
import SingleDatePicker from "../components/form/formComponents/SingleDatePicker";
import { resetCondominiumReadings } from "../redux/slices/condominiumSlice";
import { store } from "../redux/store";
import { Link } from "react-router-dom";
import BlockMetersTableTabs from "../components/form/formComponents/BlockMetersTableTabs";
import { objectFieldTemplate } from "../utils";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CondominiumReadingsTab = ({ condominiumId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const condominiumReadings = useSelector(
    (state) => state?.condominiumSlice?.condominiumReadings
  );
  const readingsCount = Math.ceil(
    useSelector(
      (state) => state?.condominiumSlice?.condominiumReadingsCount / 100
    )
  );
  const [deviceType, setDeviceType] = useState(["07"]);
  const stats = IwdFormAuthWrapper({ section: "block_stats" });
  const [readySort, setReadySort] = useState(false);
  const condominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.condominiumIdentifier
  );
  const condominiumname = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.name;
  const [filteredCondominiumReadings, setFilteredCondomoniumReadings] =
    useState();
  const [meterType, setMeterType] = useState(["AFS"]);
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const condominiumReadingsCount = useSelector(
    (state) => state?.condominiumSlice?.condominiumReadingsCount
  );
  const totalWater = useSelector(
    (state) => state?.condominiumSlice?.totalWater
  );
  const totalHca = useSelector((state) => state?.condominiumSlice?.totalHca);
  const totalHeat = useSelector((state) => state?.condominiumSlice?.totalHeat);
  const [exportReadings] = store.useExportCondominiumReadingsMutation();

  const [colorMeters, setColorMeters] = useState([]);
  const [lastWaterField, setLastWaterField] = useState(null);
  const [waterSorts, setWaterSorts] = useState([]);
  const [filterSrting, setFilterString] = useState(null);
  const [savedToDate, setSavedToDate] = useState(null);
  const [savedFromDate, setSavedFromDate] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [heatMeters, setHeatMeters] = useState([]);
  const [coldMeters, setColdMeters] = useState([]);
  const [readyToShow, setReadyToShow] = useState(false);

  const [afsMeters, setAfsMeters] = useState([]);
  const [acsMeters, setAcsMeters] = useState([]);
  const [heatCostMeters, setHeatCostMeters] = useState([]);
  const [getTotalReadings] = store.useLazyGetTotalReadingsQuery();
  const [sortModel, setSortModel] = useState([]);
  const [sortedColumns, setSortedColumns] = useState({});

  const resetFilter = () => {
    setFilterString(null);
    setLastWaterField(null);
    setReadySort(false);
    setWaterSorts([]);
  };

  const condominiumRadingsFilter = () => {
    if (meterType?.length > 0 && meterType?.[0] === "CALORIE / FRIGORIE") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        colorMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }

    if (meterType?.[0] === "ACS") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        acsMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    if (meterType?.[0] === "AFS") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        afsMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    if (meterType?.[0] === "RIPARTITORE") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        heatCostMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
  };
  useEffect(() => {
    condominiumRadingsFilter();
  }, [meterType, condominiumReadings]);
  const subblocks = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.children?.map((item) => item);

  const mappedSubblocks = {};
  subblocks.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedSubblocks[identifier] = obj;
  });
  const meterTypeSelector = (item) => {
    switch (item?.type) {
      case "08":
      case "8":
        if (item?.hasOwnProperty("last_reading")) {
          if (item?.last_reading?.value === null) {
            return item;
          } else return null;
        } else {
          return [];
        }
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        if (item?.hasOwnProperty("last_reading")) {
          if (item?.last_reading?.value === null) {
            return item;
          } else return null;
        } else {
          return [];
        }
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        if (
          item?.hasOwnProperty("cold_last_reading") ||
          item.hasOwnProperty("heat_last_reading")
        ) {
          if (
            item?.cold_last_reading?.value === null &&
            !item?.heat_last_reading?.value === null
          ) {
            return item;
          } else return null;
        } else {
          return [];
        }
    }
  };
  const allMeters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat();
  const mappedMeters = {};
  allMeters.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedMeters[identifier] = obj;
  });
  const meters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat();
  const allPulsMeter = allMeters
    ?.map((item) => item?.children)
    .flat()
    ?.filter((item) => item?.attributes?.serial_number.includes("-"));
  const pulsMetersIdId = allPulsMeter?.map(
    (item) => item?.attributes?.identifier
  );
  const meterIdCollector = () => {
    const combinedMeters = [...meters, ...allPulsMeter];

    setColorMeters(
      combinedMeters
        ?.filter(
          (item) =>
            item?.attributes?.meter_type === "CALORIE / FRIGORIE" ||
            item?.attributes?.meter_type === "CALORIE/FRIGORIE" ||
            item?.attributes?.meter_type === "Calorie" ||
            item?.attributes?.meter_type === "Frigorie"
        )
        ?.map((item) => item?.attributes?.identifier)
    );

    setAcsMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "ACS")
        ?.map((item) => item?.attributes?.identifier)
    );
    setAfsMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "AFS")
        ?.map((item) => item?.attributes?.identifier)
    );
    setHeatCostMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "RIPARTITORE")
        ?.map((item) => item?.attributes?.identifier)
    );
  };
  useEffect(() => {
    meterIdCollector();
  }, []);

  const apartments = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat();

  const mappedApartments = {};
  apartments.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedApartments[identifier] = obj;
  });
  useEffect(() => {}, [apartments]);
  const subcondominiumInfo = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.children?.[0]?.children;
  const nullMeter = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat()
    ?.filter((item) => meterTypeSelector(item?.attributes))

    ?.map((item) => ({
      children: item?.children,
      attributes: {
        device_id: item?.attributes?.identifier,
        meter_type: item?.attributes?.meter_type,
        values: {
          serial_number: item?.attributes?.serial_number,

          values: {
            apartment_name: item?.attributes?.apartment_name,
            condominium_name: item?.attributes?.condominium_name,
            sub_condominium_name: item?.attributes?.sub_condominium_name,
            internal:
              mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.internal,
            flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
              ?.flat,
            room: mappedMeters[item?.attributes?.identifier]?.attributes?.room,
          },
        },
      },
    }));
  const nullPulsMeters = nullMeter
    ?.map((item) =>
      item?.children?.filter(
        (item) => item?.attributes?.meter_type && !item?.last_reading?.value
      )
    )
    .flat()
    ?.map((item) => {
      const actualMeter = nullMeter?.filter((nm) =>
        item?.attributes?.serial_number.includes(
          nm?.attributes?.values?.serial_number
        )
      );
      return {
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,
            values: {
              apartment_name:
                actualMeter?.[0]?.attributes?.values?.values?.apartment_name,
              condominium_name:
                actualMeter?.[0]?.attributes?.values?.values?.condominium_name,
              sub_condominium_name:
                actualMeter?.[0]?.attributes?.values?.values
                  ?.sub_condominium_name,
            },
          },
        },
      };
    });

  const filteredNullMeters =
    readyToShow &&
    nullMeter?.filter((item) =>
      meterType.includes(item?.attributes?.meter_type)
    );
  const filteredNullPulsMeters = nullPulsMeters?.filter((item) =>
    meterType.includes(item?.attributes?.meter_type)
  );
  const mappedFilteredReadings = filteredCondominiumReadings?.map((item) => {
    if (
      item &&
      item.attributes &&
      item.attributes.values &&
      item.attributes.values.values
    ) {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          values: {
            ...item.attributes.values,
            values: {
              ...item.attributes.values.values,
              internal:
                mappedApartments[item?.attributes?.values?.values?.apartment_id]
                  ?.attributes?.internal,
              flat: mappedApartments[
                item?.attributes?.values?.values?.apartment_id
              ]?.attributes?.flat,
              room: mappedMeters[item?.attributes?.values?.device_identifier]
                ?.attributes?.room,
              subblock_pos:
                mappedSubblocks?.[
                  item?.attributes?.values?.values?.sub_condominium_id
                ]?.attributes?.position,
              app_pos:
                mappedApartments?.[
                  item?.attributes?.values?.values?.apartment_id
                ]?.attributes?.position,
            },

            value: {
              ...item.attributes?.values?.values?.value,
            },
          },
        },
      };
    }

    return item;
  });

  useEffect(() => {}, [colorMeters]);
  const allMeterIds = allMeters?.map((item) => item?.attributes?.identifier);
  const filteredReadingsIds = filteredCondominiumReadings?.map(
    (item) => item?.attributes?.values?.device_identifier
  );
  const newPulsMetersIds = pulsMetersIdId?.filter(
    (item) => !filteredReadingsIds?.includes(item)
  );
  const mappedPulsMeters = {};
  allPulsMeter.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedPulsMeters[identifier] = obj;
  });

  const newNullMeterIds = allMeterIds?.filter(
    (item) => !filteredReadingsIds?.includes(item)
  );

  const newNullPulsMeters = newPulsMetersIds?.reduce((acc, key) => {
    if (mappedPulsMeters.hasOwnProperty(key)) {
      acc[key] = mappedPulsMeters[key];
    }
    return acc;
  }, {});

  const newNullMeters = newNullMeterIds.reduce((acc, key) => {
    if (mappedMeters.hasOwnProperty(key)) {
      acc[key] = mappedMeters[key];
    }
    return acc;
  }, {});
  const mappedNullPulsMeters =
    condominiumReadings &&
    Object.values(newNullPulsMeters)
      ?.map((item) => ({
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,
            network_address:
              item?.attributes?.[item?.attributes?.identifier_type],
            values: {
              apartment_name: item?.attributes?.apartment_name,
              condominium_name: item?.attributes?.condominium_name,
              sub_condominium_name: item?.attributes?.sub_condominium_name,
              internal:
                mappedApartments[item?.attributes?.apartment_id]?.attributes
                  ?.internal,
              flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.flat,
              room: mappedMeters[item?.attributes?.identifier]?.attributes
                ?.room,
              subblock_pos:
                mappedSubblocks?.[item?.attributes?.sub_condominium_id]
                  ?.attributes?.position,
              app_pos:
                mappedApartments?.[item?.attributes?.apartment_id]?.attributes
                  ?.position,
              value: {
                ...item.attributes?.values?.values?.value,
              },
            },
          },
        },
      }))
      ?.filter((item) => meterType.includes(item?.attributes?.meter_type));
  useEffect(() => {
    console.log("newNullMeters", newNullMeters);
  }, [newNullMeters]);
  const mappedNullMeters =
    condominiumReadings &&
    Object.values(newNullMeters)
      ?.map((item) => ({
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,
            network_address:
              item?.attributes?.[item?.attributes?.identifier_type],
            values: {
              apartment_name: item?.attributes?.apartment_name,
              condominium_name: item?.attributes?.condominium_name,
              sub_condominium_name: item?.attributes?.sub_condominium_name,
              internal:
                mappedApartments[item?.attributes?.apartment_id]?.attributes
                  ?.internal,
              flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.flat,
              room: mappedMeters[item?.attributes?.identifier]?.attributes
                ?.room,
              subblock_pos:
                mappedSubblocks?.[item?.attributes?.sub_condominium_id]
                  ?.attributes?.position,
              app_pos:
                mappedApartments?.[item?.attributes?.apartment_id]?.attributes
                  ?.position,
            },
          },
        },
      }))
      ?.filter((item) => meterType.includes(item?.attributes?.meter_type));

  const allReadings =
    condominiumReadings &&
    filteredCondominiumReadings &&
    nullMeter &&
    readyToShow
      ? waterSorts?.length == 0
        ? sortBlockReadings([
            ...mappedNullMeters,

            ...mappedNullPulsMeters,
            ...mappedFilteredReadings,
          ])
        : [
            ...mappedNullMeters,

            ...mappedNullPulsMeters,
            ...mappedFilteredReadings,
          ]
      : [];
  const { t } = useTranslation();
  useEffect(() => {}, [allReadings, newNullMeterIds]);
  const [formData, setFormData] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const now = new Date();
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );

  now.setHours(0, 0, 0, 0);
  const [getReadings, { isSuccess, status }] =
    store.useLazyGetCondominiumReadingsQuery();
  const fields = {
    datepicker: BadgeDatePicker,
  };
  useEffect(() => {}, [readingsCount]);
  const schema = {
    type: "object",
    properties: {
      network_address: {
        id: 2,
        title: t("network_address"),
        type: "string",
      },
      mechanical_serial_number: {
        id: 2,
        title: t("metrological_serial"),
        type: "string",
      },
      /* condominium_name: {
        id: 4,
        title: t("condominium_name"),
        type: "string",
      },
      sub_condominium_name: {
        id: 5,
        title: t("sub_condominium_name"),
        type: "string",
      }, */
      apartment_name: {
        id: 1,
        title: t("apartment_name"),
        type: "string",
      },
      insertion_date: {
        id: 3,
        title: t("inserted_at"),
        type: "object",
        badgemode: true,
        properties: {
          to_date: {
            type: "string",
            default: now.toISOString(),
          },
          from_date: {
            type: "string",
          },
        },
      },

      /*  read_time: {
        id: 6,
        title: t("read_tie"),
        type: "string",
        label: "read_time",
      }, */
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
    read_time: {
      "ui:field": "datepicker",
    },
  };

  useEffect(() => {
    if (condominiumId) {
      const to = new Date();

      const fromDate = new Date(
        Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1)
      );
      const toDate = new Date(
        Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1)
      );

      getReadings({
        condominium_id: condominiumIdentifier,

        to_date: savedToDate ?? toDate.toISOString(),

        most_recent_2: true,
        message_type: "reading",
      });
      setReadySort(true);
    }
  }, [condominiumId, meterType]);

  useEffect(() => {
    const to = new Date();

    const fromDate = new Date(
      Date.UTC(to.getFullYear(), to.getMonth(), to.getDate())
    );
    const toDate = new Date(
      Date.UTC(to.getFullYear(), to.getMonth(), to.getDate())
    );
    toDate.setUTCHours(23, 59, 59, 59);
    stats &&
      getTotalReadings({
        id: id,
        params: {
          from: fromDate.toISOString(),
          to: toDate.toISOString(),
        },
      });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setFirstRender(false);
    }
  }, [isSuccess]);
  useEffect(() => {}, [status]);

  const openMessageModal = () => {
    setMessageModal(true);
  };
  const closeMessageModal = () => {
    setMessageModal(false);
    setMessage("");
  };
  const onMailClick = (msg) => {
    setMessage(msg);
    openMessageModal();
  };

  const readingRows = allReadings?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      serial_number:
        item?.attributes?.extra?.network_address ??
        item?.attributes?.values?.network_address,
      metrological_serial:
        item?.attributes?.extra?.metrological_serial ??
        item?.attributes?.values?.serial_number,
      last_reading:
        item?.attributes?.values?.values?.value?.last_reading &&
        item?.attributes?.values?.values?.value?.last_reading?.value !== null
          ? t("number", {
              val: item?.attributes?.values?.values?.value?.last_reading?.value,
            })
          : "--",
      unit:
        item.attributes?.values?.values.value?.last_reading?.unit == "m^3"
          ? "m³"
          : item.attributes?.values?.values.value?.last_reading?.unit ?? "--",
      read_time: item?.attributes?.values?.read_time
        ? t("date_val", { val: new Date(item?.attributes?.values?.read_time) })
        : "--",
      alarms: {
        alarms: item?.attributes?.values?.values?.value?.alarms,
        mapped_alarms: item?.attributes?.values?.values?.value?.mapped_alarms,
      },
      anomalies:
        item?.attributes?.values?.values?.value?.mapped_anomalies &&
        item?.attributes?.values?.values?.value?.mapped_anomalies?.length > 0 &&
        item?.attributes?.values?.values?.value?.mapped_anomalies,

      apartment_name: item?.attributes?.values?.values?.apartment_name,
      condominium_name: item?.attributes?.values?.values?.condominium_name,
      internal: item?.attributes?.values?.values?.internal ?? "--",
      flat: item?.attributes?.values?.values?.flat ?? "--",
      sub_condominium_name:
        item?.attributes?.values?.values?.sub_condominium_name,
      actions: item?.attributes,
      dayDiff: getDifferenceInDays(
        item?.attributes?.values?.read_time,
        formData?.insertion_date?.to_date
      ),
    };
  });

  const handleWaterHeaderClick = (field) => {
    setLastWaterField(field);
  };
  const readingColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,

      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",
      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "flat",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "read_time",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 5,
      editable: false,
    },
    {
      field: "last_reading",
      headerName: t("last_reading_value"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      nosort: true,
      flex: 2,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 5,
      editable: false,
      renderCell: (params) => {
        if (params?.value?.alarms && params.value.alarms.length > 0) {
          if (
            !params?.value?.mapped_alarms ||
            params.value.mapped_alarms.length === 0
          ) {
            return (
              <Chip
                label={t("in_progress")}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                }}
              />
            );
          }
          if (params.value.mapped_alarms.length > 0) {
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {params.value.mapped_alarms?.map((item, index) => (
                  <Chip
                    key={index}
                    sx={{
                      background: theme.palette.error.light,
                      color: theme.palette.error.contrastText,
                      mt: 1,
                    }}
                    label={item}
                  />
                ))}
              </Box>
            );
          }
        }
        return "";
      },
    },

    {
      field: "anomalies",
      headerName: t("anomalies"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 6,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {params?.value?.map((item, index) => (
              <Tooltip title={item}>
                <Chip
                  key={index}
                  sx={{
                    background: theme.palette.error.light,
                    color: theme.palette.error.contrastText,
                    mt: 1,
                  }}
                  label={item}
                />
              </Tooltip>
            ))}
          </Box>
        );
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      nosort: true,
      headerAlign: "right",
      flex: 4,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <IwdAuthWrapper
              children={
                <IconButton
                  disabled={!params?.value?.values?.values?.value}
                  onClick={() =>
                    onMailClick(params?.value?.values?.values?.value)
                  }
                >
                  <DraftsIcon sx={{ fill: "red" }} />
                </IconButton>
              }
              section={"block_msg_btn"}
            />
            {params?.value?.device_id && (
              <IwdAuthWrapper
                children={
                  <IconButton sx={{ mt: 0.5 }}>
                    <Link
                      to={`/meters/${params?.value?.device_id}`}
                      target="_blank"
                    >
                      <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                    </Link>
                  </IconButton>
                }
                section={"block_show_btns"}
              />
            )}
          </Box>
        );
      },
    },
  ];

  const heatColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "flat",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "read_time",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "heat_last_reading",
      headerName: t("last_reading_heat_val"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "cold_last_reading",
      headerName: t("last_reading_cold_val"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      nosort: true,
      editable: false,
      flex: 2,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 5,
      editable: false,
      renderCell: (params) => {
        if (params?.value?.alarms && params.value.alarms.length > 0) {
          if (
            !params?.value?.mapped_alarms ||
            params.value.mapped_alarms.length === 0
          ) {
            return (
              <Chip
                label={t("in_progress")}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                }}
              />
            );
          }
          if (params.value.mapped_alarms.length > 0) {
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {params.value.mapped_alarms?.map((item, index) => (
                  <Chip
                    key={index}
                    sx={{
                      background: theme.palette.error.light,
                      color: theme.palette.error.contrastText,
                      mt: 1,
                    }}
                    label={item}
                  />
                ))}
              </Box>
            );
          }
        }
        return "";
      },
    },
    {
      field: "anomalies",
      headerName: t("anomalies"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 6,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {params?.value?.map((item, index) => (
              <Tooltip title={item}>
                <Chip
                  key={index}
                  sx={{
                    background: theme.palette.error.light,
                    color: theme.palette.error.contrastText,
                    mt: 1,
                  }}
                  label={item}
                />
              </Tooltip>
            ))}
          </Box>
        );
      },
    },
    /*  {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    }, */

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      nosort: true,
      headerAlign: "right",
      flex: 4,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <IwdAuthWrapper
              children={
                <IconButton
                  disabled={!params?.value?.values?.values?.value}
                  onClick={() =>
                    onMailClick(params?.value?.values?.values?.value)
                  }
                >
                  <DraftsIcon sx={{ fill: "red" }} />
                </IconButton>
              }
              section={"block_msg_btn"}
            />
            {params?.value?.device_id && (
              <IwdAuthWrapper
                children={
                  <IconButton sx={{ mt: 0.5 }}>
                    <Link
                      to={`/meters/${params?.value?.device_id}`}
                      target="_blank"
                    >
                      <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                    </Link>
                  </IconButton>
                }
                section={"block_show_btns"}
              />
            )}
          </Box>
        );
      },
    },
  ];
  const heatRows = allReadings?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      serial_number:
        item?.attributes?.extra?.network_address ??
        item?.attributes?.values?.network_address,
      metrological_serial:
        item?.attributes?.extra?.metrological_serial ??
        item?.attributes?.values?.serial_number,
      cold_last_reading:
        item?.attributes?.values?.values?.value?.cold_last_reading?.value !==
          null &&
        typeof item?.attributes?.values?.values?.value?.cold_last_reading ===
          "object" &&
        Object.keys(item?.attributes?.values?.values?.value?.cold_last_reading)
          .length !== 0
          ? /* t("number", {
              val:
                item?.attributes?.values?.values?.value?.cold_last_reading
                  ?.value / 1000,
            }) */ item?.attributes?.values?.values?.value?.cold_last_reading
              ?.value / 1000
          : "--",
      heat_last_reading:
        item?.attributes?.values?.values?.value?.heat_last_reading?.value !==
          null &&
        typeof item?.attributes?.values?.values?.value?.heat_last_reading ===
          "object" &&
        Object.keys(item?.attributes?.values?.values?.value?.heat_last_reading)
          .length !== 0
          ? /* t("number", {
              val:
                item?.attributes?.values?.values?.value?.heat_last_reading
                  ?.value / 1000,
            }) */ item?.attributes?.values?.values?.value?.heat_last_reading
              ?.value / 1000
          : "--",
      unit:
        item?.attributes?.values?.values?.value?.heat_last_reading?.unit ===
        "Wh"
          ? "kWh"
          : item?.attributes?.values?.values?.value?.heat_last_reading?.unit ??
            "--",
      read_time: item?.attributes?.values?.read_time
        ? t("date_val", { val: new Date(item?.attributes?.values?.read_time) })
        : "--",
      alarms: {
        alarms: item?.attributes?.values?.values?.value?.alarms,
        mapped_alarms: item?.attributes?.values?.values?.value?.mapped_alarms,
      },
      anomalies:
        item?.attributes?.values?.values?.value?.mapped_anomalies &&
        item?.attributes?.values?.values?.value?.mapped_anomalies?.length > 0 &&
        item?.attributes?.values?.values?.value?.mapped_anomalies,

      apartment_name: item?.attributes?.values?.values?.apartment_name,
      condominium_name: item?.attributes?.values?.values?.condominium_name,
      internal: item?.attributes?.values?.values?.internal ?? "--",
      flat: item?.attributes?.values?.values?.flat ?? "--",
      sub_condominium_name:
        item?.attributes?.values?.values?.sub_condominium_name,
      actions: item?.attributes,
      dayDiff:
        /* getDateDifferenceInDays(item?.attributes?.values?.read_time) */ getDifferenceInDays(
          item?.attributes?.values?.read_time,
          formData?.insertion_date?.to_date
        ),
    };
  });

  const heatCostColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "flat",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "room",
      headerName: t("room"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("network_address"),
      type: "string",

      flex: 4,
      editable: false,
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "read_time",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "last_unconverted_reading",
      headerName: t("last_unconverted_reading"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "k",
      headerName: t("K"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "last_reading",
      headerName: t("parameterized_reading"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 5,
      editable: false,
      renderCell: (params) => {
        if (params?.value?.alarms && params.value.alarms.length > 0) {
          if (
            !params?.value?.mapped_alarms ||
            params.value.mapped_alarms.length === 0
          ) {
            return (
              <Chip
                label={t("in_progress")}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                }}
              />
            );
          }
          if (params.value.mapped_alarms.length > 0) {
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {params.value.mapped_alarms?.map((item, index) => (
                  <Chip
                    key={index}
                    sx={{
                      background: theme.palette.error.light,
                      color: theme.palette.error.contrastText,
                      mt: 1,
                    }}
                    label={item}
                  />
                ))}
              </Box>
            );
          }
        }
        return "";
      },
    },

    {
      field: "anomalies",
      headerName: t("anomalies"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 6,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {params?.value?.map((item, index) => (
              <Tooltip title={item}>
                <Chip
                  key={index}
                  sx={{
                    background: theme.palette.error.light,
                    color: theme.palette.error.contrastText,
                    mt: 1,
                  }}
                  label={item}
                />
              </Tooltip>
            ))}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      nosort: true,
      headerAlign: "right",
      flex: 4,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <IwdAuthWrapper
              children={
                <IconButton
                  disabled={!params?.value?.values?.values?.value}
                  onClick={() =>
                    onMailClick(params?.value?.values?.values?.value)
                  }
                >
                  <DraftsIcon sx={{ fill: "red" }} />
                </IconButton>
              }
              section={"block_msg_btn"}
            />
            {params?.value?.device_id && (
              <IwdAuthWrapper
                children={
                  <IconButton sx={{ mt: 0.5 }}>
                    <Link
                      to={`/meters/${params?.value?.device_id}`}
                      target="_blank"
                    >
                      <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                    </Link>
                  </IconButton>
                }
                section={"block_show_btns"}
              />
            )}
          </Box>
        );
      },
    },
  ];
  const heatCostRows = allReadings?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      serial_number:
        item?.attributes?.extra?.network_address ??
        item?.attributes?.values?.network_address,
      metrological_serial:
        item?.attributes?.extra?.metrological_serial ??
        item?.attributes?.values?.serial_number,
      last_reading:
        item?.attributes?.values?.values?.value?.last_reading &&
        item?.attributes?.values?.values?.value?.last_reading?.value !== null &&
        item?.attributes?.values?.values?.value?.last_reading?.value !== null
          ? /*  t("number", {
              val: item?.attributes?.values?.values?.value?.last_reading?.value,
            }) */ Math.round(
              item?.attributes?.values?.values?.value?.last_reading?.value
            )
          : "--",
      last_unconverted_reading:
        item?.attributes?.values?.values?.value?.last_unconverted_reading
          ?.value !== null &&
        typeof item?.attributes?.values?.values?.value
          ?.last_unconverted_reading === "object" &&
        item?.attributes?.values?.values?.value?.last_unconverted_reading !==
          null &&
        Object.keys(
          item?.attributes?.values?.values?.value?.last_unconverted_reading
        ).length !== 0
          ? /*  t("number", {
              val: item?.attributes?.values?.values?.value
                ?.last_unconverted_reading?.value,
            }) */ Math.round(
              item?.attributes?.values?.values?.value?.last_unconverted_reading
                ?.value
            )
          : "--",
      /*   unit: item.attributes?.values?.values.value?.last_reading?.unit ?? "--", */
      k:
        item?.attributes?.values?.values?.value?.k !== null
          ? t("number", {
              val: item?.attributes?.values?.values?.value?.k,
            })
          : "--",
      read_time:
        t("date_val", { val: new Date(item?.attributes?.values?.read_time) }) ??
        "--",
      alarms: {
        alarms: item?.attributes?.values?.values?.value?.alarms,
        mapped_alarms: item?.attributes?.values?.values?.value?.mapped_alarms,
      },
      anomalies:
        item?.attributes?.values?.values?.value?.mapped_anomalies &&
        item?.attributes?.values?.values?.value?.mapped_anomalies?.length > 0 &&
        item?.attributes?.values?.values?.value?.mapped_anomalies,

      apartment_name: item?.attributes?.values?.values?.apartment_name,
      internal: item?.attributes?.values?.values?.internal ?? "--",
      flat: item?.attributes?.values?.values?.flat ?? "--",
      room: item?.attributes?.values?.values?.room ?? "--",
      condominium_name: item?.attributes?.values?.values?.condominium_name,
      sub_condominium_name:
        item?.attributes?.values?.values?.sub_condominium_name,
      actions: item?.attributes,
      dayDiff:
        /*  getDateDifferenceInDays(item?.attributes?.values?.read_time), */ getDifferenceInDays(
          item?.attributes?.values?.read_time,
          formData?.insertion_date?.to_date
        ),
    };
  });

  const onSubmitClick = (obj) => {
    dispatch(setCondominiumReadings([]));
    resetFilter();
    const insertionDate = new Date(formData.insertion_date.to_date);
    const totalFromDate = new Date(formData.insertion_date.to_date);
    const totalToDate = new Date(formData.insertion_date.to_date);
    const messagesDateTo = new Date(insertionDate);
    const messageFromDate = new Date(insertionDate);

    // Добавляем один день, используя setUTCDate для корректного учета конца месяца
    messagesDateTo.setUTCDate(insertionDate.getUTCDate() + 1);
    messageFromDate.setUTCDate(insertionDate.getUTCDate() + 1);
    totalFromDate.setUTCDate(insertionDate.getUTCDate());
    totalToDate.setUTCDate(insertionDate.getUTCDate());
    totalToDate.setUTCHours(23, 59, 59, 59);

    // Устанавливаем время для messagesDateTo на конец следующего дня
    /*   messagesDateTo.setUTCHours(23, 59, 59, 999); */
    setSavedToDate(messagesDateTo.toISOString());
    setSavedFromDate(obj?.read_time?.read_time);
    getReadings({
      ...obj,
      condominium_id: condominiumIdentifier,
      /*  no_gateway: true, */
      page: page,
      to_date: messagesDateTo.toISOString(),
      most_recent_2: true,
      message_type: "reading",
      from_date:
        formData?.insertion_date?.from_date &&
        formData?.insertion_date?.from_date,
      /*     nearest_message: true, */
      /*  meter_types: JSON.stringify(meterType), */
    });
    stats &&
      getTotalReadings({
        id: id,
        params: {
          from: totalFromDate.toISOString(),
          to: totalToDate.toISOString(),
        },
      });
  };

  useEffect(() => {
    if (!firstRender && readySort) {
      dispatch(resetCondominiumReadings());
      const insertionDate = new Date(formData.insertion_date.to_date);
      const messagesDateTo = new Date(insertionDate);
      messagesDateTo.setUTCDate(insertionDate.getUTCDate() + 1);
      getReadings({
        ...formData,
        condominium_id: condominiumIdentifier,
        /*  no_gateway: true, */
        page: page,
        to_date: messagesDateTo.toISOString(),
        most_recent_2: true,
        message_type: "reading",
        /*     nearest_message: true, */
        /* meter_types: JSON.stringify(meterType), */
        sorts: waterSorts,
      });
    }
  }, [waterSorts]);

  useEffect(() => {
    if (status === "fulfilled") {
      setReadyToShow(true);
    }
  }, [status]);

  useEffect(() => {
    if (!firstRender) {
      setReadyToShow(false);
    }
  }, [meterType]);
  const bearer = localStorage.getItem("user_token");
  let sortix = waterSorts;
  let fileName = "";
  const onExportClick = () => {
    dispatch(loadingOn());
    const messagesDateTo = new Date(formData.insertion_date.to_date);
    messagesDateTo.setDate(messagesDateTo.getDate() + 1);
    setExportModal(true);

    const attributes = {
      to_date: messagesDateTo.toISOString(),
      most_recent_2: true,
      message_type: "reading",
      condominium_id: condominiumId,
      sorts: JSON.parse(localStorage.getItem("sorts")),
      /*    condominium_name: condominiumname, */
    };
    const fromAttributes = {
      to_date: messagesDateTo.toISOString(),
      from_date: formData?.insertion_date?.from_date,

      message_type: "reading",
      condominium_id: condominiumId,
      sorts: JSON.parse(localStorage.getItem("sorts")),
      /*    condominium_name: condominiumname, */
    };
    fetch(`${window.BASE_URL}/api/v1/plugin/condominium_export`, {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify(
        prepareBody(
          prepareBodyParams(
            formData?.insertion_date?.from_date ? fromAttributes : attributes
          )
        )
      ),
    })
      .then((response) => {
        const contentDisposition = response.headers.get("content-disposition");
        fileName = contentDisposition
          .split(";")
          .find((part) => part.trim().startsWith("filename="))
          .split("=")[1]
          .trim();

        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        setExportModal(false);
        dispatch(loadingOff());
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
        setExportModal(false);
        dispatch(setSeverity("error"));
        dispatch(loadingOff());
        dispatch(setErrors({ error: "Esportazione non è andata a buon fine" }));
      });
  };

  useEffect(() => {
    localStorage.setItem("sorts", JSON.stringify(waterSorts));
  }, [waterSorts]);
  const styleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange custom-colored`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled custom-colored`;
    }
    if (params?.row?.last_reading == "--") {
      return `super-app-theme--Rejected custom-colored`;
    }
    if (params?.row?.anomalies?.length > 0) {
      return `super-app-theme--anomaly custom-colored`;
    }
  };
  const heatStyleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange custom-colored`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled custom-colored`;
    }
    if (params?.row?.anomalies?.length > 0) {
      return `super-app-theme--anomaly custom-colored`;
    }
    if (
      params?.row?.heat_last_reading == "--" &&
      params?.row?.cold_last_reading == "--"
    ) {
      return `super-app-theme--Rejected custom-colored`;
    }
  };

  const handleWaterSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      const newSort = newModel[0];

      setWaterSorts((prevSorts) => {
        const existingSortIndex = prevSorts.findIndex(
          (sort) => sort.field === newSort.field
        );

        if (existingSortIndex !== -1) {
          const updatedSorts = [...prevSorts];
          updatedSorts[existingSortIndex] = newSort;
          return updatedSorts;
        } else {
          return [...prevSorts, newSort];
        }
      });
    } else if (lastWaterField) {
      setWaterSorts((prevSorts) =>
        prevSorts?.filter((sort) => sort.field !== lastWaterField)
      );
      setLastWaterField(null);
    }
  };

  const handleHeaderClick = (field) => {
    const isSorted = waterSorts.find((sort) => sort.field === field);
    if (!isSorted) {
      handleWaterSortModelChange([{ field, sort: "asc" }]);
    } else if (isSorted.sort === "asc") {
      handleWaterSortModelChange([{ field, sort: "desc" }]);
    } else {
      handleWaterSortModelChange([]);
    }
  };

  const formatSorts = (waterSorts) => {
    if (!Array.isArray(waterSorts)) {
      throw new Error("waterSorts must be an array");
    }

    const queryString = waterSorts
      ?.map(({ field, sort }) => `sorts[]=${field}&orders[]=${sort}`)
      .join("&");
    return queryString;
  };

  useEffect(() => {
    setFilterString(formatSorts(waterSorts));
  }, [waterSorts]);

  return (
    <>
      <ModalDialog
        open={messageModal}
        close={() => closeMessageModal()}
        title={"Message"}
      >
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",
                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
        </Box>
      </ModalDialog>
      <ModalDialog open={exportModal} title={t("note")} cancell={false}>
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          {t("exporting_data")}
          {/* <CircularProgress /> */}
        </Box>
      </ModalDialog>
      <FormAccordion height={205}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          validator={validator}
          uiSchema={uiSchema}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
            sx={{ mt: 2 }}
          >
            <Button
              variant={window.BUTTON_TYPE}
              onClick={() => onExportClick()}
              sx={{ mr: 2 }}
            >
              Export
            </Button>
            <Button
              variant={window.BUTTON_TYPE}
              onClick={() => onSubmitClick(formData)}
            >
              {t("search")}
            </Button>
          </Box>
        </Form>
      </FormAccordion>
      {meterType == "RIPARTITORE" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <IwdAuthWrapper
            section={"import_hca_history_btn"}
            children={<ImportHistoryButton id={id} mode={"block"} />}
          />
        </Box>
      )}
      {allReadings && (
        <Box sx={{ mt: 1 }}>
          <BlockMetersTableTabs
            resetFilter={resetFilter}
            afs={
              <Box>
                {typeof totalWater === "number" && !isNaN(totalWater) && (
                  <Typography sx={{ mb: 1 }} align="end">{`${t(
                    "readings"
                  )} : ${t("number", {
                    val: totalWater,
                  })} %`}</Typography>
                )}

                {readyToShow && (
                  <IwdDataGrid
                    columns={readingColumns}
                    perPage={perPage}
                    setPage={setPage}
                    rows={readingRows}
                    page={page}
                    count={readingRows?.length}
                    setSorts={setWaterSorts}
                    sorts={waterSorts}
                    height={680}
                    styles={styleSelector}
                    paginationMode={"client"}
                    tableName={"block_water_meters"}
                  />
                )}
              </Box>
            }
            heatMeter={
              <Box>
                {typeof totalHca === "number" && !isNaN(totalHca) && (
                  <Typography sx={{ mb: 1 }} align="end">{`${t(
                    "readings"
                  )} : ${t("number", {
                    val: totalHca,
                  })} %`}</Typography>
                )}

                {readyToShow && (
                  <IwdDataGrid
                    columns={heatCostColumns}
                    perPage={perPage}
                    setPage={setPage}
                    rows={heatCostRows}
                    page={page}
                    count={heatCostRows?.length}
                    setSorts={setWaterSorts}
                    sorts={waterSorts}
                    height={680}
                    styles={styleSelector}
                    paginationMode={"client"}
                    tableName={"block_heat_meters"}
                  />
                )}
              </Box>
            }
            colorMeter={
              <Box>
                {typeof totalHeat === "number" && !isNaN(totalHeat) && (
                  <Typography sx={{ mb: 1 }} align="end">{`${t(
                    "readings"
                  )} : ${t("number", {
                    val: totalHeat,
                  })} %`}</Typography>
                )}

                {readyToShow && (
                  <IwdDataGrid
                    columns={heatColumns}
                    perPage={perPage}
                    setPage={setPage}
                    rows={heatRows}
                    page={page}
                    count={heatRows?.length}
                    setSorts={setWaterSorts}
                    sorts={waterSorts}
                    height={680}
                    styles={heatStyleSelector}
                    paginationMode={"client"}
                    tableName={"block_color_meters"}
                  />
                )}
              </Box>
            }
            waterVisibility={acsMeters?.length > 0 || afsMeters?.length > 0}
            hcaVisibility={heatCostMeters?.length > 0}
            colorVisibility={colorMeters?.length > 0}
            setMeterType={setMeterType}
            stats={status}
            meterType={meterType}
            /*  setDeviceType={setDeviceType} */
          />
        </Box>
      )}
    </>
  );
};
export default CondominiumReadingsTab;
