import { createSlice } from "@reduxjs/toolkit";
import { digitalTwinApi } from "./digitalTwinApi";
import { store } from "../store";
const initialState = {
  digitalTwin: null,
  digitalTwins: null,
  digitalTwinName: null,
};

const digitalTwinSlice = createSlice({
  name: "digitalTwinSlice",
  initialState,
  reducers: {
    setDigitalTwin(state, action) {
      if (action.payload) {
        state.digitalTwin = action.payload;
      }
    },
    unSetDigitalTwin(state) {
      state.digitalTwin = null;
      state.digitalTwinName = null;
    },
    setDigitalTwinName(state, action) {
      state.digitalTwinName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.digitalTwinEndpoints.getDigitalTwins.matchFulfilled,
      (state, action) => {
        state.digitalTwins = action.payload.data;
      }
    );
    builder.addMatcher(
      store.digitalTwinEndpoints.getDigitalTwinAttributesShow.matchFulfilled,
      (state, action) => {
        state.digitalTwin = action.payload.data;
      }
    );
  },
});

export const { setDigitalTwin, unSetDigitalTwin, setDigitalTwinName } =
  digitalTwinSlice.actions;
export default digitalTwinSlice.reducer;
