// import { api } from "./api";
//
import { prepareBodyWithoutId } from "../../utils/api_params";
export const authApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      signout: build.mutation({
        query: () => ({
          url: "v2/session",
          method: "DELETE",
        }),
      }),
      confirmRegistration: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v2/user/confirm_registration",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
      login: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v2/session",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "session")),
        }),
      }),
      resetPassword: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v2/user/reset_password",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
      confirmResetPassword: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v2/user/reset_password",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
    }),
  });

// export const { useSignoutMutation } = authApi;
