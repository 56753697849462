import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { setMessage } from "../../redux/slices/snackbarSlice";
import clipboardCopy from "clipboard-copy";

const CopyTextButton = ({ text }) => {
  const dispatch = useDispatch();
  const copyToClipboard = () => {
    clipboardCopy(text);
    dispatch(setMessage("Сopied"));
  };

  return (
    <Button
      sx={{ float: "right" }}
      variant={window.BUTTON_TYPE}
      onClick={copyToClipboard}
    >
      Copy
    </Button>
  );
};

export default CopyTextButton;
