import { method } from "lodash";
import { prepareBodyWithoutId, prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { queries } from "@testing-library/react";
const CompaignsType = "campaings";
const positionType = "position";

export const balanceApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      createBalance: build.mutation({
        query: (attributes) => ({
          url: "v1/plugin/balances",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      updateBalance: build.mutation({
        query: ({ id, attributes }) => ({
          method: "PUT",
          url: `/v1/plugin/balances/${id}`,
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      getBalanceIndex: build.query({
        query: (params) => {
          return `v1/plugin/balances?${prepareParams(params)}`;
        },
      }),
      deleteBalance: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/plugin/balances/${id}`,
        }),
      }),
      getBalanceShow: build.query({
        query: (id) => {
          return `v1/plugin/balances/${id}`;
        },
      }),
      getBalanceValues: build.query({
        query: ({ id, params }) => ({
          url: `v1/plugin/balances/${id}/values?${prepareParams(params)}`,
        }),
      }),
    }),
  });

/* export const {
  usePostCampaignMutation,
  useLazyGetIndexCampaignQuery,
  useLazyGetShowCampaignQuery,
  useStartCampaignMutation,
  useStopCampaignMutation,
  useDeleteCampaignMutation,
} = CampaignApi;
 */
