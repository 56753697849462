import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { store } from "../../redux/store";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import theme from "../../theme";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import ColorPickerWidget from "./ColorPickerWidget";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import DeleteButton from "../../utils/DeleteButton";
import ImageUpload from "./ImageUpload";
import { setConfigTrashModal } from "../../redux/slices/configurationSlice";
import { useParams } from "react-router-dom";
import { setConfigTrashId } from "../../redux/slices/configurationSlice";

const ObjectFieldTemplate = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "colors") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button type="submit" variant={window.BUTTON_TYPE} color="primary">
              {t("update")}
            </Button>
          </Grid>
          <Grid item>
            <DeleteButton
              name={t("delete")}
              onClick={() => dispatch(setConfigTrashModal(true))}
            />
            {/* <Button
              type="button"
              variant={window.BUTTON_TYPE}
              color="secondary"
              onClick={() => dispatch(setConfigTrashModal(true))}
              sx={{
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.main,
                },
              }}
            >
              {t("delete")}
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ConfigShow = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.configurationSlice?.configShow);
  const trashModal = useSelector(
    (state) => state?.configurationSlice?.trashModal
  );
  const [updateConfig, { isSuccess }] = store.useUpdateConfigurationMutation();
  const [deleteConfig, { isSuccess: deleteSuccess }] =
    store.useDeleteConfigurationMutation();
  const configTrashId = useSelector(
    (state) => state?.configurationSlice?.configTrashId
  );
  const [getConfig] = store.useLazyGetConfigShowQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (configTrashId) {
      deleteConfig(configTrashId);
      dispatch(setConfigTrashId(null));
    }
  }, [configTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate("/enduser");
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (config) {
      dispatch(
        setBreadCrumbs({
          id: config?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [config]);
  const schema = {
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        title: t("name"),
        default: config?.name,
      },
      url: {
        $id: "1",
        type: "string",
        title: t("url"),
        default: config?.url,
      },

      logo_id: {
        type: ["number", "string"],
        title: t("logo"),
        default: config?.logo_id,
        update: true,
      },
      favicon_id: {
        type: ["number", "string"],
        title: t("fav icon"),
        default: config?.favicon_id,
        update: true,
      },
      colors: {
        type: "object",
        properties: {
          primary: {
            type: "string",
            default: config?.colors?.primary,
          },
          secondary: {
            type: "string",
            default: config?.colors?.secondary,
          },
        },
      },
    },
    required: ["name"],
  };
  const fields = {
    colorpicker: ColorPickerWidget,
    img: ImageUpload,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    colors: {
      "ui:field": "colorpicker",
    },
    logo_id: {
      "ui:field": "img",
    },
    favicon_id: {
      "ui:field": "img",
    },
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [isSuccess]);
  useEffect(() => {}, [formData]);
  const onSubmitClick = () => {
    const attributes = {
      logo_id: formData?.logo_id,
      favicon_id: formData?.favicon_id,
      name: formData?.name,
      url: formData?.url,
      colors: {
        primary: formData?.colors?.primary,
        secondary: formData?.colors?.secondary,
      },
    };
    updateConfig({
      id: id,
      attributes: attributes,
    });
  };
  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?$/;
  const customValidate = (formData, errors) => {
    if (!urlRegex.test(formData?.url)) {
      errors?.url?.addError("not valid url");
    }
    return errors;
  };
  useEffect(() => {
    getConfig(id);
  }, [id]);
  useEffect(() => {}, [formData]);
  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setConfigTrashModal}
        reduxMode={true}
        text={t("config_delete_text")}
        title={t("config_delete_title")}
        deleteFunc={() => setConfigTrashId(id)}
      />
      {config && (
        <>
          <Typography variant="h4" sx={{ mt: 2, mb: 3 }}>
            {t("update_config")}
          </Typography>
          <Form
            schema={schema}
            formData={formData}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={onSubmitClick}
            validator={validator}
            uiSchema={uiSchema}
            /* widgets={widgets} */
            showErrorList={false}
            fields={fields}
            customValidate={customValidate}
            noHtml5Validate
            children={true}
          />
        </>
      )}
    </>
  );
};
export default ConfigShow;
