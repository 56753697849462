import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { Tooltip } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import theme from "../theme";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { v4 as uuidv4 } from "uuid";
import { setHeaderAppBar } from "../redux/slices/utilsSlice";
import { useDispatch, useSelector } from "react-redux";
// import { useSignoutMutation } from "../redux/store";
import { store } from "../redux/store";
import { actions } from "../redux/slices/authSlice";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";

import { SideMenu } from "./SideMenu";

const drawerWidth = 356;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  const [getConfig] = store.useLazyGetShowConfigurationQuery();
  const [anchorEl, setAnchorEl] = useState(null);
  const [signout, { isSuccess, data: logoutData }] = store.useSignoutMutation();
  const [getPreference] = store.useLazyGetPreferenceQuery();
  const userInfo = useSelector((state) => state?.userPreferenceSlice?.userInfo);
  const [showUser, setShowUser] = useState(false);
  const currentScriptPath = window.location.href;
  const path = new URL(currentScriptPath).pathname;
  const bearer = localStorage.getItem("user_token");
  const redyForSignout = useSelector((state) => state?.authSlice?.signout);
  const unauthorized = useSelector((state) => state?.authSlice?.unauthorized);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useAuth();
  const location = useLocation();
  let navigate = useNavigate();
  /*  const [open, setOpen] = useState(false); */
  const open = useSelector((state) => state?.utilsSlice?.headerAppBarOpen);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [, setSelectedMenu] = useState([]);
  useEffect(() => {
    if (redyForSignout) {
    }
  }, [redyForSignout, signout]);
  useEffect(() => {
    if (userInfo == null && bearer) {
      getPreference();
    }
  }, [userInfo, bearer]);
  useEffect(() => {
    if (bearer) {
      getConfig();
    }
  }, [bearer]);

  /*   useEffect(() => {
    if (unauthorized) {
      signout();
    }
  }, [unauthorized]); */

  /*   useEffect(() => {
    if (isSuccess) {
      
    }
  }, [isSuccess]); */
  /*   useEffect(() => {
    if (unauthorized) {
      navigate("/login");
    }
  }, [unauthorized]); */

  const [items] = useState({
    devicesUUID: uuidv4(),
    digitalTwinsUUID: uuidv4(),
    groupsUUID: uuidv4(),
    districtsUUID: uuidv4(),
    codecsUUID: uuidv4(),
    aegisUUID: uuidv4(),
    usersUUID: uuidv4(),
    securityProvider: uuidv4(),
    userGroups: uuidv4(),
    middleware: uuidv4(),
    networkAdapters: uuidv4(),
    applicationDesignUUID: uuidv4(),
    developmentUUID: uuidv4(),
    applicationDesign: uuidv4(),
    custumerEndpoints: uuidv4(),
    dashboard: uuidv4(),
    meters: uuidv4(),
    metersList: uuidv4(),
    createMeter: uuidv4(),
    groups: uuidv4(),
    gis: uuidv4(),
    digitalTwins: uuidv4(),
    importExport: uuidv4(),
    alarm: uuidv4(),
    network: uuidv4(),
    dex: uuidv4(),
    dexIndex: uuidv4(),
    createDex: uuidv4(),
    userManagment: uuidv4(),
    customerConfig: uuidv4(),
    campaign: uuidv4(),
    createCampaign: uuidv4(),
    campaignList: uuidv4(),
    position: uuidv4(),
    positionList: uuidv4(),
    createPostition: uuidv4(),
    createUser: uuidv4(),
    userList: uuidv4(),
    import: uuidv4(),
    importAdmin: uuidv4(),
    importReadings: uuidv4(),
    export: uuidv4(),
    exportRedings: uuidv4(),
    exportAdmin: uuidv4(),
    synteticData: uuidv4(),
    synteticCampaignList: uuidv4(),
    campaignGenerator: uuidv4(),
    districtMetering: uuidv4(),
    districtSetup: uuidv4(),
    districtImport: uuidv4(),
    districtExport: uuidv4(),
    manageLPWAN: uuidv4(),
    eventsAlarms: uuidv4(),
    messages: uuidv4(),
    livMessages: uuidv4(),
    groupCreate: uuidv4(),
    groupList: uuidv4(),
    group: uuidv4(),
    dataServices: uuidv4(),
    statistics: uuidv4(),
    statisticList: uuidv4(),
    createStatistic: uuidv4(),
    widgets: uuidv4(),
    widgetList: uuidv4(),
    widgetCreate: uuidv4(),
    gateways: uuidv4(),
    condominiums: uuidv4(),
    errors: uuidv4(),
    errorTypes: uuidv4(),
    errorTypesList: uuidv4(),
    errorTypesCreate: uuidv4(),
    errorAssociation: uuidv4(),
    errorAssociationList: uuidv4(),
    errorAssociationCreate: uuidv4(),
    alarms: uuidv4(),
    audit: uuidv4(),
    operations: uuidv4(),
    edge: uuidv4(),
    worklists: uuidv4(),
    wokrlistEditor: uuidv4(),
    worklistEditorCreate: uuidv4(),
    workList: uuidv4(),
    workListIndex: uuidv4(),
    workListCreate: uuidv4(),
    anomalies: uuidv4(),
    trilliant: uuidv4(),
    enduser_config: uuidv4(),
    balances: uuidv4(),
    allreadings: uuidv4(),
    condominiumList: uuidv4(),
    dexind: uuidv4(),
    dexmenu: uuidv4(),
    dexadd: uuidv4(),
    metrics: uuidv4(),
    lora: uuidv4(),
    jobs: uuidv4(),
    actions: uuidv4(),
  });

  const handleDrawerOpen = () => {
    dispatch(setHeaderAppBar());
  };

  const handleDrawerClose = () => {
    dispatch(setHeaderAppBar());
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const menuItems = useMemo(
    () => [
      {
        name: "groups",
        expanded: [items.digitalTwinsUUID, items.groupsUUID],
        selected: items.groupsUUID,
        children: [
          {
            name: "list_groups",
            expanded: [items.digitalTwinsUUID, items.groupsUUID],
            selected: null,
            to: "/groups",
          },
          {
            name: "create_groups",
            expanded: [items.digitalTwinsUUID, items.groupsUUID],
            selected: null,
            to: "/groups/create",
          },
        ],
      },
      {
        name: "devices",
        expanded: [items.digitalTwinsUUID, items.devicesUUID],
        selected: items.devicesUUID,
        children: [
          {
            name: "list_devices",
            expanded: [items.digitalTwinsUUID, items.devicesUUID],
            selected: null,
            to: "/devices",
          },
          {
            name: "create_devices",
            expanded: [items.digitalTwinsUUID, items.devicesUUID],
            selected: null,
            to: "/devices/create",
          },
        ],
      },
      {
        name: "districts",
        expanded: [items.digitalTwinsUUID, items.districtsUUID],
        selected: items.districtsUUID,
        children: [
          {
            name: "list_districts",
            expanded: [items.digitalTwinsUUID, items.districtsUUID],
            selected: null,
            to: "/districts",
          },
          {
            name: "create_districts",
            expanded: [items.digitalTwinsUUID, items.districtsUUID],
            selected: null,
            to: "/districts/create",
          },
        ],
      },
      {
        name: "middleware",
        expanded: [items.digitalTwinsUUID, items.codecsUUID],
        selected: items.codecsUUID,
        children: [
          {
            name: "list_middleware",
            expanded: [items.digitalTwinsUUID, items.codecsUUID],
            selected: null,
            to: "/codecs",
          },
          {
            name: "create_codecs",
            expanded: [items.digitalTwinsUUID, items.codecsUUID],
            selected: null,
            to: "/codecs/create",
          },
        ],
      },
    ],
    [items]
  );

  const findLocation = useCallback((list, location) => {
    return list.find((item) => {
      if (item.children) {
        const result = findLocation(item.children, location);
        if (result) {
          setExpanded(result.expanded);
          setSelectedMenu(result);
          setSelected(result.selected);
          return result;
        }
      }
      return item.to === location.pathname;
    });
  });

  useEffect(() => {
    findLocation(menuItems, location);
  }, [location, menuItems, findLocation]);

  return (
    <Box>
      <CssBaseline />
      <AppBar
        sx={{ width: 300 }}
        position="absolute"
        open={open}
        color="transparent"
        elevation={0}
      >
        <Toolbar>
          <Box display={"flex"} sx={{ flexGrow: 1 }} alignItems="center"></Box>

          {auth.user() && (
            <Box display={"flex"}>
              <Box sx={{ mb: 2 }}>
                {/*  {showUser && auth.user().display_name} */}
                <Tooltip title={auth.user().display_name}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    onMouseEnter={() => setShowUser(true)}
                    onMouseLeave={() => setShowUser(false)}
                  >
                    <AccountCircle sx={{ fill: theme.palette.header }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      signout();
                    }}
                  >
                    {t("logout")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/me");
                      handleClose();
                    }}
                  >
                    {t("myAccount")}
                  </MenuItem>
                </Menu>
              </Box>
              <IwdAuthWrapper
                children={
                  <Box>
                    <Tooltip title="Swarm settings">
                      <IconButton
                        onClick={() => navigate("/settings")}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                      >
                        <SettingsSuggestIcon
                          sx={{ fill: theme.palette.header }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                section={"settings"}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <SideMenu
        expanded={expanded}
        selected={selected}
        handleToggle={handleToggle}
        handleSelect={handleSelect}
        open={open}
        handleDrawerClose={handleDrawerClose}
        items={items}
      />
    </Box>
  );
};

export default Header;
