import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
const WidgetsAutocomplete = (props) => {
  const updating = useSelector((state) => state?.widgetSlice?.updating);
  const [value, setValue] = React.useState(null);

  const data = props?.schema?.data;
  React.useEffect(() => {
    if (value) {
      props.onChange(value.value);
    }
  }, [value]);
  React.useEffect(() => {
    if (props?.formData) {
      const filteredData = data?.filter(
        (item) => item.value === props?.formData
      );

      setValue(filteredData[0]);
    }
  }, [props?.formData]);
  React.useEffect(() => {}, [value]);
  return (
    <>
      {updating && value && (
        <Autocomplete
          disablePortal
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          id="combo-box-demo"
          options={data}
          renderInput={(params) => (
            <TextField
              error={props?.rawErrors?.length > 0}
              {...params}
              label={props?.schema?.title}
            />
          )}
        />
      )}
      {!updating && (
        <Autocomplete
          disablePortal
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          id="combo-box-demo"
          options={data}
          renderInput={(params) => (
            <TextField
              error={props?.rawErrors?.length > 0}
              {...params}
              label={props?.schema?.title}
            />
          )}
        />
      )}
    </>
  );
};
export default WidgetsAutocomplete;
