import { createSlice } from "@reduxjs/toolkit";
import { aegisApi } from "./aegisApi";
import { store } from "../store";
const initialState = {
  roles: null,
};

const aegisSlice = createSlice({
  name: "aegisSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      store.aegisEndpoints.getAllroles.matchFulfilled,
      (state, action) => {
        state.roles = action.payload.data?.map((item) => item.attributes.key);
      }
    );
  },
});

export default aegisSlice.reducer;
