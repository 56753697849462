import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  errorTypes: null,
  errorTypesCount: null,
  errorType: null,
  trashId: null,
};

const errorTypeSlice = createSlice({
  name: "errorTypeSlice",
  initialState,
  reducers: {
    setErrorTypeTrashId(state, action) {
      state.trashId = action.payload;
    },
    showClean(state) {
      state.errorType = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.errorTypesEndpoints.getErrorTypes.matchFulfilled,
      (state, action) => {
        state.errorTypes = action.payload.data.attributes.data;
        state.errorTypesCount = action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.errorTypesEndpoints.getErrorTypeShow.matchFulfilled,
      (state, action) => {
        state.errorType = action.payload.data.attributes;
      }
    );
  },
});
export const errorTypeActions = errorTypeSlice.actions;
export default errorTypeSlice.reducer;
