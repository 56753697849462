import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useEffect, useState } from "react";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { store } from "../../../redux/store";

import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash.debounce";

const IwdAutocomplete = (props) => {
  const [value, setValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);

  const defPropValue = props?.schema?.default;

  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();

  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  useEffect(() => {}, [props]);
  useEffect(() => {
    if (value?.value && !props?.schema?.multiple) {
      props.onChange(value.value);
    }
    if (!value && !props?.schema?.multiple) {
      props.onChange("");
    }
  }, [value]);

  //1
  /*   useEffect(() => {
    if (value?.length >= 0) {
      props.onChange(value?.map((item) => item.value));
    }
  }, [value]); */

  //2
  /*  useEffect(() => {
    if (
      defPropValue &&
      defPropValue !== null &&
      defPropValue !== "" &&
      firstRender &&
      (!resp || !resp.data)
    ) {
      setLoading(true);
      Promise.all([getByName([props.schema.url, {}])]).then(() => {
        setSearchValue(null);
      });
    }
  }, [defPropValue]); */

  useEffect(() => {}, [value]);
  //3
  useEffect(() => {
    if (
      defPropValue &&
      resp &&
      resp.data &&
      firstRender &&
      !props?.schema?.multiple
    ) {
      const val = resp?.data?.data
        ?.filter((item) => defPropValue?.[0].includes(item.id))
        ?.map((item) => ({
          label: `${item?.attributes?.name} (${item?.attributes?.version})`,
          value: item?.attributes?.id,
        }));
      if (val?.length > 0) {
        setValue(val[0]);
      } else {
        setValue(null);
      }

      setLoading(false);
      setFirstRender(false);
    }
  }, [resp]);
  useEffect(() => {
    if (
      defPropValue &&
      resp &&
      resp.data &&
      firstRender &&
      props?.schema?.multiple
    ) {
      setValue(
        resp?.data?.data
          ?.filter((item) => defPropValue?.[0].includes(item.attributes.name))
          ?.map((item) => ({
            label: item?.attributes?.name,
            value: item?.attributes?.name,
          }))
      );

      setLoading(false);
      setFirstRender(false);
    }
  }, [resp]);

  const openFunc = () => {
    setOpen(true);

    setLoading(true);
  };
  useEffect(() => {}, [value, props?.formData, resp]);

  useEffect(() => {}, [open]);

  useEffect(() => {
    if (props?.schema?.default) {
      setValue(props?.schema?.default);
    }
  }, [props?.schema?.default]);

  useEffect(() => {
    if (open) {
      Promise.all([getByName([props.schema.url, [props.schema?.params]])]).then(
        () => {
          setLoading(false);
          setFirstRender(false);
          setSearchValue(null);
        }
      );
    }
  }, [open]);
  let data = resp && props?.schema?.mapFn(resp);
  useEffect(() => {
    setOptions(data);
  }, [resp]);
  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      Promise.all([
        getByName([
          props.schema.searchUrl,
          { [props.schema.searchStr]: searchValue },
        ]),
      ]).then(() => {
        setLoading(false);
        setSearchValue(null);
      });
    }
  }, [searchValue]);
  useEffect(() => {}, [props?.schema?.filteredWidgets]);

  return (
    <>
      <Autocomplete
        multiple={props?.schema?.multiple}
        fullWidth
        options={options ?? []}
        value={value}
        open={open}
        onOpen={openFunc}
        getOptionDisabled={(option) =>
          props?.schema?.filteredWidgets &&
          props?.schema?.filteredWidgets?.includes(option.value)
        }
        onClose={() => setOpen(false)}
        popupIcon={""}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              onChange={debValue}
              {...params}
              value={value}
              error={props?.rawErrors?.length > 0}
              required={props.required}
              label={props.schema.title}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CircularProgress color="inherit" size={20} />}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </>
  );
};

export default React.memo(IwdAutocomplete);
