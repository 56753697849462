import { prepareParams } from "../../utils";
import { prepareBody, prepareBodyWithoutId } from "../../utils/api_params";
export const meterApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getConsumptions: build.query({
        query: (params) => {
          return `v1/plugin/consumptions?${prepareParams(params)}`;
        },
      }),
      getMeterShow: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/0/instance/${id}`;
        },
      }),
      getMeterAttributes: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/${id}/attribute`;
        },
      }),
      postCommands: build.mutation({
        query: ({
          digitalTwinId,
          digitalTwinInstance,
          commandName,
          attributes,
        }) => ({
          method: "POST",
          url: `v1/altior/digital_twin/${digitalTwinId}/instance/${digitalTwinInstance}/command/${commandName}`,
          body: JSON.stringify(
            prepareBodyWithoutId(attributes, "device_commands")
          ),
        }),
      }),
      updateMeter: build.mutation({
        query: ({ attributes, twinId, instanceId }) => ({
          url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, instanceId, "devices")),
        }),
      }),
      getDigitalTwin: build.query({
        query: (id) => {
          return `v1/altior/digital_twin`;
        },
      }),
      deleteMeter: build.mutation({
        query: (id) => ({
          url: `v1/plugin/condominium_device/${id}`,
          method: "DELETE",
        }),
      }),

      importMeterHcaHistory: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/altior/device/${id}/import_historical`,
          method: "POST",
          body: JSON.stringify(
            prepareBody(attributes, id, "import_historical")
          ),
        }),
      }),
      assocMeterToUser: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/altior/device/${id}/user`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
      disasocMeterFromUser: build.mutation({
        query: ({ deviceId, userId }) => ({
          url: `v1/altior/device/${deviceId}/user/${userId}`,
          method: "DELETE",
        }),
      }),
      getListOfAssociatedUser: build.query({
        query: ({ id, params }) => {
          return `v1/altior/device/${id}/user?${prepareParams(params)}`;
        },
      }),
      getAllReadings: build.query({
        query: (params) => {
          return `v1/message/all?${prepareParams(params)}`;
        },
        transformResponse: (response) => {
          const data = response?.data?.map((value) => {
            return {
              id: value.id,
              messageDate: value.attributes.message_date,
              deviceId: value.attributes.device_id,
              messageType: value.attributes.message_type,
              parentDeviceId: value.attributes.parent_device_id,
              deviceIdentifier: value?.attributes?.values?.device_identifier,
              valueType: value?.attributes?.values?.value_type,
              pod: value?.attributes?.extra?.pod,
              values: value?.attributes?.values,
              metrological_serial:
                value?.attributes?.extra?.metrological_serial,
              value:
                value?.attributes?.values?.values?.value?.last_reading?.value,
              unit:
                value?.attributes?.values?.values?.value?.last_reading?.unit ??
                value?.attributes?.values?.values?.last_reading?.unit ??
                "",
              strValue:
                value?.attributes?.values?.values?.value?.last_reading?.value ??
                value?.attributes?.values?.values?.last_reading?.value ??
                "--",
              // inAlarm: value?.attributes?.values?.value?.alarms.length > 0,
              alarms:
                value?.attributes?.values?.values?.value?.alarms ??
                value?.attributes?.values?.values?.alarms,
            };
          });
          return { data: data, meta: response.meta };
        },

        extraOptions: { maxRetries: 1 },
      }),
    }),
  });

/* export const {
  useLazyGetConsumptionsQuery,
  useLazyGetMeterAttributesQuery,
  useUpdateMeterMutation,
  useLazyGetDigitalTwinQuery,
} = meterApi;
 */
