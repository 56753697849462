import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function GraficCard({
  value = false,
  value2 = false,
  color1 = false,
  color2 = false,
  icon,
  title,
  date,
  coldValue = false,
  heatValue = false,
  width = false,
}) {
  return (
    <Card sx={{ width: width ? width : 150 }}>
      <CardContent>
        <Typography align="center" variant="subtitle2" component="div">
          {date}
        </Typography>
        {value && (
          <Typography
            sx={{ color: color1 }}
            align="center"
            variant="subtitle2"
            gutterBottom
          >
            {value}
          </Typography>
        )}
        {value2 && (
          <Typography
            sx={{ color: color2 }}
            align="center"
            variant="subtitle2"
            gutterBottom
          >
            {value2}
          </Typography>
        )}
        {heatValue && (
          <Typography align="center" variant="subtitle2" gutterBottom>
            {heatValue}
          </Typography>
        )}
        {coldValue && (
          <Typography align="center" variant="subtitle2" gutterBottom>
            {coldValue}
          </Typography>
        )}
        <Typography variant="h5" component="div" align="center" sx={{ mt: 2 }}>
          {icon}
        </Typography>
      </CardContent>
      {/*  <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
