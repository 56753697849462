import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  auditIndex: null,
  auditCount: null,
  haveRecords: "waiting",
};

const auditSlice = createSlice({
  name: "auditSlice",
  initialState,
  reducers: {
    setAuditRecords(state, action) {
      state.haveRecords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.auditEndpoints.getAuditIndex.matchFulfilled,
      (state, action) => {
        state.auditIndex = action.payload.data;
        /*    state.auditCount = action.payload.meta.count; */
        if (action.payload.data?.length > 0) {
          state.haveRecords = "havedata";
        } else {
          state.haveRecords = "nodata";
        }
      }
    );
  },
});
export const { setAuditRecords } = auditSlice.actions;
export default auditSlice.reducer;
