import { store } from "../../redux/store";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MeterDatePicker from "../form/formComponents/MeterDatePicker";
import UserWidget from "../users/UserWidget";
import InfiniteTable from "../../pages/InfiniteTable";
import IwdDataGrid from "../../pages/IwdDataGrid";
import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/mui";
import theme from "../../theme";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { setAuditRecords } from "../../redux/slices/auditSlice";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const AuditIndex = () => {
  const dispatch = useDispatch();
  const audit = useSelector((state) => state?.auditSlice?.auditIndex);
  const users = useSelector((state) => state?.userSlice?.users);
  const haveRecords = useSelector((state) => state?.auditSlice?.haveRecords);
  const [sortMode, setSortMode] = useState("desc");
  const [formData, setFormData] = useState({});
  const auditCount = useSelector((state) => state?.auditSlice?.auditCount);
  const [getAuditIndex, { isFetching }] = store.useLazyGetAuditIndexQuery();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const { t } = useTranslation();
  const typeEnum = [{ const: "database" }, { const: "http_request" }];
  const actionEnum = [
    { const: "created" },
    { const: "updated" },
    { const: "deleted" },
  ];
  useEffect(() => {
    getAuditIndex({
      audit_type: formData?.audit_type && formData?.audit_type,
      sort: "recorded_at",
      order: sortMode,
      page: page,
      per_page: perPage,
      user_id: formData?.user_id && formData?.user_id,
      form: formData?.insertion_date?.from && formData?.insertion_date?.from,
      to: formData?.insertion_date?.to && formData?.insertion_date?.to,
      action: formData?.action && formData?.action,
    });
  }, [page, sortMode]);
  const fields = {
    datepicker: MeterDatePicker,
    user: UserWidget,
  };
  const onSortClick = () => {
    setSortMode(sortMode == "desc" ? "asc" : "desc");
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("audit"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("search"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    user_id: {
      "ui:field": "user",
    },
    insertion_date: {
      "ui:field": "datepicker",
    },
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "action",
      headerName: t("action"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "entity_id",
      headerName: t("entity_id"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "user_id",
      headerName: t("user"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "http_method",
      headerName: t("http_method"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    /* {
      field: "http_params",
      headerName: t("http_params"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    }, */
    {
      field: "http_remote_ip",
      headerName: t("http_remote_ip"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "http_request_path",
      headerName: t("http_request_path"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "recorded_at",
      headerName: t("recorded_at"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
  ];
  const rows = audit?.map((item) => {
    const user = users?.filter(
      (user) => user?.id == item?.attributes?.user_id
    )?.[0]?.attributes?.display_name;

    return {
      id: item?.id,
      action: item?.attributes?.action ?? "--",
      entity_id: item?.attributes?.entity_id ?? "--",
      http_method: item?.attributes?.http_method ?? "--",
      http_params: item?.attributes?.http_params ?? "--",
      http_remote_ip: item?.attributes?.http_remote_ip ?? "--",
      http_request_path: item?.attributes?.http_request_path ?? "--",
      order: sortMode,
      recorded_at:
        t("dt", { val: new Date(item?.attributes?.recorded_at) }) ?? "--",
      user_id: user ?? "--",
    };
  });
  useEffect(() => {}, [formData]);
  const schema = {
    title: "New user",
    type: "object",

    properties: {
      audit_type: {
        $id: "12",
        type: "string",
        title: t("type"),
        oneOf: [...typeEnum],
      },

      action: {
        $id: "4",
        type: "string",
        title: t("action"),
        oneOf: [...actionEnum],
      },
      user_id: {
        $id: "2",
        type: "string",
        title: t("user"),
        /*   default: paramsList.zip_code, */
      },
      insertion_date: {
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
            /*       default: paramsList.from ?? from.toISOString(), */
          },
          to: {
            type: "string",
            /*  default: paramsList.to ?? now.toISOString(), */
          },
        },
      },
    },
  };
  const onSubmitClick = (val) => {
    setPage(1);
    getAuditIndex({
      page: page,
      per_page: perPage,
      audit_type: formData?.audit_type,
      from: val?.insertion_date?.from,
      to: val?.insertion_date?.to,
      action: val?.action,
      user_id: val?.user_id,
      sort: "recorded_at",
      order: "desc",
    });
  };
  return (
    <>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        showErrorList={false}
        onChange={({ formData: newFormData }) => setFormData(newFormData)}
        onSubmit={(values) => {
          onSubmitClick(values.formData);
        }}
        validator={validator}
      />
      <IwdDataGrid
        rows={rows ?? []}
        columns={columns}
        perPage={perPage}
        setPage={setPage}
        page={page}
        infiniteMode={true}
        sort={false}
        height={622}
        paginationMode={"server"}
        tableName={"audit"}
        records={haveRecords}
        recordsFn={setAuditRecords}
      />
      {/* <InfiniteTable
        styles={{ height: 622, mt: 2 }}
        rows={rows ?? []}
        columns={columns}
        page={page}
        perPage={perPage}
        setPage={setPage}
        isSuccess={isFetching}
        paginationMode={"server"}
     
        records={haveRecords}
        recordsFn={setAuditRecords}
      /> */}
    </>
  );
};

export default AuditIndex;
