import {
  PieChart,
  Tooltip as PieTooltip,
  Cell,
  Label,
  LabelList,
} from "recharts";
import { Pie } from "recharts";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import PopoverDashboardCardError from "../Popover";
import { deleteCreatedWidget } from "../../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectColor,
  selectTextColor,
  selectLabel,
} from "./utils/widgetConfigurations";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, useTheme, Paper, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import PopoverCardTitle from "../PopoverCardTitle";
import { setDragBlcok } from "../../../redux/slices/dashboardSlice";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import GroupIcon from "@mui/icons-material/Group";

const COLORS = ["#408fda", "#73b0ed", "#c1deff", "#aeefc8"];

const CustomPieLabel = ({ viewBox, value }) => {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${value}%`}
    </text>
  );
};

const data01 = [
  {
    name: "Group A",
    value: 400,
  },
  {
    name: "Group B",
    value: 300,
  },
  {
    name: "Group C",
    value: 300,
  },
  {
    name: "Group D",
    value: 200,
  },
  {
    name: "Group E",
    value: 278,
  },
  {
    name: "Group F",
    value: 189,
  },
];

const data02 = [{ name: "A1", value: 100 }];

const PieCharWidget = ({
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase,
}) => {
  const previewMode = useSelector((state) => state?.widgetsSlice?.previewMode);
  const [linkUnderline, setLinkUnderline] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data =
    !previewMode && Array.isArray(widget?.last_update?.data)
      ? widget?.last_update?.data?.map((item) => ({
          name: item.name,
          value: +item.value,
        }))
      : {
          name: widget?.last_update?.data,
          value: widget?.last_update?.data,
        };

  useEffect(() => {}, [data]);

  const onTrashClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };

  const onDelete = (id) => {
    dispatch(deleteCreatedWidget(id));
  };

  return (
    <Paper
      elevation={5}
      sx={{
        maxWidth: 400,
        height: 290,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ m: 1 }}>
        <PopoverCardTitle
          title={widget?.name}
          description={widget?.description}
          edit={edit}
        />
        <Divider />
        {edit && (
          <IconButton sx={{ float: "right" }}>
            <DeleteIcon
              onMouseEnter={() => dispatch(setDragBlcok(true))}
              onMouseLeave={() => dispatch(setDragBlcok(false))}
              onClick={
                creationPhase
                  ? () => onDelete(widget?.id)
                  : () => onTrashClick(widget?.id)
              }
              sx={{
                float: "right",
                fill: theme.palette.error.main,
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <>
          {widget.link && data.length > 0 && (
            <a
              onMouseEnter={() => setLinkUnderline(true)}
              onMouseLeave={() => setLinkUnderline(false)}
              style={{
                textDecoration: linkUnderline ? "" : "none",
              }}
              href={widget?.link}
              target="_blank"
            >
              <PieChart width={730} height={140}>
                <PieTooltip
                  formatter={(value, name) => [`${name} ${value}%`]}
                  contentStyle={{ zIndex: 1000 }}
                  offset={20}
                />
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  paddingAngle={data.length > 1 && 20}
                  outerRadius={data.length > 1 && 40}
                />
              </PieChart>
            </a>
          )}
          {!previewMode &&
            (!widget.link && data.length > 0 ? (
              <PieChart width={200} height={150}>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={data.length > 1 && 40}
                  paddingAngle={data.length > 1 && 35}
                >
                  {data?.map((entry, index) => (
                    <Cell
                      fill={COLORS[index % COLORS.length]}
                      key={`cell-${index}`}
                    ></Cell>
                  ))}

                  <LabelList
                    dataKey="value"
                    position="outside"
                    formatter={(v) => `${v} %`}
                    style={{
                      fill: "#000",
                      fontSize: "100%",
                      fontWeight: "900",
                      textShadow: "none",
                      boxShadow: "none",
                      letterSpacing: 2,
                      fontFamily: "Roboto",
                    }}
                  />
                </Pie>

                <PieTooltip
                  formatter={(value, name) => [`${name} ${value}%`]}
                />
              </PieChart>
            ) : (
              <Typography variant="h3">NA</Typography>
            ))}
          {previewMode && (
            <PieChart width={730} height={150}>
              <Pie
                data={data01}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                paddingAngle={20}
                outerRadius={40}
              >
                {data01?.map((entry, index) => (
                  <Cell
                    fill={COLORS[index % COLORS.length]}
                    key={`cell-${index}`}
                  />
                ))}
              </Pie>

              <PieTooltip
                formatter={(value, name) => [`${name} ${value}%`]}
                wrapperStyle={{ zIndex: 1000 }}
                contentStyle={{ zIndex: 1000 }}
                labelStyle={{ zIndex: 1000 }}
              />
            </PieChart>
          )}
        </>
        <Box sx={{ mt: 5 }}>
          {widget?.last_error?.date && !previewMode && (
            <PopoverDashboardCardError error={widget?.last_error} />
          )}
        </Box>
      </Box>
      <Box sx={{ m: 1 }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={1}
          direction="row"
          spacing={1}
          xs={12}
        >
          {widget?.link ? (
            <>
              <Grid item xs={2}>
                {widget?.tags?.map((item) => (
                  <Chip
                    style={{
                      /*   color: theme.palette.chipLabel, */
                      background: theme.palette.chip,
                    }}
                    label={selectLabel(widget?.tags?.[0], theme)}
                  />
                ))}
              </Grid>
              <Grid item xs={2}>
                {/* {widget?.link && (
                  <IconButton href={widget?.link} target="_blank">
                    <BookmarksIcon sx={{ ml: 1 }} />
                  </IconButton>
                )} */}
              </Grid>
            </>
          ) : (
            <Grid item xs={4}>
              {widget?.tags?.map((item) => (
                <Chip
                  style={{
                    /*   color: theme.palette.chipLabel, */
                    background: theme.palette.chip,
                  }}
                  label={selectLabel(widget?.tags?.[0], theme)}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={8}>
            <Tooltip title={t("last_update")}>
              <Typography
                textAlign="end"
                variant="body2"
                sx={{ fontSize: "0.75rem" }}
              >
                {t("dt", { val: new Date(widget?.last_update?.date) })}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PieCharWidget;
