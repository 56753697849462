import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BalanceYearDatePicker from "./BalanceYearDatePicker";
/* import BalanceWeekDatePicker from "./BalanceWeekDatePicker"; */
import BalanceMonthDatePicker from "./BalanceMonthDatePicker";
import BalanceDayDatePicker from "./BalanceDayDatePicker";
import MonthSelect from "./MonthSelect";
import "dayjs/locale/en";
import "dayjs/locale/it";

const BalaceDatePicker = (props) => {
  const modeSelectore = () => {
    if (props.schema.mode === "day") {
      return <BalanceDayDatePicker props={props} />;
    }
    if (props.schema.mode === "month") {
      return <BalanceMonthDatePicker props={props} />;
    }
    /*  if (props.mode === "week") {
      return <BalanceWeekDatePicker />;
    } */
  };

  return <>{modeSelectore()}</>;
};

export default BalaceDatePicker;
