import {
  prepareBody,
  prepareParams,
  prepareBodyWithoutId,
} from "../../utils/api_params";

export const configurationApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getShowConfiguration: build.query({
        query: () => {
          return `v1/swarm_configuration/${window.CONFIG_ID}`;
        },
      }),
      setConfiguration: build.mutation({
        query: (attributes) => ({
          method: "PUT",
          url: `v1/swarm_configuration/${window.CONFIG_ID}`,
          body: JSON.stringify(
            prepareBody(
              attributes,
              window.CONFIG_ID.toString(),
              "swarm_configuration"
            )
          ),
        }),
      }),
      getConfigIndex: build.query({
        query: (params) => ({
          url: `v1/end_user_portal?${prepareParams(params)}`,
        }),
      }),
      getConfigShow: build.query({
        query: (id) => ({
          url: `v1/end_user_portal/${id}`,
        }),
      }),
      createConfiguration: build.mutation({
        query: (attributes) => ({
          url: "v1/end_user_portal",
          method: "POST",
          body: JSON.stringify(
            prepareBodyWithoutId(attributes, "end_user_portal")
          ),
        }),
      }),
      updateConfiguration: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/end_user_portal/${id}`,
          method: "PUT",
          body: JSON.stringify(prepareBody(attributes, id, "end_user_portal")),
        }),
      }),
      deleteConfiguration: build.mutation({
        query: (id) => ({
          url: `v1/end_user_portal/${id}`,
          method: "delete",
        }),
      }),
    }),
  });
