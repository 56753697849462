import { queries } from "@testing-library/react";
import {
  prepareBodyWithoutId,
  prepareBody,
  prepareParams,
} from "../../utils/api_params";
import { method } from "lodash";

const type = "dex";
export const dexApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getDexShow: build.query({
        query: (id) => {
          return `v1/dex/${id}`;
        },
      }),
      deleteDex: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/dex/${id}`,
        }),
      }),
      postDex: build.mutation({
        query: (attributes) => ({
          url: "v1/dex/execute",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, type)),
        }),
      }),
      executeSavedDex: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/dex/${id}/execute`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, type)),
        }),
      }),
      getDexIndex: build.query({
        query: (params) => ({
          url: `v1/dex?${prepareParams(params)}`,
        }),
      }),

      createDex: build.mutation({
        query: (attributes) => ({
          url: `v1/dex`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "dex")),
        }),
      }),
      exportDex: build.mutation({
        query: (attributes) => ({
          url: "v1/dex/execute/export",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "dex")),
        }),
      }),
      updateDex: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/dex/${id}`,
          method: "PUT",
          body: JSON.stringify(prepareBody(attributes, id, type)),
        }),
      }),
    }),
  });

/* export const { usePostDexMutation } = dexApi; */
