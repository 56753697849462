import { StyledDataGrid } from "./StyledDataGrid";
import { Box } from "@mui/system";
import InfiniteTable from "./InfiniteTable";
import { useState, useEffect, useRef } from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { store } from "../redux/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { addOrUpdateTable } from "../redux/slices/userPreferenceSlice";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import ModalDialog from "../components/meters/ModalDialog";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";
import theme from "../theme";
import { includes } from "lodash";

const IwdDataGrid = ({
  columns,
  rows,
  page,
  setPage,
  perPage,
  count,
  height,
  sorts,
  setSorts,
  styles,
  paginationMode = "server",
  sort = true,
  infiniteMode = false,
  records,
  recordsFn,
  customSortFn = false,
  tableName,
  getRowHeight = false,
}) => {
  const [lastField, setLastField] = useState(null);
  const [setPreference, { isSuccess: preferenceSuccess }] =
    store.useSetPreferenceMutation();
  const tables = useSelector((state) => state?.userPreferenceSlice?.tables);

  const selectedTable = tables[tableName];
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const preference = useSelector(
    (state) => state?.userPreferenceSlice?.preference
  );
  const preferenceUpdating = useSelector(
    (state) => state?.userPreferenceSlice?.updating
  );

  const hideFields = columns
    ?.filter((item) => item?.hide)
    ?.map((item) => item?.field);
  const hideHeaders = columns
    ?.filter((item) => item?.hide)
    ?.map((item) => item?.headerName);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return selectedTable?.length > 0 && selectedTable
      ? selectedTable.reduce((acc, column) => {
          acc[column.field] = column.isVisible;
          return acc;
        }, {})
      : columns.reduce((acc, column) => {
          acc[column.field] = true;
          return acc;
        }, {});
  });

  const [tempColumnVisibility, setTempColumnVisibility] = useState(() => ({
    ...columnVisibility,
  }));
  const [columnOrder, setColumnOrder] = useState(
    selectedTable?.length > 0 && selectedTable
      ? selectedTable?.map((col) => col.field)
      : columns.map((col) => col.field)
  );

  const [draggedIndex, setDraggedIndex] = useState(null); // To track the dragged item index
  const [hoveredIndex, setHoveredIndex] = useState(null); // To track the hovered item index

  const listContainerRef = useRef(null); // Reference for the container

  const toggleColumnVisibility = (field) => {
    setTempColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };

  const visibilityList = columnOrder
    .map((field) => {
      const column = columns.find((col) => col.field === field);
      const isVisible = tempColumnVisibility[field] !== false;
      return {
        headerName: column?.headerName,
        field,
        isVisible,
      };
    })
    .filter(
      (item) =>
        !hideFields.includes(item.field) &&
        !hideHeaders.includes(item.headerName)
    );

  const handleSortModelChange = (newModel) => {
    if (customSortFn) {
      customSortFn(newModel);
    } else {
      if (newModel.length > 0) {
        const newSort = newModel[0];
        setSorts((prevSorts) => {
          const existingSortIndex = prevSorts?.findIndex(
            (sort) => sort.field === newSort.field
          );
          if (existingSortIndex !== -1) {
            const updatedSorts = [...prevSorts];
            updatedSorts[existingSortIndex] = newSort;
            return updatedSorts;
          } else {
            return [...prevSorts, newSort];
          }
        });
      } else if (lastField) {
        setSorts((prevSorts) =>
          prevSorts?.filter((sort) => sort.field !== lastField)
        );
        setLastField(null);
      }
    }
  };

  useEffect(() => {
    console.log("colix", hideHeaders, hideFields);
  }, [hideFields]);

  useEffect(() => {
    if (preferenceUpdating) {
      setPreference(preference);
    }
  }, [preferenceUpdating]);

  const handleHeaderClick = (field) => {
    const isSorted = sorts?.find((sort) => sort.field === field);
    if (!isSorted) {
      handleSortModelChange([{ field, sort: "asc" }]);
    } else if (isSorted.sort === "asc") {
      handleSortModelChange([{ field, sort: "desc" }]);
    } else {
      handleSortModelChange([]);
    }
  };

  const iconHeaderColumn = {
    field: "previewHeader",
    headerName: "",
    width: 60,
    flex: 0,
    renderHeader: () => (
      <Tooltip title={t("column_settings")}>
        <Box display={"flex"} justifyContent={"flex-end"} width="100%">
          <Divider orientation="vertical" flexItem />
          <IconButton
            onClick={() => {
              setModal(true);
            }}
            sx={{ ml: 1 }}
          >
            <PreviewIcon sx={{ cursor: "pointer" }} />
          </IconButton>
        </Box>
      </Tooltip>
    ),
    sortable: false,
    disableColumnMenu: true,
  };

  const mappedColumns = !customSortFn
    ? columnOrder
        .map((field) => columns.find((col) => col.field === field))
        .map((column) => ({
          ...column,
          renderHeader: (params) => (
            <span
              className="MuiDataGrid-columnHeaderTitle"
              onClick={() =>
                sort && !column.nosort ? handleHeaderClick(params.field) : null
              }
              onMouseEnter={() => {
                setLastField(params?.field);
              }}
            >
              {params.colDef.headerName}
              {!column?.nosort &&
                sorts?.find((sort) => sort.field === params.field) && (
                  <span>
                    {sorts?.find((sort) => sort.field === params.field).sort ===
                    "asc" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )}
                  </span>
                )}
            </span>
          ),
          sortable: false,
        }))
    : columnOrder.map((field) => columns.find((col) => col.field === field));

  const visibleColumns = mappedColumns.filter(
    (column) => tempColumnVisibility[column.field] !== false
  );

  const columnsWithIconHeader = [...visibleColumns, iconHeaderColumn];

  const hasAtLeastOneTrue = Object.entries(tempColumnVisibility).some(
    ([key, value]) => key.toLowerCase() !== "id" && value === true
  );

  const handlePreviewHeaderClick = () => {
    console.log("Preview header clicked");
  };

  const handleSaveChanges = () => {
    setColumnVisibility(tempColumnVisibility);
    setModal(false);
    const tableInfo = visibilityList?.map((item) => ({
      field: item?.field,
      isVisible: item?.isVisible,
    }));
    dispatch(addOrUpdateTable({ tableName, tableInfo }));
  };

  const handleCancelChanges = () => {
    setTempColumnVisibility(columnVisibility);
    setModal(false);
  };

  const handleDragStart = (event, index) => {
    setDraggedIndex(index);
    event.dataTransfer.setData("text/plain", index);
    event.currentTarget.style.opacity = "0.5"; // Slightly transparent during drag
  };

  const handleDragEnd = (event) => {
    setDraggedIndex(null);
    setHoveredIndex(null);
    event.currentTarget.style.opacity = "1"; // Fully opaque after drag
  };

  const handleDrop = (event, index) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData("text/plain");
    const newItems = [...visibilityList];
    const [movedItem] = newItems.splice(draggedIndex, 1);
    newItems.splice(index, 0, movedItem);
    setColumnOrder(newItems.map((item) => item.field));
    setTempColumnVisibility((prevVisibility) => {
      return {
        ...prevVisibility,
        ...newItems.reduce((acc, item) => {
          acc[item.field] = item.isVisible;
          return acc;
        }, {}),
      };
    });
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    setHoveredIndex(index); // Update hovered index for visual effect

    // Scroll the container up or down depending on the mouse position
    const container = listContainerRef.current;
    const { clientY } = event;
    const { top, bottom } = container.getBoundingClientRect();
    const scrollStep = 10; // Adjust this value as needed

    if (clientY - top < 50) {
      container.scrollTop -= scrollStep;
    } else if (bottom - clientY < 50) {
      container.scrollTop += scrollStep;
    }
  };

  return (
    <Box sx={{ height: height }}>
      <ModalDialog
        open={modal}
        cancell={false}
        title={t("column_settings")}
        close={handleCancelChanges}
      >
        <Box
          ref={listContainerRef} // Attach ref to the container
          sx={{
            maxHeight: "700px", // Adjust this height as needed
            overflowY: "auto",
            mb: 2,
          }}
        >
          {visibilityList.map((item, index) => (
            <Box
              key={item.field}
              display="flex"
              alignItems="center"
              mb={1}
              p={1}
              sx={{
                border: "1px solid gray",
                borderRadius: "4px",
                backgroundColor: "#f0f0f0",
                cursor: "move",
                transition:
                  "background-color 0.3s, transform 0.3s, margin 0.3s",
                marginTop: draggedIndex === index ? "25px" : "5px",
                marginBottom: hoveredIndex === index ? "25px" : "0",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
                "&.dragging": {
                  transform: "scale(1.02)",
                  backgroundColor: "#d0d0d0",
                },
              }}
              draggable
              onDragStart={(event) => handleDragStart(event, index)}
              onDragEnd={handleDragEnd}
              onDrop={(event) => handleDrop(event, index)}
              onDragOver={(event) => handleDragOver(event, index)}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {item.headerName}
              </Typography>
              <IconButton onClick={() => toggleColumnVisibility(item.field)}>
                {tempColumnVisibility[item.field] ? (
                  <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button
            onClick={handleCancelChanges}
            variant={window.BUTTON_TYPE}
            /*  sx={{
              background: theme.palette.error.main,
              "&:hover": { backgroundColor: theme.palette.error.dark },
            }} */
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleSaveChanges}
            variant={window.BUTTON_TYPE}
            /* sx={{
              mr: 1,
              background: theme.palette.success.main,
              "&:hover": { backgroundColor: theme.palette.success.dark },
            }} */
          >
            {t("save")}
          </Button>
        </Box>
      </ModalDialog>
      {hasAtLeastOneTrue ? (
        <>
          {!infiniteMode ? (
            <StyledDataGrid
              disableColumnMenu
              rows={rows ?? []}
              columns={columnsWithIconHeader}
              rowCount={count ?? 0}
              pageSize={perPage}
              rowsPerPageOptions={[perPage]}
              getRowClassName={(params) => styles && styles(params)}
              onSortModelChange={sort && handleSortModelChange}
              disableSelectionOnClick
              sortingMode="server"
              onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1}
              paginationMode={paginationMode}
              getRowHeight={() => (getRowHeight ? getRowHeight() : "auto")}
            />
          ) : (
            <InfiniteTable
              styles={{ height: height, mt: 4 }}
              rows={rows ?? []}
              columns={columnsWithIconHeader}
              page={page}
              perPage={perPage}
              setPage={setPage}
              paginationMode={"server"}
              records={records}
              recordsFn={recordsFn}
            />
          )}
        </>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          width="100%"
          alignItems={"center"}
          sx={{ mt: 2 }}
        >
          <Typography variant="h5">{`${t("no_columns")} ->`}</Typography>
          <IconButton
            onClick={() => {
              setModal(true);
            }}
            sx={{ ml: 1, mt: 1 }}
          >
            <PreviewIcon
              onClick={handlePreviewHeaderClick}
              sx={{
                cursor: "pointer",
                fill: theme.palette.error.main,
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default IwdDataGrid;
