import React from "react";
import { MuiColorInput } from "mui-color-input";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";

const ColorPickerWidget = (props) => {
  const [primary, setPrimary] = useState(
    props?.schema?.properties?.primary?.default ?? "#ffffff"
  );
  const [secondary, setSecondary] = useState(
    props?.schema?.properties?.secondary?.default ?? "#ffffff"
  );

  const primaryChange = (newValue) => {
    setPrimary(newValue);
  };
  const secondaryChange = (newValue) => {
    setSecondary(newValue);
  };

  useEffect(() => {
    props.onChange({
      ...props.formData,
      primary: primary,
    });
  }, [primary]);
  useEffect(() => {
    props.onChange({
      ...props.formData,
      secondary: secondary,
    });
  }, [secondary]);
  return (
    <>
      <Typography>Colors</Typography>
      <Box sx={{ mt: 2 }}>
        <MuiColorInput
          format="hex"
          fullWidth
          sx={{ mr: 2 }}
          label="Primary"
          isAlphaHidden
          value={primary}
          onChange={primaryChange}
        />
        <MuiColorInput
          format="hex"
          fullWidth
          label="Secondary"
          isAlphaHidden
          value={secondary}
          onChange={secondaryChange}
        />
      </Box>
    </>
  );
};
export default ColorPickerWidget;
