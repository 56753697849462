import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
const initialState = {
  jobs: null,
  count: null,
};

const jobsSlice = createSlice({
  name: "loadingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      store.jobsEndpoints.getJobs.matchFulfilled,
      (state, action) => {
        state.jobs = action.payload.data;
        state.count = action.payload.meta.count;
      }
    );
  },
});

export default jobsSlice.reducer;
