import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
const lngEnum = [{ const: "it" }, { const: "en" }];
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        /* if (i == 5 || i == 4) {
              return (
                <Grid key={element.id} alignItems="center" item xs={12}>
                  {element.content}
                </Grid>
              );
            } */
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const UserUpdate = () => {
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [updateUser, { isSuccess: updSucc }] = store.useUpdateUserMutation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const fields = useSelector((state) => state?.userSlice?.fieldsToUpdate);
  const schema = {
    type: "object",
    properties: {
      display_name: {
        $id: "2",
        type: "string",
        title: t("display_name"),
        default: fields?.display_name,
      },

      first_name: {
        $id: "4",
        type: "string",
        title: t("first_name"),
        default: fields?.first_name,
      },
      last_name: {
        $id: "5",
        type: "string",
        title: t("last_name"),
        default: fields?.last_name,
      },

      preferred_language: {
        type: "string",
        oneOf: [...lngEnum],
        title: t("preferred_language"),
        default: fields?.preferred_language,
      },
    },
    required: ["display_name", "first_name", "last_name", "preferred_language"],
  };
  useEffect(() => {
    if (fields) {
      dispatch(
        setBreadCrumbs({
          id: `${fields?.first_name} ${fields?.last_name}`,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [fields]);
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  };

  const onCreateClick = () => {
    const attributes = {
      ...formData,
    };
    updateUser({
      attributes: attributes,
      id: id,
    });
  };
  useEffect(() => {
    if (updSucc) {
      dispatch(setMessage(t("user_upd_succ")));
      navigate(`/users/${id}`);
    }
  }, [updSucc]);
  return (
    <>
      <Typography sx={{ mb: 4 }} variant="h4">
        {t("update_user")}
      </Typography>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        onSubmit={onCreateClick}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        validator={validator}
        showErrorList={false}
        noHtml5Validate
      />
    </>
  );
};
export default UserUpdate;
