import { store } from "../redux/store";
import ModalDialog from "../components/meters/ModalDialog";
import { useState, useEffect } from "react";
import { setMessage } from "../redux/slices/snackbarSlice";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { IwdFormAuthWrapper } from "../utils/IwdFormAuthWrapper";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
const ImportHistoryButton = ({ id, mode }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [importMterHistory, { isSuccess }] =
    store.useImportMeterHcaHistoryMutation();
  const [importBlockHistory, { isSuccess: importBlockHistorySuccess }] =
    store.useImportHcaHistoryMutation();
  const [modal, setModal] = useState(false);
  const onImportClick = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const onSubmitClick = () => {
    if (mode == "block") {
      importBlockHistory({ id: id, attributes: {} });
    }
    if (mode == "meter") {
      importMterHistory({ id: id, attributes: {} });
    }
  };

  useEffect(() => {
    if (importBlockHistorySuccess || isSuccess) {
      dispatch(setMessage(t("imported_successfully")));
      closeModal();
    }
  }, [importBlockHistorySuccess, isSuccess]);
  return (
    <>
      <ModalDialog
        title={t("hca_history_import")}
        close={closeModal}
        open={modal}
      >
        <Box>
          <Typography variant="h5">{t("import_history_msg")}</Typography>
          <Box justifyContent={"space-between"} display={"flex"} sx={{ mt: 4 }}>
            <Button onClick={closeModal}>{t("no")}</Button>
            <Button onClick={onSubmitClick}>{t("yes")}</Button>
          </Box>
        </Box>
      </ModalDialog>
      <IwdAuthWrapper
        children={
          <Button variant={window.BUTTON_TYPE} onClick={onImportClick}>
            {t("import_history")}
          </Button>
        }
        section={"import_hca_history_btn"}
      />
    </>
  );
};

export default ImportHistoryButton;
