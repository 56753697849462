import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import theme from "../../theme";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import PasswordComponent from "./PasswordComponent";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { useNavigate } from "react-router-dom";
import UserName from "./UserName";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        /* if (i == 5 || i == 4) {
            return (
              <Grid key={element.id} alignItems="center" item xs={12}>
                {element.content}
              </Grid>
            );
          } */
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const UserCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [createdUserId, setCreatedUserId] = useState(null);
  const [createUser, { isSuccess: userCreateSuccess }] =
    store.useCreateUserMutation();

  const { t } = useTranslation();
  const lngEnum = [{ const: "it" }, { const: "en" }];
  const schema = {
    title: "New user",
    type: "object",
    properties: {
      /*  avatar_url: {
        $id: 1,
        title: t("avatar_url"),
        type: "string",
      }, */
      email: {
        $id: "3",
        type: "string",
        title: t("email"),
      },
      /* password: {
        $id: "12",
        type: "string",
        title: t("password"),
      }, */
      display_name: {
        $id: "2",
        type: "string",
        title: t("display_name"),
      },

      first_name: {
        $id: "4",
        type: "string",
        title: t("first_name"),
      },
      last_name: {
        $id: "5",
        type: "string",
        title: t("last_name"),
      },
      /*      gender: {
        $id: 6,
        type: "string",
        title: t("gender"),
        oneOf: [...genderEnum],
        default: "Gender unspecified",
      }, */

      nick_name: {
        $id: "8",
        type: "string",
        title: t("nick_name"),
      },

      user_name: {
        $id: "11",
        type: "string",
        title: t("user_name"),
      },
      preferred_login_name: {
        $id: "9",
        type: "string",
        title: t("preferred_login_name"),
      },
      roles: {
        $id: "10",
        type: "array",
        title: t("roles"),
        roles: true,
        multiple: true,
        url: "role",
      },
      preferred_language: {
        type: "string",
        oneOf: [...lngEnum],
        title: t("preferred_language"),
      },
      /*  login_names: {
        $id: 7,
        type: "array",
        title: t("login_names"),
        items: {
          type: "string",
        },
      }, */
    },
    required: [
      "email",
      /*  "password", */
      "display_name",
      "first_name",
      "last_name",
      "nick_name",
      "user_name",
      "preferred_login_name",
      "roles",
    ],
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("users/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const onCreateClick = () => {
    const attributes = {
      avatar_url: null,
      display_name: formData.display_name,
      email: formData.email,
      first_name: formData.first_name,
      gender: "GENDER_UNSPECIFIED",
      /*   initial_password: formData.password, */
      is_email_verified: true,
      is_phone_verified: false,
      last_name: formData.last_name,
      login_names: [formData.preferred_login_name],
      nick_name: formData.nick_name,
      preferred_login_name: formData.preferred_login_name,
      roles: formData.roles,
      user_name: formData.user_name,
      preferred_language: formData?.preferred_language,
    };
    createUser(attributes)
      .unwrap()
      .then((data) => setCreatedUserId(data?.data?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  const autocomplete = () => {
    if (formData.email) {
      if (
        formData.email.includes(".") &&
        formData.email.indexOf(".") < formData.email.indexOf("@")
      ) {
        setFormData((prev) => ({
          ...prev,
          first_name: formData.email.substring(0, formData.email.indexOf(".")),
          last_name: formData.email.substring(
            formData.email.indexOf(".") + 1,
            formData.email.indexOf("@")
          ),
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          first_name: "",
          last_name: "",
        }));
      }

      setFormData((prev) => ({
        ...prev,
        display_name: formData.email.substring(0, formData.email.indexOf("@")),
        nick_name: formData.email.substring(0, formData.email.indexOf("@")),
        user_name: formData.email.substring(0, formData.email.indexOf("@")),
        preferred_login_name: formData.email.substring(
          0,
          formData.email.indexOf("@")
        ),
      }));
    }
    if (!formData.email) {
      setFormData((prev) => ({
        ...prev,
        first_name: "",
        last_name: "",
        display_name: "",
        nick_name: "",
        user_name: "",
        preferred_login_name: "",
      }));
    }
  };
  const fields = {
    serverSearch: SearchOnServer,
    userName: UserName,
    password: PasswordComponent,
  };
  useEffect(() => {
    autocomplete();
  }, [formData.email]);
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("create"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    roles: {
      "ui:field": "serverSearch",
    },

    password: {
      "ui:field": "password",
    },
  };
  useEffect(() => {
    if (userCreateSuccess && createdUserId) {
      navigate(`/users/${createdUserId}`);
    }
  }, [userCreateSuccess, createdUserId]);
  useEffect(() => {}, [formData]);
  useEffect(() => {}, [createdUserId]);
  const customValidate = (formData, errors) => {
    if (formData?.roles?.length < 1) {
      errors?.roles?.addError("is a required property");
    }
    return errors;
  };
  useEffect(() => {}, []);

  return (
    <>
      <Typography sx={{ mb: 4 }} variant="h4">
        {t("new_user")}
      </Typography>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        onSubmit={onCreateClick}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        validator={validator}
        showErrorList={false}
        customValidate={customValidate}
        noHtml5Validate
      />
    </>
  );
};
export default UserCreate;
