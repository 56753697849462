import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  actions: null,
  count: null,
  actionShow: null,
  fromBlock: false,
  fromBlockId: null,
  refreshActions: false,
  page: 1,
  resetTwin: false,
};

const actionsSlice = createSlice({
  name: "actionsSlice",
  initialState,
  reducers: {
    setFromBlock(state, action) {
      state.fromBlock = action.payload;
    },
    setFromBlockId(state, action) {
      state.fromBlockId = action.payload;
    },
    setRefreshActions(state, action) {
      state.refreshActions = action.payload;
    },
    setActionPage(state, action) {
      state.page = action.payload;
    },
    setRestTwin(state, action) {
      state.resetTwin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.actionsEndpoints.getActionsIndex.matchFulfilled,
      (state, action) => {
        state.actions = action.payload.data;
        state.count = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.actionsEndpoints.getActionsShow.matchFulfilled,
      (state, action) => {
        state.actionShow = action.payload.data;
      }
    );
  },
});
export const {
  setFromBlock,
  setFromBlockId,
  setRefreshActions,
  setActionPage,
  setRestTwin,
} = actionsSlice.actions;
export default actionsSlice.reducer;
