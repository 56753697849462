import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  alarms: null,
  alarmsCount: null,
  haveRecords: "waiting",
  meterAlarms: null,
  meterAlarmsCount: null,
};

const alarmsSlice = createSlice({
  name: "alarmsSlice",
  initialState,
  reducers: {
    cleanAlarms(state) {
      state.alarms = null;
    },
    setAlarmsRecords(state, action) {
      state.haveRecords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.alarmsEndpoints.getAlarms.matchFulfilled,
      (state, action) => {
        if (action.payload.data.attributes.data.length > 0) {
          state.haveRecords = "havedata";
        } else {
          state.haveRecords = "nodata";
        }
        state.alarms = action.payload.data.attributes.data;
        state.alarmsCount = action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.alarmsEndpoints.getMeterAlarms.matchFulfilled,
      (state, action) => {
        state.meterAlarms = action.payload.data.attributes.data;
        state.meterAlarmsCount = action.payload.data.attributes.meta.count;
      }
    );
  },
});
export const alarmsActions = alarmsSlice.actions;
export default alarmsSlice.reducer;
