import { prepareParams } from "../../utils";
import { prepareBodyWithoutId } from "../../utils/api_params";
export const actionsApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getActionsIndex: build.query({
        query: (params) => {
          return `v1/approved_action?${prepareParams(params)}`;
        },
      }),
      getActionsShow: build.query({
        query: ({ id, params }) => {
          return `v1/approved_action/${id}?${prepareParams(params)}`;
        },
      }),
      approveActions: build.mutation({
        query: (attributes) => ({
          url: "v1/approved_action",
          method: "POST",
          body: JSON.stringify(
            prepareBodyWithoutId(attributes, "approved_action")
          ),
        }),
      }),
    }),
  });
