import { store } from "../../redux/store";
import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useNavigate } from "react-router-dom";
import { nameTransform } from "../../utils/utilsFunctions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import theme from "../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { Button, Typography } from "@mui/material";
const EdgeIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const data = useSelector((state) => state?.loraSlice?.loraIndex);
  const { t } = useTranslation();
  const [getLoraIndex] = store.useLazyGetIndexLoraQuery();
  useEffect(() => {
    getLoraIndex({
      class: "edge_controller",
    });
  }, []);
  useEffect(() => {
    dispatch(setBreadCrumbs("edge"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      /*    hide: true, */
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "serial",
      headerName: t("name"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            {/*        <IconButton
              onClick={() => onTrashClick(params?.value)}
              sx={{ float: "right" }}
            >
              <DeleteIcon sx={{ ml: 2, fill: theme.palette.error.main }} />
            </IconButton> */}
            <Link to={`/edge/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.navIcon }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",
    /*    class: item?.attributes?.class ? item?.attributes?.class : "--", */
    /*    tag: item?.attributes?.fields?.tags
      ? item?.attributes?.fields?.tags?.map((item) => item)
      : "--", */
    class: item?.attributes?.class
      ? nameTransform(item?.attributes?.class)
      : "--",
    serial: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    metrological_serial: item?.attributes?.fields?.serial ?? "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",
    /*  pod: item?.attributes?.fields?.pod ? item?.attributes?.fields?.pod : "--", */
    /*     diameter: item?.attributes?.fields?.diameter
      ? item?.attributes?.fields?.diameter
      : "--", */
    configdate: item?.attributes?.inserted_at
      ? t("dt", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: item.id ? item.id : "",
  }));

  return (
    <Box sx={{ height: 700 }}>
      {/*  <Typography
        variant="h4"

      >
        Edge controllers
      </Typography> */}
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() => navigate("/edge/create")}
          variant={window.BUTTON_TYPE}
          sx={{ float: "right", mt: 3, mb: 1 }}
        >
          {t("create_edge")}
        </Button>
      </Box>

      <IwdDataGrid
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
        perPage={perPage}
        setPage={setPage}
        page={page}
        sort={false}
        height={700}
        tableName={"edge"}
        paginationMode={"server"}
      />
      {/* <StyledDataGrid
        disableColumnMenu
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
       
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
      
        paginationMode="server"
        getRowHeight={() => "auto"}
      /> */}
    </Box>
  );
};
export default EdgeIndex;
