import React, { useEffect, useState } from "react";
import { Box, Paper, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/uk";
import IwdBreadCrumb from "../components/IwdBreadcrumb";
import { useRef } from "react";
import IwdBreadCrumbProvider from "./IwdBreadcrumbProvider";
import { setPrevLocation } from "../redux/slices/utilsSlice";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import ErrorDisplay from "../utils/ErrorDisplay";
import theme from "../theme";
import BSBreadCrumb from "./BSBreadcrumb";
import Banner from "../utils/Banner";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const localeMap = {
  "it-IT": itLocale,
  "en-UK": enLocale,
};

const Layout = () => {
  const { i18n } = useTranslation();
  const loginPage =
    useSelector((state) => state?.breadCrumbMeterSlice?.breadcrumbs) == "Login";
  const location = useLocation();
  const previousPathRef = useRef(location.pathname);
  const dispatch = useDispatch();
  const [firstRender, setFirstRender] = useState(true);
  const navigate = useNavigate();

  /*  useEffect(() => {
    const bearer = localStorage.getItem("user_token");
    if (!bearer) {
      navigate("/login");
    }
  }, []); */
  useEffect(() => {
    if (!firstRender) {
      const currentPath = location.pathname;
      sessionStorage.setItem("previousPath", currentPath);
    }
    setFirstRender(false);
  }, [location]);
  useEffect(() => {
    // Log the previous path

    console.log("Previous Path:", previousPathRef.current);

    // Dispatch previous path to Redux before updating the ref
    dispatch(setPrevLocation(previousPathRef.current));

    // Update the ref with the current path
    previousPathRef.current = location.pathname;
  }, [location]);

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={localeMap[i18n.language]}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            background: theme.palette.secondary.main,
            color: "white",
          }}
        >
          {!loginPage ? (
            <>
              <Header />
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  flexBasis: "fitContent",
                }}
              >
                <Box component="main">
                  <IwdBreadCrumbProvider>
                    <IwdBreadCrumb />

                    <Paper
                      sx={{
                        paddingLeft: "2rem",
                        paddingTop: "2rem",
                        paddingBottom: "2rem",
                        paddingRight: "2rem",
                        borderRadius: 0,
                      }}
                      elevation={0}
                    >
                      <ErrorDisplay />
                      <Outlet />
                    </Paper>
                  </IwdBreadCrumbProvider>
                </Box>
                <Footer />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  flexBasis: "fitContent",
                }}
              >
                <Box component="main">
                  <IwdBreadCrumbProvider>
                    {" "}
                    <Paper
                      sx={{
                        paddingLeft: "2rem",
                        paddingTop: "2rem",
                        paddingBottom: "2rem",
                        paddingRight: "2rem",
                        borderRadius: 0,
                      }}
                      elevation={0}
                    >
                      <Outlet />
                    </Paper>
                  </IwdBreadCrumbProvider>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </LocalizationProvider>
    </>
  );
};
export default Layout;
