import { useState, useEffect } from "react";
export const Count = () => {
  const [count, setCount] = useState();

  useEffect(() => {}, [count]);

  /*  const incrementCount = () => {
    setCount("a");
    setCount("b");
    setCount("v");
  }; */
  useEffect(() => {
    setCount("a");
    setCount("b");
    setCount("v");
  }, []);

  return (
    <div>
      <p>Count: {count}</p>
      <button /* onClick={incrementCount} */>Increment</button>
    </div>
  );
};
