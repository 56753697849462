import { createSlice } from "@reduxjs/toolkit";

import { store } from "../store";
const initialState = {
  type: null,
  balances: null,
  balancesCount: null,
  trashId: null,
  balance: null,
  balanceValues: null,
  valuesCount: null,
  haveRecords: "waiting",
  recorsLength: 0,
};

const balanceSlice = createSlice({
  name: "balanceSlice",
  initialState,
  reducers: {
    setBalancType(state, action) {
      state.type = action.payload;
    },
    setBalanceTrashId(state, action) {
      state.trashId = action.payload;
    },
    cleanBalances(state) {
      state.balances = null;
      state.balance = null;
    },
    setRecords(state, action) {
      state.haveRecords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.balanceEndpoints.getBalanceIndex.matchFulfilled,
      (state, action) => {
        state.balances = action.payload.data.attributes?.data;
        state.balancesCount = action.payload.data.attributes?.meta?.count;
        state.recorsLength =
          state.recorsLength + action.payload.data.attributes?.data?.length;
        if (action.payload.data.attributes.data.length > 0) {
          state.haveRecords = "havedata";
        } else {
          state.haveRecords = "nodata";
        }
      }
    );
    builder.addMatcher(
      store.balanceEndpoints.getBalanceShow.matchFulfilled,
      (state, action) => {
        state.balance = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.balanceEndpoints.getBalanceValues.matchFulfilled,
      (state, action) => {
        state.balanceValues = action.payload.data.attributes.data;
        state.valuesCount = action.payload.data.attributes.meta.count;
      }
    );
  },
});
export const { setBalancType, setBalanceTrashId, cleanBalances, setRecords } =
  balanceSlice.actions;
export default balanceSlice.reducer;
