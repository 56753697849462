import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerAppBarOpen: true,
  prevLocation: null,
};
const utilsSlice = createSlice({
  name: "utilsSlice",
  initialState,
  reducers: {
    setHeaderAppBar(state) {
      state.headerAppBarOpen = !state.headerAppBarOpen;
    },
    setPrevLocation(state, action) {
      state.prevLocation = action.payload;
    },
  },
});
export const { setHeaderAppBar, setPrevLocation } = utilsSlice.actions;

export default utilsSlice.reducer;
