import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import theme from "../../../theme";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
const ImpulsiveMeterRegistry = ({ result, enteredFromParent }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {}, [result]);

  return (
    <Grid container>
      <Grid itex xs={3}>
        <Typography variant="subtitle2">Wmbus address</Typography>
        <Typography>{result?.wmbus_address}</Typography>
      </Grid>

      <Grid itex xs={2}>
        <Typography variant="subtitle2">{t("time_zone")}</Typography>
        <Typography>Europe/Rome</Typography>
      </Grid>
      <Grid itex xs={2}>
        <Typography variant="subtitle2">{t("meter_type")} :</Typography>
        <Typography>{result?.meter_type}</Typography>
      </Grid>
      <Grid itex xs={2}>
        <Typography variant="subtitle2">{t("unit")} :</Typography>
        <Typography>{result?.unit_of_reading}</Typography>
      </Grid>
      {!enteredFromParent && result?.parent && (
        <Grid item xs={3}>
          <Typography variant="subtitle2"> Parent :</Typography>
          <IconButton onClick={() => navigate(`/meters/${result?.parent}`)}>
            <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
export default ImpulsiveMeterRegistry;
