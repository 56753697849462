import React, { useEffect, useState } from "react";
import ImgUpload from "./ImgUpload";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";

const ImageBox = ({ imgId, text, seveIdFn, success, msg }) => {
  const bearer = localStorage.getItem("user_token");
  const [imageUrl, setImageUrl] = useState(
    `${window.BASE_URL}/api/v1/image/${imgId}?token=${bearer}` // Placeholder URL for default image
  );

  useEffect(() => {
    if (imgId) {
      setImageUrl(`${window.BASE_URL}/api/v1/image/${imgId}?token=${bearer}`);
    }
  }, [imgId]);
  useEffect(() => {
    if (success) {
      window.location.reload();
    }
  }, [success]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "24px",
          border: "2px solid #ccc",
          borderRadius: "12px",
          width: "97%",
          margin: "auto",
        }}
      >
        <Typography sx={{ mb: 2 }} variant="subtitle1">
          {text}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* Image Display Section */}
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{
              width: "200px",
              height: "264px",
              borderRadius: "12px",
              objectFit: "cover",
            }}
          />

          {/* Image Upload Section */}
          <Box sx={{ marginLeft: "24px" }}>
            <ImgUpload seveIdFn={seveIdFn} msg={msg} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImageBox;
