import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, Typography } from "@mui/material";
import { errorList, integraErrors, errorListMaddalena } from "./errorsList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import theme from "../../theme";

const RawErrorCustomField = (props) => {
  const { t } = useTranslation();
  const error = useSelector(
    (state) => state?.errorAssociationSlice?.errorAssociation
  );
  const { id } = useParams();
  const [className, setClassName] = useState(
    id ? props?.schema?.properties?.class?.default : ""
  );
  const [rawError, setRawError] = useState(
    id ? props?.schema?.properties?.error?.default : ""
  );

  const classesSelector = () => {
    switch (window.MAPERRORS) {
      case "bs":
        return errorList;
      case "integra":
        return integraErrors;
      case "mad":
        return errorListMaddalena;
    }
  };
  const classErrorSelector = () => {
    switch (window.MAPERRORS) {
      case "bs":
        return errorList?.[className];
      case "integra":
        return integraErrors?.[className];
      case "mad":
        return errorListMaddalena?.[className];
    }
  };

  const classes = [...Object.keys(classesSelector())];
  const classError = props?.errorSchema?.class?.__errors?.[0];
  const rawErr = props?.errorSchema?.error?.__errors?.[0];
  const classErrors = classErrorSelector();

  const classHandler = (e) => {
    setClassName(e.target.value);
  };
  const errorhandler = (e) => {
    setRawError(e.target.value);
  };
  useEffect(() => {
    props.onChange({
      class: className,
      error: props?.formData?.error,
    });
  }, [className]);
  useEffect(() => {
    props.onChange({
      class: props?.formData?.class,
      error: rawError,
    });
  }, [rawError]);
  /*   useEffect(() => {
    if (id && error) {
      setClassName(error?.class);
      setRawError(error?.raw_error);
    }
  }, [id, error]); */
  useEffect(() => {}, [className, rawError]);
  useEffect(() => {}, [props.errorSchema]);
  return (
    <Box display={"flex"}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t("class")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={className}
          label={t("class")}
          onChange={classHandler}
          error={classError}
        >
          {classes?.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {classError && classError}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth sx={{ ml: 2 }}>
        <InputLabel id="demo-simple-select-label">{t("raw_error")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={rawError}
          label={t("raw_error")}
          onChange={errorhandler}
          error={rawErr}
        >
          {classErrors?.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {rawErr && rawErr}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
export default RawErrorCustomField;
