import { createSlice } from "@reduxjs/toolkit";

import { store } from "../store";
const initialState = {
  statistics: [],
  statisticsCount: null,
  statistic: null,
  updating: false,
  trashId: null,
  statisticTrashModal: false,
};

const statisticsSlice = createSlice({
  name: "statisticsSlice",
  initialState,
  reducers: {
    setUpdating(state, action) {
      state.updating = action.payload;
    },
    setStatistic(state, action) {
      state.statistic = action.payload;
    },
    setTrashId(state, action) {
      state.trashId = action.payload;
    },
    setStatisticTrashModal(state, action) {
      state.statisticTrashModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.statisticEndpoints.getStatistics.matchFulfilled,
      (state, action) => {
        state.statistics = action.payload.data.attributes.data;
        state.statisticsCount = action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.statisticEndpoints.getStatisticShow.matchFulfilled,
      (state, action) => {
        state.statistic = action.payload.data.attributes;
      }
    );
  },
});
export const { setUpdating, setStatistic, setTrashId, setStatisticTrashModal } =
  statisticsSlice.actions;
export default statisticsSlice.reducer;
