import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { clearErrors } from "../redux/slices/errorSlice";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { hasCommonValue } from "./api_params";
import { useEffect } from "react";

const CustomListItem = styled(ListItem)(() => ({
  "&.MuiButtonBase-root": {
    padding: "7px",
  },
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "normal",
  overflowWrap: "break-word",
}));

const StyledTreeItem = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer": {
    display: "none", // Скрываем иконки стрелок
  },
});
const StyledListItemIcon = styled(ListItemIcon)({
  width: "25px", // Увеличиваем минимальную ширину контейнера для иконки
  "& .MuiSvgIcon-root": {
    fontSize: "1.7rem", // Увеличиваем размер самой иконки
  },
});

export const CloseIwdTreeItem = ({
  uuid,
  icon,
  title,
  children,
  section,
  disabled,
  link = false,
  hrefLink = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permessions = window.MENU[section];
  const groups = useSelector((state) => state?.userPreferenceSlice.groups);

  const onNavigatwClick = () => {
    if (link) {
      navigate(link);
      dispatch(clearErrors());
    }
    if (hrefLink) {
      dispatch(clearErrors());
      window.location.href = "/metrics";
    }
  };

  useEffect(() => {}, [permessions, section]);

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);

  const render = () => {
    if (permessions?.groups?.length > 0) {
      if (hasCommonValue(groups, permessions?.groups)) {
        return true;
      } else {
        return false;
      }
    }
    if (permessions?.groups?.length === 0 || !permessions?.groups) {
      if (hasCommonValue(roles, permessions?.roles)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {permessions?.visibility && render() && (
        <StyledTreeItem
          itemId={uuid}
          disabled={disabled}
          onClick={onNavigatwClick}
          label={
            <CustomListItem sx={{ paddingLeft: 0 }} button>
              <StyledListItemIcon sx={{ color: "white" }}>
                {icon}
              </StyledListItemIcon>
            </CustomListItem>
          }
        >
          {children}
        </StyledTreeItem>
      )}
    </>
  );
};
