import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useLazyGetDigitalTwinsQuery } from "../../redux/slices/digitalTwinApi";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import theme from "../../theme";
import { worklistActions } from "../../redux/slices/worklistSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { Link } from "react-router-dom";

const WorklistEditorTable = ({ perPage, setPage, page }) => {
  const navigate = useNavigate();
  const [idToDelete, setIdToDelete] = useState(null);
  const [getDigitalTwins] = store.useLazyGetDigitalTwinsQuery();
  const twins = useSelector((state) => state?.digitalTwinSlice?.digitalTwins);
  const workLists = useSelector(
    (state) => state?.worklistSlice?.worklistEditors
  );
  const { t } = useTranslation();
  const [trashId, setTrashId] = useState(null);
  const [trashModal, setTrashModal] = useState(false);
  useEffect(() => {
    if (!twins) {
      getDigitalTwins();
    }
  }, []);
  const onTrashClick = (id) => {
    setIdToDelete(id);
    setTrashModal(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      /*  hide: true, */
    },
    {
      field: "name",
      headerName: t("name"),
      sortable: false,
      editable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: t("description"),
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "classes",
      headerName: t("Tipologia"),
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        const twinsNames = twins?.filter((twin) =>
          params.value?.includes(twin.id)
        );
        return (
          <Box>
            {twinsNames?.map((twin) => (
              <Chip label={twin?.attributes?.name} sx={{ mt: 1 }} />
            ))}
          </Box>
        );
      },
    },

    {
      field: "iserted_at",
      headerName: t("inserted_at"),
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: t("updated_at"),
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onTrashClick(params.value)}
            >
              <DeleteIcon sx={{ ml: 1, fill: theme.palette.error.main }} />
            </IconButton>

            <Link to={`/worklisteditors/${params.value}`}>
              <VisibilityIcon
                sx={{
                  float: "right",
                  mt: 1,
                  fill: theme.palette.navIcon,
                }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const rows = workLists?.map((wl) => ({
    id: wl?.id,
    name: wl?.name,
    description: wl?.description,
    classes: wl?.classes,
    iserted_at: t("dt", { val: new Date(wl.inserted_at) }),
    updated_at: t("dt", { val: new Date(wl.updated_at) }),
    actions: wl?.id,
  }));
  return (
    <Box sx={{ height: "57vh", mt: 4 }}>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("worklistEditor_delete_title")}
        title={t("worklistEditor_delete_text")}
        deleteFunc={() => worklistActions.setworklistEditorTrashId(idToDelete)}
      />

      <IwdDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        perPage={perPage}
        setPage={setPage}
        page={page}
        count={rows?.length}
        sort={false}
        height={600}
        tableName={"worklist_editor"}
        paginationMode={"client"}
      />
      {/* <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns ?? []}
     
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
  
        paginationMode="server"
        getRowHeight={() => "auto"}
      /> */}
    </Box>
  );
};
export default WorklistEditorTable;
