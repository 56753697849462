import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Alert,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LogoMad from "./svgs/LogoMad";
import { useDispatch } from "react-redux";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";
import { store } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const ConfirmRegistrationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialCode = searchParams.get("code") ?? "";
  const [confirmRegistration, { isSuccess }] =
    store.useConfirmRegistrationMutation();
  const [code, setCode] = useState(initialCode);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({
    code: "",
    password: "",
    passwordConfirmation: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [submitError, setSubmitError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") setCode(value);
    if (name === "password") setPassword(value);
    if (name === "passwordConfirmation") setPasswordConfirmation(value);
  };

  useEffect(() => {
    dispatch(setBreadCrumbs("Login"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [dispatch]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation(!showPasswordConfirmation);

  const validate = () => {
    let tempErrors = { code: "", password: "", passwordConfirmation: "" };
    let isValid = true;

    if (!code) {
      tempErrors.code = "Code is required";
      isValid = false;
    }

    if (!password) {
      tempErrors.password = "Password is required";
      isValid = false;
    } else if (password.length < 6) {
      tempErrors.password = "Password must be at least 6 characters";
      isValid = false;
    }

    if (!passwordConfirmation) {
      tempErrors.passwordConfirmation = "Password confirmation is required";
      isValid = false;
    } else if (password !== passwordConfirmation) {
      tempErrors.passwordConfirmation = "Passwords do not match";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitError("");
    setSuccessMessage("");

    if (validate()) {
      confirmRegistration({
        code: code,
        password: password,
        password_confirmation: passwordConfirmation,
      })
        .unwrap()
        .then(() => {
          setSuccessMessage(
            "Your registration has been confirmed successfully."
          );
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          setSubmitError("An error occurred. Please try again later.");
        });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSuccessMessage("Your registration has been confirmed successfully.");
      navigate("/login");
    }
  }, [isSuccess]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 3,
          backgroundColor: "#f5f5f5",
        }}
      >
        <Grid container justifyContent="center" sx={{ mb: 4 }}>
          <Box component="div">
            <LogoMad /> {/* Логотип */}
          </Box>
        </Grid>
        <Typography
          component="h1"
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Confirm Registration
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ mb: 3 }}
        >
          Please enter the code sent to your email to confirm your registration
          and set your password
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Code"
            name="code"
            autoFocus
            value={code}
            onChange={handleChange}
            error={Boolean(errors.code)}
            helperText={errors.code}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label="Confirm Password"
            type={showPasswordConfirmation ? "text" : "password"}
            id="passwordConfirmation"
            autoComplete="new-password"
            value={passwordConfirmation}
            onChange={handleChange}
            error={Boolean(errors.passwordConfirmation)}
            helperText={errors.passwordConfirmation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPasswordConfirmation}
                    edge="end"
                  >
                    {showPasswordConfirmation ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {submitError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {submitError}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant={window.BUTTON_TYPE}
            sx={{ mt: 3, mb: 2 }}
          >
            Confirm Registration
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ConfirmRegistrationPage;
