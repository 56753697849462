import { api } from "./api";
import {
  prepareBody,
  prepareParams,
  prepareBodyWithoutId,
} from "../../utils/api_params";

const preparePolygonBody = ({ positions }, id) => {
  return {
    data: {
      attributes: {
        geom: {
          coordinates: [[...positions, positions[0]]],
          type: "Polygon",
        },
      },
      work_list_editor_id: id,
      type: "plguin",
    },
  };
};

export const worklistApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      createWorklistEditor: build.mutation({
        query: (attributes) => ({
          url: "v1/plugin/worklisteditor",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      getWorklistEditorIndex: build.query({
        query: (params) => {
          return `v1/plugin/worklisteditor?${prepareParams(params)}`;
        },
      }),
      getWorklistEditorShow: build.query({
        query: (id) => {
          return `v1/plugin/worklisteditor/${id}`;
        },
      }),
      updateWorklistEditor: build.mutation({
        query: ({ attributes, id }) => ({
          url: `v1/plugin/worklisteditor/${id}`,
          method: "PUT",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      deleteWorkListEditor: build.mutation({
        query: (id) => ({
          url: `v1/plugin/worklisteditor/${id}`,
          method: "DELETE",
        }),
      }),
      createWorklistPolygon: build.mutation({
        query: ({ attributes, id }) => {
          function swapPositions(positions) {
            return positions?.map((position) => [position[1], position[0]]);
          }

          const swappedPositions = swapPositions(attributes.positions);

          return {
            url: "v1/plugin/devicepolygon",
            method: "POST",
            body: JSON.stringify(
              preparePolygonBody(
                { ...attributes, positions: swappedPositions },
                id
              )
            ),
          };
        },
      }),

      getWorklistPolygon: build.query({
        query: (id) => {
          return `v1/plugin/devicepolygon/${id}`;
        },
      }),
      createWorkList: build.mutation({
        query: (attributes) => ({
          url: "v1/plugin/worklist",
          method: "POST",
          body: JSON.stringify(prepareBody(attributes)),
        }),
      }),
      getWorklistShow: build.query({
        query: (id) => {
          return `v1/plugin/worklist/${id}`;
        },
      }),
      getWorklistIndex: build.query({
        query: (params) => {
          return `/v1/plugin/worklist?${prepareParams(params)}`;
        },
      }),
      updateWorklist: build.mutation({
        query: ({ id, attributes }) => ({
          method: "PUT",
          url: `v1/plugin/worklist/${id}`,
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      deleteWorklist: build.mutation({
        query: (id) => ({
          url: `v1/plugin/worklist/${id}`,
          method: "DELETE",
        }),
      }),
      deleteWorklistPolygon: build.mutation({
        query: (id) => ({
          url: `v1/plugin/devicepolygon/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });

/* export const {
  useLazyGetWorklistEditorIndexQuery,
  useLazyGetWorklistPolygonQuery,
  useLazyGetWorklistEditorShowQuery,
  useCreateWorklistEditorMutation,
  useCreateWorklistPolygonMutation,
  useDeleteWorkListEditorMutation,
  useUpdateWorklistEditorMutation,
  useCreateWorkListMutation,
  useLazyGetWorklistShowQuery,
  useLazyGetWorklistIndexQuery,
  useUpdateWorklistMutation,
  useDeleteWorklistMutation,
  useDeleteWorklistPolygonMutation,
} = worklistApi;
 */
