import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { Box } from "@mui/system";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RolesSelector from "./RolesSelector";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (i == 0 || i == 1) {
          return (
            <Grid key={element.id} alignItems="center" item xs={6}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const UserGroupsCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createUserGroup, { isSuccess }] = store.useCreateUserGroupMutation();
  const [createdId, setCreatedId] = useState(null);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();
  const schema = {
    title: "New user",
    type: "object",
    properties: {
      name: {
        $id: "4",
        type: "string",
        title: t("name"),
      },
      roles: {
        type: "array",
        title: t("roles"),
        roles: true,
        multiple: true,
        url: "role",
      },
      description: {
        $id: "5",
        type: "string",
        title: t("description"),
      },
    },
    required: ["name", "description"],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    roles: {
      "ui:field": "serverSearch",
    },
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("usergroups/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const onSubmitClick = () => {
    createUserGroup({ ...formData })
      .unwrap()
      ?.then((data) => setCreatedId(data?.data?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  const fields = {
    serverSearch: RolesSelector,
  };
  useEffect(() => {}, [formData]);
  useEffect(() => {
    if (createdId) {
      navigate(`/usergroups/${createdId}`);
    }
  }, [createdId]);
  return (
    <>
      <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
        {t("create_usergroup")}
      </Typography>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        onSubmit={onSubmitClick}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        validator={validator}
        showErrorList={false}
        noHtml5Validate
      />
    </>
  );
};
export default UserGroupsCreate;
