import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import theme from "../../theme";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { nameTransform } from "../../utils/utilsFunctions";

import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { useNavigate } from "react-router-dom";
import {
  setDigitalTwinName,
  unSetDigitalTwin,
} from "../../redux/slices/digitalTwinSlice";

const DigitalTwinIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filteredValue, setFilteredValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [dt, setDt] = useState();
  const [getTwins] = store.useLazyGetDigitalTwinsQuery();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(unSetDigitalTwin());
    getTwins();
  }, []);

  const digitaltwins = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwins
  );
  useEffect(() => {
    if (digitaltwins && firstRender) {
      setDt(digitaltwins);
      setFirstRender(false);
    }
  }, [digitaltwins]);
  const mappedTwins = dt?.map((dt) => ({
    label: dt?.attributes?.name,
  }));
  const onShowClick = (id, name) => {
    dispatch(setDigitalTwinName(name));

    navigate(`/digitaltwins/${id}`);
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("digitaltwins"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const handleColumnClick = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: true,
      flex: 1,
      onClick: () => handleColumnClick("id"),
    },
    {
      field: "name",
      headerName: t("name"),
      type: "string",
      sortable: true,
      headerAlign: "left",
      flex: 5,
      onClick: () => handleColumnClick("name"),
    },
    {
      field: "version",
      headerName: t("version"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 5,
    },
    {
      field: "tag",
      headerName: t("group"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              type="link"
              onClick={() => onShowClick(params.value.id, params.value.name)}
            >
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.navIcon }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const sortedDigitalTwins = dt
    ? [...dt].sort((a, b) => {
        const order = sortOrder === "asc" ? 1 : -1;

        if (sortField === "id") {
          return order * (a?.attributes?.id - b?.attributes?.id);
        } else if (sortField === "name") {
          return order * a?.attributes?.name.localeCompare(b?.attributes?.name);
        }

        return 0;
      })
    : [];

  const rows = sortedDigitalTwins?.map((item) => ({
    id: item?.attributes?.id,
    name: nameTransform(item?.attributes?.name),
    version: item?.attributes?.version,
    tag: item?.attributes?.digital_twin_fields?.tag,
    actions: {
      id: item?.attributes?.id,
      name: item?.attributes?.name,
    },
  }));
  useEffect(() => {
    if (filteredValue) {
      setDt(
        digitaltwins?.filter(
          (item) => item?.attributes?.name == filteredValue.label
        )
      );
    } else {
      setDt(digitaltwins);
    }
  }, [filteredValue]);
  useEffect(() => {}, [dt]);
  return (
    <>
      {dt && (
        <Box>
          {/*   <Typography
            variant="h4"
            sx={{ color: theme?.palette?.primary?.main, mb: 1 }}
          >
            Digital Twins
          </Typography> */}
          <Autocomplete
            sx={{ mb: 3 }}
            disablePortal
            id="combo-box-demo"
            options={mappedTwins}
            onChange={(event, newValue) => {
              setFilteredValue(newValue);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="digital twin" />
            )}
          />
          <IwdDataGrid
            rows={rows ? rows : []}
            columns={columns}
            setPage={setPage}
            page={page}
            count={rows?.length}
            sort={false}
            height={760}
            tableName={"digital_twins"}
            paginationMode={"client"}
          />
        </Box>
      )}
    </>
  );
};

export default DigitalTwinIndex;
