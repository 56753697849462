import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  invites: null,
  count: null,
};

const inviteSlice = createSlice({
  name: "inviteSlice",
  initialState,
  reducers: {
    resetInvites(state) {
      state.invites = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.inviteEndpoints.getInviteList.matchFulfilled,
      (state, action) => {
        state.invites = action.payload.data;
        state.count = action.payload.count;
      }
    );
  },
});
export const { resetInvites } = inviteSlice.actions;
export default inviteSlice.reducer;
