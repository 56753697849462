import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import React from "react";
import PolygonsList from "./PolygonsList";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { store } from "../../redux/store";

import { useParams } from "react-router-dom";
import DeleteButton from "../../utils/DeleteButton";
import { Grid } from "@mui/material";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { actions } from "../../redux/slices/deviceDistrictSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import theme from "../../theme";

const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const DeviceGroupShowForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [updateDistrict, { isSuccess: updateDistrictSuccess }] =
    store.useUpdateDistrictMutation();
  const [deleteDistrict, { isSuccess: deleteSuccess }] =
    store.useDeleteDistrictMutation();
  const [trashModal, setTrashModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [updating, setUpdating] = useState(false);
  const [polygonId, setPolygonId] = useState(null);
  const [getDistrict] = store.useLazyGetDistrictShowQuery();
  const [getPolygons, { isFetching }] = store.useLazyGetPolygonsQuery();
  const [deletePolygon, { isSuccess: deletePolygonSuccess }] =
    store.useDeletePolygonMutation();
  const [createDistrict] = store.useCreateDistrictMutation();
  const [createPolygon, { isSuccess: createPolygonSuccess }] =
    store.useCreatePolygonMutation();
  const district = useSelector((state) => state?.deviceDistrictSlice?.district);
  const polygons = useSelector((state) => state?.deviceDistrictSlice?.polygons);
  const polygonsToRemove = useSelector(
    (state) => state?.deviceDistrictSlice?.polygonsToRemove
  );
  const deleteDistrictId = useSelector(
    (state) => state?.deviceDistrictSlice.deleteDistrictId
  );
  const newPolygonsInUpdate = useSelector(
    (state) => state?.deviceDistrictSlice?.newPolygonsInUpdate
  );
  const updatePolygons = () => {
    if (newPolygonsInUpdate.length > 0) {
      for (let i = 0, len = newPolygonsInUpdate.length; i < len; i++) {
        createPolygon({ id: id, body: newPolygonsInUpdate[i] });
      }
    }
    if (polygonsToRemove.length > 0) {
      for (let i = 0, len = polygonsToRemove.length; i < len; i++) {
        deletePolygon({
          districtId: id,
          polygonId: polygonsToRemove[i].polygon.id,
        });
      }
    }
  };
  useEffect(() => {
    dispatch(actions.updatingOn());
  }, []);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      description: formData.description,
    };
    updateDistrict({ id: id, attributes: attributes });
    updatePolygons();
    setUpdating(true);
  };
  /*  
  useEffect(() => {
    if (polygonId && polygons.length > 0) {
      for (let i = 0, len = polygons.length; i < len; i++) {
        createPolygon({ id: polygonId, body: polygons[i] });
      }
    }
  }, [polygonId, polygons]); */
  useEffect(() => {
    getDistrict(id);
    getPolygons(id);
  }, []);
  const updated = () => {
    dispatch(actions.setPolygonsToremove([]));
    dispatch(actions.cleanPolygonsInUpdate());
    getDistrict(id);
    getPolygons(id);
    setUpdating(false);
    dispatch(setMessage(t("update_success")));
  };
  useEffect(() => {
    if (
      updateDistrictSuccess &&
      createPolygonSuccess &&
      polygonsToRemove.length < 1 &&
      updating
    ) {
      /*  window.location.reload(); */
      updated();
    }
    if (
      updateDistrictSuccess &&
      createPolygonSuccess &&
      deletePolygonSuccess &&
      updating
    ) {
      /*    window.location.reload(); */
      updated();
    }
    if (
      updateDistrictSuccess &&
      newPolygonsInUpdate.length < 1 &&
      deletePolygonSuccess &&
      updating
    ) {
      /*  window.location.reload(); */
      updated();
    }
    if (updateDistrictSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [
    updateDistrictSuccess,
    createPolygonSuccess,
    polygonsToRemove.length,
    deletePolygonSuccess,
    newPolygonsInUpdate.length,
  ]);
  const schema = {
    title: "Device Group form",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: district?.name ?? "",
      },
      description: {
        id: 0,
        type: "string",
        title: t("description"),
        default: district?.description ?? "",
      },
    },
  };
  useEffect(() => {
    if (district) {
      dispatch(
        setBreadCrumbs({
          id: district?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [district]);
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };
  useEffect(() => {
    //clear function
    return () => {
      dispatch(actions.setDistrict(null));
      dispatch(actions.updatingOf());
      dispatch(actions.setPolygons([]));
      dispatch(actions.setDevices({}));
    };
  }, []);
  useEffect(() => {
    if (deleteDistrictId) {
      deleteDistrict(deleteDistrictId);
      dispatch(actions.setDeleteDistrictId(null));
    }
  }, [deleteDistrictId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate(`/groups`);
    }
  }, [deleteSuccess]);
  return (
    <>
      {district && (
        <>
          <DeleteConfirmModal
            trashModal={trashModal}
            setTrashModal={setTrashModal}
            text={t("district_delete_text")}
            title={t("district_delete_title")}
            deleteFunc={() => actions.setDeleteDistrictId(id)}
          />
          <Form
            schema={schema}
            /*     fields={fields} */
            formData={formData}
            /*     customValidate={customValidate} */
            showErrorList={false}
            /*  onSubmit={onSubmitClick} */
            hideSubmitButton
            children={true}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            /*   onSubmit={(values) => {
      onSubmitClick(values.formData);
    }} */
            validator={validator}
            uiSchema={uiSchema}
          />
          <PolygonsList polygonFetching={isFetching} />
          <Button
            onClick={onSubmitClick}
            variant={window.BUTTON_TYPE}
            sx={{
              mt: 2,
            }}
          >
            {t("update")}
          </Button>
          <DeleteButton
            name={t("delete")}
            styles={{ mt: 2, ml: 2 }}
            onClick={() => setTrashModal(true)}
          />
        </>
      )}
    </>
  );
};

export default React.memo(DeviceGroupShowForm);
