import { store } from "../../redux/store";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useSelector, useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import { prepareParams } from "../../utils";
import { Box, Typography } from "@mui/material";
import { createQueryString } from "../../utils/utilsFunctions";
import { alarmsActions } from "../../redux/slices/alarmsSlice";
import IwdAutocomplete from "../form/formComponents/IwdAutocomplete";
import Tooltip from "@mui/material/Tooltip";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useTransition } from "react";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { useSearchParams } from "react-router-dom";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import { setTreeExpanded } from "../../redux/slices/condominiumSlice";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const AlarmsIndex = () => {
  const [formData, setFormData] = useState({});
  const [firstRender, setFirstRenred] = useState(true);
  const [refreshParams, setRefreshParams] = useState(false);
  const [sorts, setSorts] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const haveRecords = useSelector((state) => state?.alarmsSlice?.haveRecords);
  const [page, setPage] = useState(1);
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const errorTypes = useSelector((state) => state?.errorTypeSlice?.errorTypes);
  const alarmsCount = useSelector((state) => state?.alarmsSlice?.alarmsCount);
  const errorType = useSelector((state) => state?.errorTypeSlice?.errorType);
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const meterShow = useSelector((state) => state?.meterSlice?.meterShow);
  const [perPage, setPerPage] = useState(10);
  const { t } = useTranslation();
  const alarms = useSelector((state) => state?.alarmsSlice?.alarms);
  const [getAlarms, { isLoading: alarmsLoading }] =
    store.useLazyGetAlarmsQuery();
  const [getMeter] = store.useLazyGetMeterShowQuery();
  const [getErrorType] = store.useLazyGetErrorTypeShowQuery();

  const defaultMeter = meterShow && {
    label: meterShow?.attributes?.serial_number,
    value: meterShow?.id,
  };
  const defaultBlock = condominiumShow && {
    label: condominiumShow?.name,
    value: condominiumShow?.identifier,
  };
  const defaultError = errorType && {
    label: errorType?.name,
    value: errorType?.id,
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("alarms"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "left",
      sortable: false,
      flex: 2,
      hide: "true",
    },
    {
      field: "condominium_name",
      headerName: t("condominium"),
      headerAlign: "left",
      sortable: false,
      flex: 5,
      renderCell: (params) => {
        return (
          <Box
            width={"90%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <>{params?.value?.name}</>
          </Box>
        );
      },
    },
    {
      field: "sub_condominium_name",
      headerName: t("subcondominum"),
      headerAlign: "left",
      sortable: false,
      flex: 3,
    },
    {
      field: "apartment_name",
      headerName: t("apartament"),
      headerAlign: "left",
      sortable: false,
      flex: 3,
    },

    {
      field: "room",
      headerName: t("room"),
      headerAlign: "left",
      sortable: false,
      flex: 3,
    },
    {
      field: "device_serial_number",
      headerName: t("serial_number"),
      headerAlign: "left",
      sortable: false,
      flex: 3,
    },
    {
      field: "date_times",
      headerName: t("error_date"),
      headerAlign: "left",
      sortable: false,
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params?.value?.length > 0 ? (
              <Box width={"100%"} display={"flex"} flexWrap="wrap">
                {params?.value?.map((item) => (
                  <Box sx={{ mt: params.value.length > 1 && 2 }}>
                    {t("dt_ns", { val: new Date(item) })}
                  </Box>
                ))}
              </Box>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "update_date_times",
      headerName: t("upd_date"),
      headerAlign: "left",
      sortable: false,
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params?.value?.length > 0 ? (
              <Box width={"100%"} display={"flex"} flexWrap="wrap">
                {params?.value?.map((item) => (
                  <Box sx={{ mt: params.value.length > 1 && 2 }}>
                    {t("dt_ns", { val: new Date(item) })}
                  </Box>
                ))}
              </Box>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "error_type_id",
      headerName: t("error_type"),
      headerAlign: "left",
      sortable: false,
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params.value.length > 0 ? (
              <Box width={"100%"} display={"flex"} flexWrap="wrap">
                {params?.value?.map((item) => (
                  <Tooltip title={item}>
                    <Chip
                      sx={{
                        mt: params.value.length > 1 && 1,
                        ml: 1,
                        background: theme.palette.error.main,
                        color: theme.palette.error.contrastText,
                      }}
                      label={item}
                    />
                  </Tooltip>
                ))}
              </Box>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,

      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              onClick={() => {
                localStorage.setItem("alarm_date", params?.value?.date);
                const queryString = createQueryString(params?.value?.date);

                window.open(
                  `${params?.value?.navLink}?${queryString}`,
                  "_blank"
                );
              }}
            >
              <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = alarms?.map((alarm) => ({
    id: alarm?.id,
    error_type_id: alarm?.errors,
    condominium_name: {
      name: alarm?.condominium_name,
      navLink: `/condominiums/${alarm?.condominium_id}`,
      openPath: ["condominum", alarm?.sub_condominium_id, alarm?.apartment_id],
    },
    sub_condominium_name: alarm?.sub_condominium_name,
    apartment_name: alarm?.apartment_name,
    status: alarm?.status,
    room: alarm?.room ?? "--",
    device_serial_number: alarm?.device_serial_number ?? "--",
    update_date_times: alarm?.update_date_times,
    date_times: alarm?.date_times,

    actions: {
      navLink: `/meters/${alarm?.device_id}`,
      date: alarm?.date_times?.[0],
    },
  }));
  const fields = {
    date: SingleDatePicker,
    autocomplete: IwdAutocomplete,
  };
  const paramsList = {
    ...formData,
    page: page,
    per_page: perPage,
    status: "open",

    condominium_id: searchParams.get("condominium_id") ?? "",
    error_type_id: searchParams.get("error_type_id") ?? "",
    device_id: searchParams.get("device_id") ?? "",
    sub_condominium_name: searchParams.get("sub_condominium_name") ?? "",
    apartment_name: searchParams.get("apartment_name") ?? "",
    from_date_time: searchParams.get("from_date_time") ?? "",
    sorts: sorts,
  };

  useEffect(() => {
    if (firstRender) {
      if (paramsList?.condominium_id) {
        getCondominiumShow(paramsList.condominium_id);
      }
      if (paramsList?.device_id) {
        getMeter(paramsList?.device_id);
      }
      if (paramsList?.error_type_id) {
        getErrorType(paramsList?.error_type_id);
      }
      setFirstRenred(false);
    }
  }, []);
  useEffect(() => {
    return () => {
      if (alarms) {
        dispatch(alarmsActions.cleanAlarms());
      }
    };
  }, []);

  /*   useEffect(() => {
    if (refreshParams) {
      getAlarms({ ...paramsList });
      setRefreshParams(false);
    }
  }, [refreshParams, page]); */
  const onSubmitClick = (obj) => {
    setSorts([]);

    const newObj = {
      ...obj,
      from_date_time: obj.from_date_time?.to_date,
    };

    const params = prepareParams(newObj);

    navigate({
      pathname: "/alarms",
      search: params.toString(),
    });
    setPage(1);
    setRefreshParams(true);
    /*  getAlarms({
      ...formData,
      page: page,
      per_page: perPage,
      from_date_time: obj?.from_date_time?.to_date,
      status: "open",
    }); */
  };
  useEffect(() => {
    if (!firstRender) {
      setRefreshParams(true);
    }
  }, [sorts]);
  useEffect(() => {
    if (refreshParams) {
      getAlarms({ ...paramsList });
    }
    setRefreshParams(false);
  }, [refreshParams]);

  useEffect(() => {}, [alarms]);
  const statusEnum = [{ const: "open" }, { const: "closed" }];
  useEffect(() => {}, [formData]);

  useEffect(() => {
    getAlarms({ ...paramsList });
  }, [page]);
  const schema = {
    type: "object",
    properties: {
      condominium_id: {
        type: "string",
        title: t("condominium"),
        url: "/plugin/condominiums?page=1&per_page=10",
        searchUrl: "/plugin/condominiums",
        searchStr: "name",
        default: paramsList?.condominium_id && defaultBlock,
        mapFn: (result) =>
          result?.data?.data?.map((item, i) => ({
            key: i,
            label: `${item.attributes?.fields?.name}`,
            value: item?.attributes?.identifier,
          })),
      },
      sub_condominium_name: {
        type: "string",
        title: t("subcondominum"),
        default: paramsList?.sub_condominium_name,
      },
      apartment_name: {
        type: "string",
        title: t("apartament"),
        default: paramsList?.apartment_name,
      },
      device_id: {
        type: "string",
        title: t("meter_serial_number"),
        default: paramsList?.device_id && defaultMeter,
        url: `altior/device`,
        searchUrl: "altior/device",
        searchStr: "serial_number",
        params: {
          meter_types: JSON.stringify(["AFS"]),
          page: 1,
          per_page: 10,
        },
        mapFn: (result) =>
          result?.data?.data?.map((item, i) => ({
            key: i,
            label: `${item.attributes?.serial_number}`,
            value: item?.id,
          })),
      },
      error_type_id: {
        type: "string",
        title: t("error_type"),
        default: paramsList?.error_type_id && defaultError,
        url: `plugin/errortype`,
        searchUrl: "plugin/errortype",
        searchStr: "name",
        params: {
          page: 1,
          per_page: 10,
        },
        mapFn: (result) =>
          result?.data?.data?.attributes?.data?.map((item, i) => ({
            key: i,
            label: `${item?.name}`,
            value: item?.id,
          })),
      },
      from_date_time: {
        type: "object",
        label: t("error_date"),
        default: paramsList?.from_date_time,
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("search"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    from_date_time: {
      "ui:field": "date",
    },
    condominium_id: {
      "ui:field": "autocomplete",
    },
    device_id: {
      "ui:field": "autocomplete",
    },
    error_type_id: {
      "ui:field": "autocomplete",
    },
  };
  useEffect(() => {}, [formData]);
  return (
    <>
      {/*  <Typography
        variant="h4"
        sx={{ color: theme?.palette?.primary?.main, mb: 4 }}
      >
        {t("alarms")}
      </Typography> */}
      <Form
        schema={schema}
        formData={formData}
        showErrorList={false}
        fields={fields}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => {
          onSubmitClick(values.formData);
        }}
        validator={validator}
        uiSchema={uiSchema}
      />
      <Box sx={{ height: 600, mt: 2 }}>
        {/* <StyledDataGrid
          disableColumnMenu
          rows={rows ?? []}
          columns={columns ?? []}
          rowCount={alarmsCount}
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          // loading={isLoadingTable}
          paginationMode="server"
          getRowHeight={() => "auto"}
        /> */}
        <IwdDataGrid
          columns={columns}
          records={haveRecords}
          recordsFn={alarmsActions.setAlarmsRecords}
          perPage={perPage}
          infiniteMode={true}
          setPage={setPage}
          rows={rows}
          page={page}
          count={alarmsCount}
          setSorts={setSorts}
          sorts={sorts}
          height={600}
          paginationMode={"server"}
          tableName={"alarms"}
        />
      </Box>
    </>
  );
};
export default AlarmsIndex;
