import DeviceGroupForm from "./DeviceGroupForm";
import DeviceGroupsMap from "./DeviceGroupsMap";
import React from "react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import DeviceDistrictMap from "../deviceDistrict/DeviceDistrictMap";
import { Grid } from "@mui/material";
const GroupDeviceCreate = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadCrumbs("Groups/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  return (
    <Grid
      container
      sx={{
        height: "75vh",
        width: appBarIsOpen ? "78vw" : "92vw",
      }}
    >
      <Grid item xs={3} sx={{ mt: 5 }}>
        <DeviceGroupForm />
      </Grid>
      <Grid item xs={9}>
        <Box sx={{ height: "93%", width: "100%", ml: 11 }}>
          <DeviceGroupsMap />
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(GroupDeviceCreate);
