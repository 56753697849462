import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledFallbackContainer = styled(Box)({
  textAlign: "center",
  padding: "2rem",
  backgroundColor: "#f0f0f0",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  maxWidth: "100%",
  margin: "auto",
});

const IwdFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <StyledFallbackContainer>
      <Typography variant="h5" gutterBottom>
        {t("global_err_msg")}
      </Typography>
      {/*  <Typography variant="body1" gutterBottom>
        {error.stack}
      </Typography> */}
      {/* <Button variant={window.BUTTON_TYPE} color="primary" onClick={resetErrorBoundary}>
        Retry
      </Button> */}
    </StyledFallbackContainer>
  );
};

export default IwdFallback;
