import React, { useEffect } from "react";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useSelector, useDispatch } from "react-redux";
import ImageUploading from "react-images-uploading";
import { Box, Button, Typography } from "@mui/material";

const ImgUpload = ({ seveIdFn, msg }) => {
  const dispatch = useDispatch();
  const formData = new FormData();
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const bearer = localStorage.getItem("user_token");
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const onImageRemove = (index) => {
    // Create a new array without the removed image
    const filteredImages = images?.filter((_, i) => i !== index);
    setImages(filteredImages);
  };

  useEffect(() => {}, [images]);

  const uploadFile = () => {
    formData.append("image", images?.[0]?.file);

    fetch(`${window.BASE_URL}/api/v1/image`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${bearer}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        // Обработка данных из ответа

        seveIdFn(data?.data?.id);
        dispatch(setMessage(msg));
        // Далее вы можете выполнить необходимые действия с полученными данными
      })
      .catch((error) => {
        console.error(":Erroriamba", error);
        // Обработка ошибок при выполнении запроса
      });
  };

  return (
    <Box>
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png", "svg"]}
      >
        {({ onImageUpload, imageList }) => (
          <Box>
            {imageList?.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Button
                  sx={{ mt: 25 }}
                  variant={window.BUTTON_TYPE}
                  onClick={onImageUpload}
                >
                  Change immage
                </Button>
              </Box>
            )}
            {imageList?.map((image, index) => (
              <Box key={index} sx={{ marginBottom: "16px" }}>
                <img
                  src={image.data_url}
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "8px",
                  }}
                >
                  <Button
                    variant={window.BUTTON_TYPE}
                    onClick={() => onImageRemove(index)}
                    sx={{ marginRight: "8px" }}
                  >
                    Remove
                  </Button>
                  <Button onClick={uploadFile} variant={window.BUTTON_TYPE}>
                    Upload
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </ImageUploading>
    </Box>
  );
};

export default ImgUpload;
