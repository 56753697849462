import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Buffer } from "buffer";

const bearer = localStorage.getItem("user_token");

export const fetchImage = createAsyncThunk(
  "getImageSlice/fetchImageStatus",
  async (url, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`${url}?token=${bearer}`, {
        /* headers: {
          Authorization: `Bearer ${bearer}`,
        }, */
        responseType: "arraybuffer",
      });

      const buff = Buffer.from(data, "binary");
      const newData = buff.toString("base64");

      return { data: newData, status };
    } catch (err) {
      return rejectWithValue(err.response.status);
    }
  }
);

const initialState = {
  image: null,
  status: "",
};

const getImageSlice = createSlice({
  name: "getImageSlice",
  initialState,
  reducers: {
    clearImage(state) {
      state.status = "";
      state.image = null;
    },
  },
  extraReducers: {
    [fetchImage.fulfilled]: (state, action) => {
      state.image = action.payload.data;
      state.status = action.payload.status;
    },
    [fetchImage.rejected]: (state, action) => {
      state.status = action.payload;
    },
  },
});
export const { clearImage } = getImageSlice.actions;
export default getImageSlice.reducer;
