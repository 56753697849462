import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import ErrorTypeSelector from "./ErrorTypeSelector";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import ParametersHandler from "./ParametersHandler";
import VisibilityHandler from "./VisibilityHandler";
import { errorAssociationActions } from "../../redux/slices/errorAssociationSlice";
import RawErrorCustomField from "./RawErrorCustomField";
import DeleteButton from "../../utils/DeleteButton";
import BlockConsumptions from "./BlockConsumptions";
import { Button, Grid, Typography } from "@mui/material";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ErrorAssociationShow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const error = useSelector(
    (state) => state?.errorAssociationSlice?.errorAssociation
  );
  const [trashModal, setTrashModal] = useState(false);
  const [deleteError, { isSuccess: deleteSucccess, isError: errorOnDelete }] =
    store.useDeleteErrorAssociationMutation();
  const errorTrashId = useSelector(
    (state) => state?.errorAssociationSlice?.trashId
  );
  const [getErrorAssociation] = store.useLazyGetErrorAssociationShowQuery();
  const [getErrorType] = store.useLazyGetErrorTypeShowQuery();
  const [updateErrorAssociation, { isSuccess: updateSuccess }] =
    store.useUpdateErrorAssociationMutation();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {}, [error]);
  useEffect(() => {
    if (error) {
      getErrorType(error?.error_type_id);
    }
  }, [error]);
  useEffect(() => {
    getErrorAssociation(id);
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage("Aggiornato con successo"));
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (error) {
      dispatch(
        setBreadCrumbs({
          id: error?.class,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [error]);
  useEffect(() => {
    if (errorTrashId) {
      deleteError(errorTrashId);

      dispatch(errorAssociationActions.setErrorAssocTrashId(null));
    }
  }, [errorTrashId]);
  useEffect(() => {
    if (deleteSucccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate("/errorassociation");
    }
  }, [deleteSucccess]);
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      error_type_id: {
        type: "string",
        title: t("error_type"),
        default: error?.error_type_id,
        update: true,
      },
      raw_error: {
        type: "object",
        properties: {
          error: {
            type: "string",
            default: error?.raw_error,
          },
          class: {
            type: "string",
            default: error?.class,
          },
        },
      },
      block_consumption_computation: {
        type: "boolean",
        title: t("block_consumption_computation"),
        default: error?.block_consumption_computation,
      },
      visibility: {
        $id: "1",
        type: "object",
        title: t("visibility"),
        properties: {
          groups: {
            type: "object",
            default: { ...error?.visibility?.groups },
          },
          users: {
            type: "object",
            default: { ...error?.visibility?.users },
          },
          comdominium_admin: {
            type: "boolean",
            default: error?.visibility?.comdominium_admin,
          },
        },
      },
      notify: {
        $id: "1",
        type: "object",
        title: t("notify"),
        properties: {
          groups: {
            type: "object",
            default: { ...error?.notify?.groups },
          },
          users: {
            type: "object",
            default: { ...error?.notify?.users },
          },
          comdominium_admin: {
            type: "boolean",
            default: error?.notify?.comdominium_admin,
          },
        },
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    visibility: {
      "ui:field": "parameters",
    },
    notify: {
      "ui:field": "parameters",
    },
    error_type_id: {
      "ui:field": "errortype",
    },
    block_consumption_computation: {
      "ui:field": "BlockConsumptions",
    },
    raw_error: { "ui:field": "rawerror" },
    "ui:order": [
      "raw_error",
      "block_consumption_computation",
      ,
      "error_type_id",
      "visibility",
      "notify",
    ],
  };
  const fields = {
    parameters: VisibilityHandler,
    errortype: ErrorTypeSelector,
    rawerror: RawErrorCustomField,
    BlockConsumptions: BlockConsumptions,
  };
  const onSubmitClick = (obj) => {
    const attributes = {
      class: obj.raw_error?.class,
      raw_error: obj.raw_error.error,
      visibility: obj.visibility,
      notify: obj.notify,
      error_type_id: obj.error_type_id,
      block_consumption_computation: obj.block_consumption_computation,
    };
    updateErrorAssociation({ id: id, attributes: attributes });
  };
  useEffect(() => {}, [formData]);
  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("error_assoc_delete_text")}
        title={t("error_assoc_delete_title")}
        deleteFunc={() => errorAssociationActions.setErrorAssocTrashId(id)}
      />
      {error && (
        <>
          <Typography variant="h4" sx={{ mb: 3 }}>{`${t(
            "error_association"
          )}: ${error?.raw_error}`}</Typography>
          <Form
            schema={schema}
            fields={fields}
            formData={formData}
            /*  customValidate={customValidate} */
            showErrorList={false}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => onSubmitClick(values.formData)}
            validator={validator}
            noHtml5Validate
            uiSchema={uiSchema}
            children={true}
          />
          <Box
            sx={{ mt: 1 }}
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button
              sx={{ mr: 1 }}
              type="submit"
              variant={window.BUTTON_TYPE}
              color="primary"
              onClick={() => onSubmitClick(formData)}
            >
              {t("update")}
            </Button>
            <DeleteButton
              name={t("delete")}
              onClick={() => setTrashModal(true)}
            />
            {/* <Button
              type="button"
              variant={window.BUTTON_TYPE}
              color="secondary"
              onClick={() => setTrashModal(true)}
              sx={{
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.main,
                },
              }}
            >
              {t("delete")}
            </Button> */}
          </Box>
        </>
      )}
    </>
  );
};
export default ErrorAssociationShow;
