import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import { Button, Grid, Typography } from "@mui/material";
import theme from "../../theme";
import Point from "../form/formComponents/Point";
import { setFieldsToUpdate } from "../../redux/slices/gatewaySlice";
import SecurityWidget from "../form/formComponents/customWidgets/SecurityWidget";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { store } from "../../redux/store";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const GatewayUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fieldsToUpdate = useSelector(
    (state) => state?.gatewaySlice?.fieldsToUpdate
  );
  const schm = !fieldsToUpdate?.iccid
    ? {
        title: "New Device",
        type: "object",
        properties: {
          serial_number: {
            $id: "1",
            type: "string",
            length: "10",
            title: t("serial"),
          },
          msisdn: {
            $id: "4",
            type: "number",
            title: t("phone_number"),
          },

          name: {
            $id: "5",
            type: "string",
            title: t("name"),
          },
          note: {
            type: "string",
            title: t("position"),
          },
          time_zone: {
            $id: "6",
            type: "string",
            title: t("time_zone"),
            url: "time_zone",
            defaultTimeZone: true,
            default: "Europe/Rome",

            mapFn: (result) =>
              result?.data?.data?.map((item) => ({
                key: item?.attributes?.key,
                label: item?.attributes?.name,
                value: item?.attributes?.id,
              })),
          },

          coordinates: {
            $id: "12",
            type: "object",
            properties: {
              lat: {
                $id: "28",
                type: "number",
                default: 0,
              },
              lng: {
                $id: "29",
                type: "number",
                default: 0,
              },
            },
          },
        },
        required: ["serial_number", "name"],
      }
    : {
        title: "New Device",
        type: "object",
        properties: {
          serial_number: {
            $id: "1",
            type: "string",
            length: "10",
            title: t("serial"),
          },
          msisdn: {
            $id: "4",
            type: "number",
            title: t("phone_number"),
          },

          /*      street_address: {
    $id: "4",
    type: "string",
    title: t("street_address"),
  }, */
          name: {
            $id: "5",
            type: "string",
            title: t("name"),
          },
          note: {
            type: "string",
            title: t("position"),
          },
          time_zone: {
            $id: "6",
            type: "string",
            title: t("time_zone"),
            url: "time_zone",
            defaultTimeZone: true,
            default: "Europe/Rome",

            mapFn: (result) =>
              result?.data?.data?.map((item) => ({
                key: item?.attributes?.key,
                label: item?.attributes?.name,
                value: item?.attributes?.id,
              })),
          },
          coordinates: {
            $id: "12",
            type: "object",
            properties: {
              lat: {
                $id: "28",
                type: "number",
                default: 0,
              },
              lng: {
                $id: "29",
                type: "number",
                default: 0,
              },
            },
          },
          iccid: {
            type: "string",
            title: t("iccd"),
          },
        },
        required: ["serial_number", "name", "iccid"],
      };
  const [schema, setSchema] = useState(schm);
  const [formData, setFormData] = useState({});
  const [readyForRender, setReadForRender] = useState(false);
  const security = IwdFormAuthWrapper({ section: "security" });
  const [updateGateway, { isSuccess: updSuccess }] =
    store.useUpdateMeterMutation();

  const storageFieldsToUpdate = JSON.parse(
    localStorage.getItem("gatewayUpdFields")
  );

  useEffect(() => {
    if (!fieldsToUpdate && storageFieldsToUpdate) {
      dispatch(setFieldsToUpdate(storageFieldsToUpdate));
    }
  }, [fieldsToUpdate, setFieldsToUpdate]);
  useEffect(() => {
    if (fieldsToUpdate) {
      setFormData({ ...fieldsToUpdate });
      setReadForRender(true);
    }
  }, [fieldsToUpdate]);
  useEffect(() => {
    if (updSuccess && fieldsToUpdate) {
      dispatch(setMessage(t("successfully_updated")));
      navigate(`/gateways/${fieldsToUpdate.instanceId}`);
    }
    if (updSuccess && !fieldsToUpdate) {
      dispatch(setMessage(t("successfully_updated")));
    }
  }, [updSuccess]);

  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "digital_twin",
      "name",
      "serial_number",
      "msisdn",
      "street_address",
      "time_zone",
      "coordinates",
      "note",
      "iccid",
      "security",
    ],

    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        className: "bntListSearch",
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    coordinates: {
      "ui:field": "point",
    },
    security: {
      "ui:field": "security",
    },
    time_zone: {
      "ui:field": "search",
    },
  };
  const fields = {
    point: Point,
    security: SecurityWidget,
    search: SearcheableSelect,
  };

  const onSubmitClick = (obj) => {
    const attributes = {
      fields: {
        class: fieldsToUpdate?.class,
        serial_number: formData.serial_number,
        name: formData?.name,
        tags: ["Gateway"],
        iccid: formData?.iccid && fieldsToUpdate?.iccid,
        msisdn: formData?.msisdn?.toString(),
        digital_twin_id: fieldsToUpdate.twinId,
        note: formData.note,
        time_zone: formData?.time_zone,
        security:
          security && formData?.security?.length > 0
            ? {
                acl: [...formData?.security],
                groups: ["/network_adapter_manager_router", "/codec_manager"],
              }
            : {
                acl: [
                  {
                    name: "/admin",
                    scopes: ["Elixir.show", "Elixir.update"],
                  },
                ],
                groups: ["/admin"],
              },
        coordinates: {
          coordinates: [formData?.coordinates?.lng, formData?.coordinates?.lat],
          type: "Point",
        },
      },
    };
    updateGateway({
      attributes: attributes,
      twinId: fieldsToUpdate.twinId,
      instanceId: fieldsToUpdate.instanceId,
    });
  };
  useEffect(() => {
    if (security) {
      const props = { ...schm.properties };

      props["security"] = {
        type: "array",
        title: t("security"),
        default: fieldsToUpdate?.security,
      };
      const newSchema = { ...schm, properties: props };
      setSchema(newSchema);
    }
  }, [security]);
  useEffect(() => {
    if (fieldsToUpdate) {
      dispatch(
        setBreadCrumbs({
          id: fieldsToUpdate?.serial_number,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [fieldsToUpdate]);

  return (
    <>
      {readyForRender && (
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ mb: 4 }} variant="h4">
            {`${t("upd_gateway")}: ${
              fieldsToUpdate.name ?? fieldsToUpdate.serial_number
            }`}
          </Typography>
          <Form
            schema={schema}
            fields={fields}
            formData={formData}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => onSubmitClick(values.formData)}
            validator={validator}
            uiSchema={uiSchm}
            /* widgets={widgets} */
            showErrorList={false}
            /*   customValidate={customValidate} */
            noHtml5Validate
          />
        </Box>
      )}
    </>
  );
};
export default GatewayUpdate;
