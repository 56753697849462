import { createSlice, current } from "@reduxjs/toolkit";
import { polygon } from "leaflet";
import { store } from "../store";
import { deviceDistrictApi } from "./deviceDistrictApi";
import { worklistApi } from "./worklistApi";

const colors = ["lime", "red", "purple", "yellow", "orange"];

const initialState = {
  polygonName: "",
  worklistEditors: null,
  worklistEditorsCount: null,
  worklistEditor: null,
  worklistEditorTrashId: null,
  worklistTashId: null,
  worklistIndex: null,
  worklistIndexCount: null,
  workList: null,
  updating: false,
  district: null,
  polygons: [],
  districts: null,
  districtsMeta: null,
  devices: {},
  device: null,
  drawPolygon: null,
  currentPolygon: null,
  polygonsToRemove: [],
  newPolygonsInUpdate: [],
  deleteDistrictId: null,
  polygonCoordinates: null,
  freeColor: "",
  devicesToDownload: null,
  haveRecords: "waiting",
};
const worklistSlice = createSlice({
  name: "worklistSlice",
  initialState,
  reducers: {
    cleanWorklistData(state, action) {
      state.polygonName = "";
      state.worklistEditor = null;
      state.polygons = [];
      state.devices = {};
      state.device = null;
    },
    setDistrict(state, action) {
      state.district = action.payload;
    },
    setWorlistRecords(state, action) {
      state.haveRecords = action.payload;
    },
    setDistricts(state, action) {
      state.districts = action.payload;
    },
    setDevice(state, action) {
      state.device = action.payload;
    },
    setDevices(state, action) {
      state.devices = action.payload;
    },
    setDrawPolygon(state, action) {
      state.drawPolygon = action.payload;
    },
    setCurrentPolygon(state, action) {
      state.currentPolygon = action.payload;
    },
    setPolygonsToremove(state, action) {
      state.polygonsToRemove = action.payload;
    },
    setPolygons(state, action) {
      const swappedPolygons = action.payload?.map((point) => [
        point[1],
        point[0],
      ]);
      state.polygons = swappedPolygons;
    },

    setWorklistTrashId(state, action) {
      state.worklistTashId = action.payload;
    },
    clearDistrictAndPolygon(state, action) {
      state.district(null);
      state.polygons([]);
    },
    addPolygon(state, action) {
      const coords = action.payload;
      state.polygons = [
        ...state.polygons,
        {
          pathOptions: { color: state.freeColor },
          positions: coords,
          /*      polygon: action.payload, */
        },
      ];
    },
    addNewPolygonInUpdate(state, action) {
      const coords = action.payload;
      state.newPolygonsInUpdate = [
        ...state.newPolygonsInUpdate,
        {
          pathOptions: { color: state.freeColor },
          positions: coords,
          /*    polygon: action.payload, */
        },
      ];
    },
    cleanPolygonsInUpdate(state, action) {
      state.newPolygonsInUpdate = [];
    },
    removePolygon(state, action) {
      if (!action.payload?.item?.polygon?.geom) {
        state.polygons = current(state.polygons)?.filter(
          (item, i) => item !== action.payload.item
        );
        state.newPolygonsInUpdate = current(state.newPolygonsInUpdate)?.filter(
          (item, i) => item !== action.payload.item
        );
      }
      if (action?.payload?.item?.polygon?.geom) {
        state.polygonsToRemove = [
          ...state.polygonsToRemove,
          action.payload.item,
        ];
      }
    },
    discardRemoving(state, action) {
      state.polygonsToRemove = current(state.polygonsToRemove)?.filter(
        (item) => item !== action.payload
      );
    },
    setHigLight(state, action) {
      state.polygons = action.payload;
    },
    updatingOn(state) {
      state.updating = true;
    },
    updatingOf(state) {
      state.updating = false;
    },
    setDeleteDistrictId(state, action) {
      state.deleteDistrictId = action.payload;
    },
    resetWorklistEdiitors(state) {
      state.worklistEditors = null;
      state.worklistEditorsCount = null;
    },
    setPolygonName(state, action) {
      state.polygonName = action.payload;
    },
    setPoligonCoordinates(state, action) {
      if (action.payload !== null) {
        const lat = action.payload[0]?.map((item) => item[0]);
        const lng = action.payload[0]?.map((item) => item[1]);
        const latMax = Math.max(...lat);
        const latMin = Math.min(...lat);
        const lngMax = Math.max(...lng);
        const lngMin = Math.min(...lng);
        const latCenter = (latMax - latMin) / 2 + latMin;
        const lngCenter = (lngMax - lngMin) / 2 + lngMin;

        state.polygonCoordinates = [latCenter, lngCenter];
      } else {
        state.polygonCoordinates = null;
      }
    },
    setFreeColor(state, action) {
      state.freeColor = action.payload;
    },
    setDevicesToDownload(state, action) {
      const devices = {
        coordinates: action.payload?.geom?.coordinates,
        type: "Polygon",
      };
      state.devicesToDownload = devices;
    },
    resetDeviceToDownload(state) {
      state.devicesToDownload = null;
    },
    setworklistEditorTrashId(state, action) {
      state.worklistEditorTrashId = action.payload;
    },
    resetPolygons(state) {
      state.polygonsToRemove = [];
      state.newPolygonsInUpdate = [];
    },
    cleanWorklist(state) {
      state.workList = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.worklistEndpoints.getPolygonDevices.matchFulfilled,
      (state, action) => {
        state.devices[state.polygonName] = action.payload.data;
        state.polygonName = "";
      }
    );
    builder.addMatcher(
      store.worklistEndpoints.getWorklistEditorShow.matchFulfilled,
      (state, action) => {
        state.worklistEditor = action.payload.data.attributes;
        const downloadedPolygons = action.payload.data.attributes.area
          .sort((a, b) => a.id - b.id)
          ?.map(({ geom, id }, i) => {
            const coords = geom.coordinates?.map((coordinateArray) => {
              return coordinateArray?.map(([lon, lat]) => [lat, lon]);
            });
            return {
              pathOptions: {
                color: colors[i],
              },
              positions: coords,
              polygon: {
                geom: {
                  geom: geom,
                  id: id,
                },
              },
            };
          });

        state.polygons = downloadedPolygons;
      }
    );

    builder.addMatcher(
      store.worklistEndpoints.getPolygons.matchFulfilled,
      (state, action) => {
        const downloadedPolygons = action.payload.data
          .sort((a, b) => a.id - b.id)
          ?.map(({ attributes }, i) => {
            const coords = attributes.geom.coordinates?.map((c) => c);
            return {
              pathOptions: {
                color: colors[i],
              },
              positions: coords,
              polygon: attributes,
            };
          });

        state.polygons = downloadedPolygons;
      }
    );
    builder.addMatcher(
      store.worklistEndpoints.getWorklistEditorIndex.matchFulfilled,
      (state, action) => {
        state.worklistEditors = action.payload.data.attributes;
        state.worklistEditorsCount = action.payload.meta;
      }
    );
    builder.addMatcher(
      store.worklistEndpoints.getWorklistShow.matchFulfilled,
      (state, action) => {
        state.workList = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.worklistEndpoints.getWorklistIndex.matchFulfilled,
      (state, action) => {
        state.worklistIndex = action.payload.data.attributes.data;
        state.worklistIndexCount = action.payload.data.attributes.meta.count;
      }
    );
  },
});
export const worklistActions = worklistSlice.actions;

export default worklistSlice.reducer;
