import { prepareParams } from "../../utils";
export const anomaliesApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getAomalyIndex: build.query({
        query: (params) => {
          return `v1/plugin/anomaly?${prepareParams(params)}`;
        },
      }),
    }),
  });
