import { Middleware } from "redux";
import { actions as iwdWebSocketAction } from "../slices/iwdWebSocketSlice";
import { Socket } from "phoenix";
import { getUserToken } from "../slices/authSlice";

const socketMiddleware = (store) => {
  return (next) => (action) => {
    const token = getUserToken();

    const loading = store.getState().iwdWebSocketSlice.webSocket;
    // const isConnected = store.getState().dashboard.isConnected;
    if (iwdWebSocketAction.connect.match(action) && !loading) {
      const existingWebSocket = store.getState().iwdWebSocketSlice.webSocket;
      if (existingWebSocket) {
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.connected(existingWebSocket));
        }, 500);
      } else {
        let wsSocket = new Socket(`${window.WS_BASE_URL}/socket`, {
          token: token,

          // heartbeatIntervalMs: 1000,
        });

        wsSocket.connect();

        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.connected(wsSocket));
        }, 500);
      }

      //   let channel = ws_socket.channel("plugin:dashboards", { dashboard_id: 1 });
      //   channel.on("data_update", (msg) => {
      //
      //     store.dispatch(dashboardActions.setWidgets(msg.data));
      //   });

      //   channel.on("status", (msg) => {
      //
      //     channel.push("new_msg", "cane ready");
      //   });

      //   channel
      //     .join()
      //     .receive("ok", ({ messages }) => {
      //
      //       channel.push("new_msg", "cane");
      //       store.dispatch(
      //         dashboardActions.connected({ socket: ws_socket, channel: channel })
      //       );
      //     })
      //     .receive("error", ({ reason }) =>
      //     .receive("timeout", () =>
      //
      //     );
    }
    if (iwdWebSocketAction.joinChannel.match(action)) {
      const webSocket = store.getState().iwdWebSocketSlice.webSocket;
      if (webSocket) {
        let channel = webSocket.channel(
          action.payload.name,
          action.payload.params
        );
        channel
          .join()
          .receive("ok", ({ messages }) => {
            store.dispatch(
              iwdWebSocketAction.joinedChannel({
                name: action.payload.name,
                value: channel,
              })
            );
          })
          .receive("error", ({ reason }) => {
            store.dispatch(iwdWebSocketAction.setError(reason));
          })
          .receive("timeout", () => {
            store.dispatch(iwdWebSocketAction.setError("Timeout"));
          });
      } else {
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.setError("WebSocket not valid"));
        }, 500);
      }
    }
    if (iwdWebSocketAction.subscribeChannel.match(action)) {
      const channel =
        store.getState().iwdWebSocketSlice.channels[action.payload.channelName];
      if (
        channel &&
        action.payload.eventName &&
        action.payload.eventName !== ""
      ) {
        channel.on(action.payload.eventName, (msg) => {
          store.dispatch(
            iwdWebSocketAction.setCashData({
              data: msg.data,
              eventName: action.payload.eventName,
            })
          );
        });
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.subscribedChannel(action.payload));
        }, 500);
      } else {
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.setError("Params not valid"));
        }, 500);
      }
    }
    if (iwdWebSocketAction.unsuscribeChannel.match(action)) {
      const channel =
        store.getState().iwdWebSocketSlice.channels[
          action?.payload?.channelName
        ];
      if (
        channel &&
        action.payload.eventName &&
        action.payload.eventName !== ""
      ) {
        channel.off(action.payload.eventName);
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.unsuscribedChannel(action.payload));
        }, 500);
      } else {
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.setError("Params not valid"));
        }, 500);
      }
    }
    if (iwdWebSocketAction.leaveChannel.match(action)) {
      const channel =
        store.getState().iwdWebSocketSlice.channels[action.payload];
      if (channel) {
        channel
          .leave()
          .receive("ok", () =>
            store.dispatch(iwdWebSocketAction.leavedChannel(action.payload))
          );
      } else {
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.setError("Channel not valid"));
        }, 500);
      }
    }
    if (iwdWebSocketAction.disconnect.match(action)) {
      const webSocket = store.getState().iwdWebSocketSlice.webSocket;

      if (webSocket) {
        webSocket.disconnect(() =>
          store.dispatch(iwdWebSocketAction.disconnected())
        );
      } else {
        setTimeout(() => {
          store.dispatch(iwdWebSocketAction.setError("WebSocket not valid"));
        }, 500);
      }
    }

    next(action);
  };
};

export default socketMiddleware;
