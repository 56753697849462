import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  anomalies: null,
  count: null,
};

const anomaliesSlice = createSlice({
  name: "anomaliesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      store.anomaliesEndpoints.getAomalyIndex.matchFulfilled,
      (state, action) => {
        state.anomalies = action.payload.data.attributes?.data;
        state.count = action.payload.data.attributes.meta.count;
      }
    );
  },
});
export default anomaliesSlice.reducer;
