import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { store } from "../../redux/store";
import { useEffect, useState } from "react";
const DexWidget = (props) => {
  const [value, setValue] = useState(null);
  const [readyToRender, setReady] = useState(false);
  const [getDex] = store.useLazyGetDexIndexQuery();
  useEffect(() => {
    getDex();
  }, []);
  const dex = useSelector((state) => state?.dexDataSourceSlice?.dexIndex)?.map(
    (item) => ({
      label: item?.attributes?.name,
      value: item?.id,
    })
  );
  useEffect(() => {
    setValue(props?.schema?.default);
  }, []);
  useEffect(() => {
    if (!readyToRender && value) {
      setReady(true);
    }
  }, [value]);
  useEffect(() => {
    if (value) {
      props.onChange(value.value);
    }
  }, [value]);

  useEffect(() => {}, [value]);
  return (
    <>
      {dex && readyToRender && (
        <Autocomplete
          fullWidth
          disablePortal
          value={value}
          options={dex}
          renderInput={(params) => <TextField {...params} label="Dex" />}
          onChange={(e, newValue) => {
            setValue(newValue);
          }}
        />
      )}
    </>
  );
};

export default DexWidget;
