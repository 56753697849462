import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { errorTypeActions } from "../../redux/slices/errorTypeSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import DeleteButton from "../../utils/DeleteButton";
import theme from "../../theme";
import { Button, Grid, Typography } from "@mui/material";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ErrorTypeShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trashModal, setTrashModal] = useState(false);
  const [deleteErrorType, { isSuccess: deleteSuccess }] =
    store.useDeleteErrorTypeMutation();
  const [formData, setFormData] = useState({});
  const errorType = useSelector((state) => state?.errorTypeSlice?.errorType);
  const [getErrorType] = store.useLazyGetErrorTypeShowQuery();
  const [updateErrorType, { isSuccess: updateSuccess }] =
    store.useUpdateErrorTypeMutation();

  const { id } = useParams();
  const errorTypeTrashId = useSelector(
    (state) => state?.errorTypeSlice?.trashId
  );
  const { t } = useTranslation();
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        title: t("name"),
        default: errorType?.name,
      },
    },
    required: ["name"],
  };
  useEffect(() => {
    getErrorType(id);
  }, [id, updateSuccess]);
  useEffect(() => {
    if (errorType) {
      dispatch(
        setBreadCrumbs({
          id: errorType?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [errorType]);
  useEffect(() => {
    return () => {
      dispatch(errorTypeActions.showClean());
    };
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("successfully_updated")));
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (errorTypeTrashId) {
      deleteErrorType(errorTypeTrashId);

      dispatch(errorTypeActions.setErrorTypeTrashId(null));
    }
  }, [errorTypeTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate("/errortypes");
    }
  }, [deleteSuccess]);
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
    };
    updateErrorType({ attributes: attributes, id: id });
  };
  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("error_type_delete_text")}
        title={t("error_type_delete_title")}
        deleteFunc={() => errorTypeActions.setErrorTypeTrashId(id)}
      />
      {errorType && (
        <>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("upd_error_type")}
          </Typography>

          <Box>
            <Form
              schema={schema}
              formData={formData}
              onChange={(changeEvent) => setFormData(changeEvent.formData)}
              onSubmit={onSubmitClick}
              validator={validator}
              uiSchema={uiSchema}
              /* widgets={widgets} */
              showErrorList={false}
              /*   customValidate={customValidate} */
              noHtml5Validate
              children={true}
            />
            <DeleteButton
              name={t("delete")}
              styles={{ mt: 2, float: "right" }}
              onClick={() => setTrashModal(true)}
            />
            {/*    <Button
              variant={window.BUTTON_TYPE}
              disabled={!formData.name}
              onClick={() => setTrashModal(true)}
              sx={{
                backgroundColor: theme.palette.error.main,
                color: "white",
                mt: 2,

                "&:hover": { backgroundColor: theme.palette.error.main },
                float: "right",
              }}
            >
              {t("delete")}
            </Button> */}
            <Button
              variant={window.BUTTON_TYPE}
              disabled={!formData.name}
              onClick={onSubmitClick}
              sx={{ mt: 2, mr: 2, float: "right" }}
            >
              {t("update")}
            </Button>
            <Button
              onClick={() => navigate("/errortypes")}
              variant={window.BUTTON_TYPE}
              sx={{ mt: 2 }}
            >
              {t("back")}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default ErrorTypeShow;
