import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";

import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import ConfirmModal from "./ConfrimModal";
import { Link } from "react-router-dom";
const DistrictTable = ({ perPage, setPage, page }) => {
  const { t } = useTranslation();

  const [trashId, setTrashId] = useState(null);
  const districts = useSelector(
    (state) => state?.deviceDistrictSlice?.districts
  );
  const [trashMoal, setTrashModal] = useState(false);
  const meta = useSelector(
    (state) => state?.deviceDistrictSlice?.districtsMeta
  );
  const onTrashClick = (id) => {
    setTrashId(id);
    setTrashModal(true);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },
    {
      field: "name",
      headerName: t("name"),
      sortable: false,

      flex: 1,
    },
    {
      field: "description",
      headerName: t("description"),
      sortable: false,

      flex: 1,
    },

    {
      field: "creationData",
      headerName: t("inserted_at"),
      sortable: false,

      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onTrashClick(params.value)}
            >
              <DeleteIcon sx={{ ml: 1, fill: theme.palette.error.main }} />
            </IconButton>
            <Link to={`/groups/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.navIcon }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const rows = districts?.map((item) => ({
    id: item?.attributes?.id ?? "--",
    name: item?.attributes?.name ?? "--",
    description: item?.attributes?.description ?? "--",
    creationData: t("dt", { val: new Date(item?.attributes?.inserted_at) }),
    actions: item?.attributes?.id,
  }));
  return (
    <Box sx={{ height: "57vh", mt: 1 }}>
      <ConfirmModal
        trashModal={trashMoal}
        setTrashModal={setTrashModal}
        text={t("district_delete_text")}
        title={t("district_delete_title")}
        trashId={trashId}
      />
      <IwdDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        perPage={perPage}
        setPage={setPage}
        page={page}
        count={meta?.count}
        sort={false}
        height={600}
        tableName={"districts"}
      />
    </Box>
  );
};
export default React.memo(DistrictTable);
