import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Alert,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import LogoMad from "./svgs/LogoMad";
import { store } from "../redux/store";
import { useEffect } from "react";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({ username: "" });
  const [resetError, setResetError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [resetPassword, { isSuccess }] = store.useResetPasswordMutation();
  const handleChange = (e) => {
    const { value } = e.target;
    setUsername(value);
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("Login"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const validate = () => {
    let tempErrors = { username: "" };
    let isValid = true;

    if (!username) {
      tempErrors.username = "Username is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setResetError("");
    setSuccessMessage("");

    if (validate()) {
      try {
        resetPassword({
          username: username,
        });
      } catch (error) {
        console.error("Error occurred:", error);
        setResetError("An error occurred. Please try again later.");
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setSuccessMessage("Password reset link has been sent to your email.");
    }
  }, [isSuccess]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 3,
          backgroundColor: "#f5f5f5",
        }}
      >
        <Grid container justifyContent="center" sx={{ mb: 4 }}>
          <Box component="div">
            <LogoMad /> {/* Логотип */}
          </Box>
        </Grid>
        <Typography
          component="h1"
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Reset Password
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ mb: 3 }}
        >
          Enter your username to receive a password reset link
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            value={username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
          {resetError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {resetError}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
          {!isSuccess && (
            <Button
              type="submit"
              fullWidth
              variant={window.BUTTON_TYPE}
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
          )}
          {isSuccess && (
            <Button
              onClick={() => navigate("/login")}
              fullWidth
              variant={window.BUTTON_TYPE}
              sx={{ mt: 3, mb: 2 }}
            >
              Back to login
            </Button>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPasswordPage;
