import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import NotFoundPage from "../utils/NotfoundPage";
import { useDispatch, useSelector } from "react-redux";
import { hasCommonValue } from "./api_params";
import { ErrorBoundary } from "react-error-boundary";
import IwdFallback from "./IwdFallback";

export const IwdRoute = ({ component, section, path, auth }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const permessions = window.MENU[section] || {};
  const userInfo = useSelector((state) => state?.userPreferenceSlice?.userInfo);
  const roles = useSelector((state) => state?.userPreferenceSlice?.roles);
  const groups = useSelector((state) => state?.userPreferenceSlice?.groups);
  const storageUserInfo = localStorage.getItem("user_info");

  const hasAccess = () => {
    debugger;
    if (section === "login") {
      return true;
    }

    if (permessions.groups?.length > 0) {
      return hasCommonValue(groups, permessions.groups);
    }

    // Если групп нет, проверяем по ролям
    if (!permessions.groups || permessions.groups.length === 0) {
      return hasCommonValue(roles, permessions.roles);
    }

    return false;
  };

  const canRender = permessions.visibility && hasAccess();

  useEffect(() => {
    if (section == "main" && !storageUserInfo) {
      navigate("/login");
    }
  }, [section]);

  return (
    <>
      {canRender ? (
        auth ? (
          <RequireAuth>
            <ErrorBoundary
              resetKeys={[location.pathname]}
              FallbackComponent={IwdFallback}
            >
              {component}
            </ErrorBoundary>
          </RequireAuth>
        ) : (
          <>{component}</>
        )
      ) : (
        userInfo && <NotFoundPage />
      )}
    </>
  );
};
