import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
const UserName = (props) => {
  const { t } = useTranslation();
  useEffect(() => {}, [props]);
  return (
    <>
      <TextField
        label={t("user_name")}
        value={props?.formData && props?.formData}
        InputLabelProps={{ shrink: props?.formData ? true : false }}
        error={props?.rawErrors?.length > 0}
        onChange={(e) => props.onChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {process.env.REACT_APP_AEGIS_USER_NAME_HOST}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default UserName;
