import * as React from "react";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useState, useEffect } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import WsConnector from "../../utils/WsConnector";
import {
  setLiveMessages,
  clearLiveMessages,
} from "../../redux/slices/messageSlice";
import MessagePopover from "./MessagePopover";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import ModalDialog from "../meters/ModalDialog";
import { isMoreThanSecondsAgo } from "../../utils/utilsFunctions";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import Messages from "./Messages";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DraftsIcon from "@mui/icons-material/Drafts";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import theme from "../../theme";
import Button from "@mui/material/Button";

function generate(element) {
  return [0, 1, 2]?.map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const LiveMessages = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [params, setParams] = useState();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [rows, setRows] = useState(null);
  const [messages, setMessages] = useState(null);
  const liveMessages = useSelector((state) => state.messageSlice.liveMessages);
  const liveConfig = window.LIVE;
  const [secondary, setSecondary] = React.useState(false);
  const [timer, setTimer] = useState(false);
  /*  const {
    isWSConnected,
    data: dataMessage,
    error,
    channels,
    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);
  useEffect(() => {
    if (!isWSConnected) {
      dispatch(actions.connect());
    }
  }, [isWSConnected]);
  useEffect(() => {
    if (isWSConnected) {
      dispatch(
        actions.joinChannel({
          name: "message",
        })
      );
    }
  }, [isWSConnected]);
  useEffect(() => {
    if (Object.keys(channels).length > 0) {
      dispatch(
        actions.subscribeChannel({
          channelName: "message",
          eventName: "messages_incoming",
        })
      );
    }
  }, [channels]);
  const showData = dataMessage?.payload?.map((item) => item.values.values);
  useEffect(() => {
    if (dataMessage?.messages_incoming) {
      
      dispatch(setLiveMessages(dataMessage?.messages_incoming));
    }
  }, [dataMessage]); */
  const openModal = (msg) => {
    setMessage(msg);
    setModal(true);
  };
  /*   useEffect(() => {
    setMessages(liveMessages);
    if (liveMessages.length > 0) {
      setTimer(true);
    }
  }, [liveMessages]); */

  /*   useEffect(() => {
    if (timer && liveMessages.length > 0) {
      setTimeout(() => {
        setMessages(messages?.map((item) => ({ ...item, timer: false })));
        setTimer(false);
        
      }, 6000);
    }
  }, [timer, liveMessages]); */
  /*   useEffect(
    () => ,
    [dataMessage, channels]
  ); */
  const closeModal = () => {
    setMessage("");
    setModal(false);
  };
  const clearMessages = () => {
    dispatch(clearLiveMessages());
    setRows(null);
  };
  function getPropertyByPath(obj, path) {
    return path?.split(".")?.reduce((acc, curr) => acc && acc[curr], obj);
  }
  const styleSelector = (params) => {
    /*  setParams(params); */
    /* const datastr = params.row.readTime;
    const date = new Date();
    
    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    }
    if (params?.row?.lastReadingValue == "--") {
      return `super-app-theme--Rejected`;
    } */
    if (params?.row?.timer) {
      return "super-app-theme--Filled";
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true, // Enable sorting by ID
      flex: 3,
    },
    {
      field: "read_time",
      headerName: t("read_time"),
      type: "string",
      sortable: true, // Enable sorting by Name
      editable: false,
      flex: 2,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "type",
      headerName: t("type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "description",
      headerName: t("description"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton sx={{ mt: 0.5, ml: 1 }}>
              <Link to={`/meters/${params?.value.device_id}`} target="blank">
                <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
              </Link>
            </IconButton>
            <IconButton onClick={() => openModal(params?.value.values)}>
              <DraftsIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    setRows(
      liveMessages?.map((item, i) => {
        const message = window.LIVE[item?.values?.class ?? item?.type];
        return {
          id: i,
          read_time: `${t("dt", {
            val: new Date(getPropertyByPath(item, message?.time_path)),
          })}`,
          serial_number: getPropertyByPath(item, message?.serial_number_path),
          type: getPropertyByPath(item, message?.type_path) ?? message?.type,
          description:
            getPropertyByPath(item, message?.description_path) ??
            message?.description,
          actions: {
            device_id: getPropertyByPath(item, message?.device_path),
            values: getPropertyByPath(item, message?.content_path),
          },
          timer: item?.timer,
        };
      })
    );
  }, [liveMessages]);
  useEffect(() => {}, [liveMessages]);
  useEffect(() => {}, [params]);
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ModalDialog open={modal} close={() => closeModal()} title={"Message"}>
          <Box>
            <Typography variant="subtitle2">
              <pre
                style={{
                  overflow: "auto",
                  background: "#c0c0c0",
                  padding: "9px",
                }}
              >
                {JSON.stringify(message, null, 10)}
              </pre>
            </Typography>
          </Box>
        </ModalDialog>
        <Typography variant="h4">{t("live_messages")}</Typography>
        <Box display="flex" alignItems="center">
          <WsConnector
            channelName={"message"}
            eventName={"messages_incoming"}
            setDataFunction={setLiveMessages}
            style={{ mr: 3 }}
          />
          <Tooltip title={t("clear_messages")}>
            <Button variant={window.BUTTON_TYPE} onClick={clearMessages}>
              <DeleteForeverIcon />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Box height={800} sx={{ mt: 4 }}>
        <StyledDataGrid
          getRowClassName={(params) => styleSelector(params)}
          disableColumnMenu
          rows={rows ?? []}
          columns={columns}
          rowCount={liveMessages?.length}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          // loading={isLoadingTable}
          paginationMode="client"
          getRowHeight={() => "auto"}
        />
      </Box>
      <Button
        onClick={() =>
          setMessages(
            liveMessages?.map((item) => ({ ...item, timestamp: true }))
          )
        }
      >
        heh
      </Button>
    </>
  );
};

export default LiveMessages;
