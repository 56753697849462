import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import { Box } from "@mui/material";

const FormAccordion = (props) => {
  return (
    <Box sx={{ width: props.width, mt: 3 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          onClick={props.onClick}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display={"flex"} justifyContent={"flex-start"} width={"100%"}>
            <Typography>{props.title ?? "Search Form"}</Typography>
            {props?.icon && <Box sx={{ ml: 1 }}>{props.icon}</Box>}
          </Box>
        </AccordionSummary>
        <Box
          sx={{ ml: 2, mr: 2, mb: 10, height: props?.height && props?.height }}
        >
          {props.children}
        </Box>
      </Accordion>
    </Box>
  );
};

export default FormAccordion;
