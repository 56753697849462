import { IconButton, Button, Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useTransition } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import { StyledDataGrid } from "../StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import IwdDataGrid from "../IwdDataGrid";
import { useTranslation } from "react-i18next";
import { setBalancType } from "../../redux/slices/balanceSlice";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { useEffect } from "react";
import { setCondomiumShow } from "../../redux/slices/condominiumSlice";
import { setBalanceTrashId } from "../../redux/slices/balanceSlice";
import InfiniteTable from "../InfiniteTable";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setRecords } from "../../redux/slices/balanceSlice";
import theme from "../../theme";
const Balances = () => {
  const dispatch = useDispatch();

  const [deleteBalance, { isSuccess: deleteSuccess }] =
    store.useDeleteBalanceMutation();
  const [trashId, setTrashId] = useState(null);
  const [trashModal, setTrashModal] = useState(false);
  const { id } = useParams();
  const [sorts, setSorts] = useState([]);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const balances = useSelector((state) => state?.balanceSlice?.balances);
  const recorsLength = useSelector(
    (state) => state?.balanceSlice?.recorsLength
  );
  const haveRecords = useSelector((state) => state?.balanceSlice?.haveRecords);
  const count = useSelector((state) => state?.balanceSlice?.balancesCount);
  const balanceTrashId = useSelector((state) => state?.balanceSlice?.trashId);
  const [getBalances, { isFetching }] = store.useLazyGetBalanceIndexQuery();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  useEffect(() => {
    getBalances({
      condominium_id: id,
      page: page,
      per_page: perPage,
      sorts: sorts,
    });
  }, [page, deleteSuccess, sorts]);
  const onDeleteClick = (id) => {
    setTrashId(id);
    setTrashModal(true);
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("Balances"));

    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {
    dispatch(setBalancType(null));
    dispatch(setCondomiumShow(null));
  }, []);
  const columns = [
    {
      field: "name",
      headerName: t("name"),

      sortable: false,
      editable: false,
      flex: 2,
    },

    {
      field: "balance_type",
      headerName: t("balance_type"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "condominium_name",
      headerName: t("condominium"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "address",
      headerName: t("address"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "region",
      headerName: t("region"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "limit",
      headerName: t("limit"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "monthly_value",
      headerName: t("month_balance"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "weekly_value",
      headerName: t("week_balance"),

      sortable: false,
      editable: false,
      flex: 1,
    },

    {
      field: "daily_value",
      headerName: t("day_balance"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "date_last_calculation",
      headerName: t("last_balance_date"),

      sortable: false,
      editable: false,
      flex: 1,
    },

    {
      field: "computed_type",
      headerName: t("type"),

      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.value === true) {
          return (
            <Tooltip title={t("simulated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        } else if (params.value === false) {
          return (
            <Tooltip title={t("calculated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return "--";
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,

      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton
              onClick={() =>
                navigate(
                  `/condominiums/${params?.value?.block_id}/balance/${params?.value.id}`
                )
              }
            >
              <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
            </IconButton>
            <IconButton onClick={() => onDeleteClick(params?.value.id)}>
              <DeleteIcon sx={{ color: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const styleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    if (
      +params?.row?.limit &&
      +params?.row?.day_val &&
      +params?.row?.day_val.toFixed(0) > +params?.row?.limit
    ) {
      return `super-app-theme--Rejected custom-colored`;
    }
  };
  const rows = balances?.map((item) => ({
    id: item?.id,
    name: `${item?.code} - ${item?.name ?? " "}`,

    balance_type: t(item?.balance_type),
    limit: item?.limit ?? "--",
    condominium_name: item?.meta?.block_name,
    address: item?.meta?.address,
    region: item?.meta?.region,
    day_val: item?.meta?.day?.value * 100,

    monthly_value:
      item?.meta?.month?.value !== undefined
        ? `${t("number", {
            val: item?.meta?.month?.value * 100,
            maximumFractionDigits: 3,
          })} %`
        : "--",
    weekly_value:
      item?.meta?.week?.value !== undefined
        ? `${t("number", {
            val: item?.meta?.week?.value * 100,
            maximumFractionDigits: 3,
          })} %`
        : "--",
    daily_value:
      item?.meta?.day?.value !== undefined
        ? `${t("number", {
            val: item?.meta?.day?.value * 100,
            maximumFractionDigits: 3,
          })} %`
        : "--",

    date_last_calculation: t("date_val", {
      val: new Date(item?.meta?.day?.date),
    }),
    computed_type: item?.meta?.day?.computed,
    actions: {
      id: item?.id,
      block_id: item?.condominium_id,
    },
  }));
  useEffect(() => {
    if (balanceTrashId) {
      deleteBalance(balanceTrashId);
    }
  }, [balanceTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfullыy_deleted")));
      setTrashModal(false);
      dispatch(setBalanceTrashId(null));
    }
  }, [deleteSuccess]);

  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("balance_delete_text")}
        title={t("balance_delete_title")}
        deleteFunc={() => setBalanceTrashId(trashId)}
      />

      <Box sx={{ mt: 2 }}>
        <Box sx={{ mb: 1 }} display={"flex"} justifyContent={"flex-end"}>
          <Button onClick={() => navigate("/balances/create")}>
            {t("create_balance")}
          </Button>
        </Box>
        <IwdDataGrid
          columns={columns}
          perPage={perPage}
          setPage={setPage}
          rows={rows}
          page={page}
          count={count}
          setSorts={setSorts}
          styles={styleSelector}
          sorts={sorts}
          height={750}
          tableName={"balances"}
        />
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        sx={{ mt: 2 }}
      ></Box>
    </>
  );
};

export default Balances;
