import Cron from "react-cron-generator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Crone = (props) => {
  const { t } = useTranslation();
  const [crone, setCrone] = useState({
    minute: "0",
    hour: "*/1",
    mounthDay: "*",
    month: "*",
    weekDay: "*",
  });
  const [res, setRes] = useState("Crone");
  const [result, setResult] = useState();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    props.onChange(Object.values(crone).join().replaceAll(",", " "));
  }, [crone]);

  useEffect(() => {}, [res]);

  useEffect(() => {}, [crone]);
  const resetValues = () => {
    setCrone({
      minute: "0",
      hour: "*/1",
      mounthDay: "*",
      month: "*",
      weekDay: "*",
    });
    setRes(
      `${t("Every")}  ${t("hoursTotal", {
        count: parseInt(crone.hour.substring(2)),
      })}`
    );
  };
  useEffect(() => {
    if (
      crone.minute === "*" &&
      crone.hour === "*" &&
      crone.mounthDay === "*" &&
      crone.month === "*" &&
      crone.weekDay === "*"
    ) {
      setRes(t("everyMin"));
    }

    if (
      crone.hour === "*" &&
      crone.minute !== "*" &&
      crone.weekDay === "*" &&
      crone.month &&
      crone.mounthDay === "*"
    ) {
      setRes(`${"everyHourAtMin"} ${crone.minute}`);
    }
    if (
      crone.hour === "*" &&
      crone.minute !== "*" &&
      crone.minute.includes("*") &&
      crone.weekDay === "*" &&
      crone.month &&
      crone.mounthDay === "*"
    ) {
      setRes(
        `${t("Every")} ${t("minutesTotal", {
          count: parseInt(crone.minute.substring(2)),
        })}`
      );
    }
    if (
      crone.hour !== "*" &&
      crone.minute !== "*" &&
      crone.weekDay === "*" &&
      crone.month &&
      crone.mounthDay === "*"
    ) {
      setRes(
        `${t("atMin")} ${crone.minute} ${t("every")} ${t("hoursTotal", {
          count: parseInt(crone.hour.substring(2)),
        })}`
      );
    }

    if (
      crone.mounthDay !== "*" &&
      crone.minute == 0 &&
      crone.hour == 0 &&
      crone.month === "*" &&
      crone.weekDay === "*"
    ) {
      setRes(
        `${t("Every")}  ${t("days", {
          count: parseInt(crone.mounthDay.substring(2)),
        })}`
      );
    }
    if (
      crone.hour !== "*" &&
      (crone.minute == 0 || crone.minute === "*") &&
      crone.weekDay === "*" &&
      crone.month === "*" &&
      crone.mounthDay === "*"
    ) {
      setRes(
        `${t("Every")}  ${t("hoursTotal", {
          count: parseInt(crone.hour.substring(2)),
        })}`
      );
    }
  }, [crone.minute, crone.hour, crone.weekDay, crone.month, crone.mounthDay]);
  useEffect(() => {}, [result]);
  return (
    /*     <div>
      <Cron
        value={crone}
        onChange={(value, result) => {
          setCrone(value);
          setResult(result);
        }}
      />
      <p>{result}</p>
    </div> */
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {`${t("push_scheduler")} : ${res}`}
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            {/*   <Tab label={t("minutes")} onClick={resetValues} /> */}
            <Tab label={t("hours")} onClick={resetValues} />
            <Tab label={t("days")} onClick={resetValues} />
          </Tabs>
          {/* <TabPanel value={value} index={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 1 }}>Every</Typography>
              <TextField
                type="number"
                size="small"
                onChange={(e) =>
                  setCrone((prev) => ({
                    ...prev,
                    minute: e.target.value ? `${e.target.value}` : "*",
                  }))
                }
              />
              <Typography sx={{ ml: 1 }}>minute(s)</Typography>
            </Box>
              </TabPanel> */}
          <TabPanel value={value} index={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 1 }}>Every</Typography>
              <TextField
                type="number"
                size="small"
                onChange={(e) =>
                  setCrone((prev) => ({
                    ...prev,
                    hour: e.target.value ? `*/${e.target.value}` : "*",
                    /*   minute: crone.minute === "*" ? "0" : crone.minute, */
                    minute: e.target.value ? "0" : "*",
                  }))
                }
              />
              <Typography sx={{ ml: 1 }}>hour(s)</Typography>
              <TextField
                type="number"
                value={crone.minute && crone.minute !== "*" ? crone.minute : ""}
                size="small"
                sx={{ ml: 1 }}
                onChange={(e) =>
                  setCrone((prev) => ({
                    ...prev,
                    minute: e.target.value ? `${e.target.value}` : "*",
                  }))
                }
              />
              <Typography sx={{ ml: 1 }}>minute(s)</Typography>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 1 }}>Every</Typography>
              <TextField
                type="number"
                size="small"
                onChange={(e) =>
                  setCrone((prev) => ({
                    ...prev,
                    minute: e.target.value ? "0" : "*",
                    hour: e.target.value ? "0" : "*",
                    mounthDay: e.target.value ? `*/${e.target.value}` : "*",
                  }))
                }
              />
              <Typography sx={{ ml: 1 }}>day(s)</Typography>
            </Box>
          </TabPanel>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Crone;
