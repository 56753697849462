import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/slices/iwdWebSocketSlice";
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import WifiIcon from "@mui/icons-material/Wifi";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { setErrors } from "../redux/slices/errorSlice";
import { loadingOff } from "../redux/slices/loadingSlice";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { set } from "lodash";
const WsConnector = ({
  channelName,
  channelParams,
  eventName,
  setDataFunction,
  hiden,
  style,
  manualClean = false,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    isWSConnected,
    data,
    error,
    channels,
    cashedData,

    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);
  const [readyForClean, setReadyForClean] = useState(false);
  const [localConnect, setLocalConnect] = useState(false);
  const debouncedAction = useDebouncedCallback(() => {
    dispatch(actions.setData(cashedData));
  }, 2000);
  useEffect(() => {
    if (!isWSConnected) {
      dispatch(actions.connect());
    }
    setLocalConnect(true);
  }, [isWSConnected]);
  useEffect(() => {
    if (error) {
      dispatch(setErrors({ error: error }));
    }
  }, [error]);
  useEffect(() => {
    if (isWSConnected && localConnect) {
      dispatch(
        actions.joinChannel({
          name: channelName,
          params: channelParams,
        })
      );
    }
  }, [isWSConnected, localConnect]);
  useEffect(() => {
    if (Object.keys(channels).length > 0) {
      dispatch(
        actions.subscribeChannel({
          channelName: channelName,
          eventName: eventName,
        })
      );
    }
  }, [channels]);

  useEffect(() => {
    if (channelName == "channelName") {
      dispatch(actions.setData(cashedData[0]));
    }
    if (cashedData?.length > 3) {
      dispatch(actions.setData(cashedData));
    } else if (cashedData.length > 0) {
      debouncedAction();
    }
  }, [cashedData]);
  useEffect(() => {
    if (data?.[eventName]?.length > 0 && setDataFunction && isWSConnected) {
      dispatch(setDataFunction(data?.[eventName]));
      setReadyForClean(true);
    }
  }, [data?.[eventName]]);

  useEffect(() => {
    return () => {
      if (channels?.[channelName] && !manualClean) {
        dispatch(actions.cleanData(eventName));
        dispatch(actions.leaveChannel(channelName));
      }
    };
  }, []);

  return (
    <>
      {!hiden && (
        <>
          {isWSConnected ? (
            <Tooltip title={`${t("webSocket")}: ${t("connected")} `}>
              <WifiIcon sx={style} />
            </Tooltip>
          ) : (
            <Tooltip title={`${t("webSocket")}: ${t("no_connection")}`}>
              <WifiOffIcon sx={style} />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default WsConnector;
