import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  identifierTypeValue: null,
  user: null,
  blockName: null,
  gatewaySerial: null,
  breadcrumbs: null,
  readyToShow: false,
};

const breadCrumbmeterSlice = createSlice({
  name: "breadCrumbmeterSlice",
  initialState,
  reducers: {
    setidentifierTypeValue(state, action) {
      state.identifierTypeValue = action.payload;
    },
    unsetValue(state) {
      state.identifierTypeValue = null;
    },
    setUserName(state, action) {
      state.user = action.payload;
    },
    setBlockName(state, action) {
      state.blockName = action.payload;
    },
    setGwSerial(state, action) {
      state.gatewaySerial = action.payload;
    },
    setBreadCrumbs(state, action) {
      state.breadcrumbs = action.payload;
      state.readyToShow = false;
    },
    setReadyToShow(state) {
      state.readyToShow = true;
    },
  },
});
export const {
  setidentifierTypeValue,
  unsetValue,
  setUserName,
  setBlockName,
  setGwSerial,
  setBreadCrumbs,
  setReadyToShow,
} = breadCrumbmeterSlice.actions;
export default breadCrumbmeterSlice.reducer;
