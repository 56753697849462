import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { Grid, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import MapLeaflet from "./MapLeaflet";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { setMeterTrashId } from "../../redux/slices/meterSlice";
import PasswordField from "material-ui-password-field";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setUpdateMode } from "../../redux/slices/meterSlice";
import { useDispatch, useSelector } from "react-redux";
import { nameTransform } from "../../utils/utilsFunctions";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useNavigate } from "react-router-dom";
import Input from "@mui/material/Input";
import {
  setMeterIdToUpdate,
  setMeterShow,
} from "../../redux/slices/meterSlice";
import theme from "../../theme";
const Registri = ({
  result,
  id,
  enteredFromCondominium,
  impulsiveMeterId,
  lora = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteMeter, { isSuccess: deleteSuccess, error: deleteError }] =
    store.useDeleteMeterMutation();

  const [modal, setModal] = useState(false);
  const meterTrashId = useSelector((state) => state?.meterSlice?.trashId);
  const meterId = useSelector(
    (state) => state.meterSlice?.meterShow?.attributes?.identifier
  );

  const [trashId, setTrashId] = useState(null);
  const [meterType, setMeterType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const fields = result?.data?.data?.attributes?.fields;
  const [trigger, res, lastPromiseInfo] = store.useLazyGetDataQuery();
  const filteredType = res?.data?.data?.attributes?.filter(
    (item) => item.value == fields?.type
  );
  const MeterIdToUpdate = useSelector(
    (state) => state?.meterSlice?.MeterIdToUpdate
  );
  const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const type = result?.data?.data?.attributes?.fields?.type;
  const offMap =
    fields.coordinates?.coordinates[0] == 0 &&
    fields.coordinates?.coordinates[1] == 0;
  useEffect(() => {}, [offMap]);
  useEffect(() => {
    switch (type) {
      case "08":
      case "8":
        setMeterType("heatCost");
        break;
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        setMeterType("waterMeter");
        break;
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        setMeterType("colorMeter");
        break;
    }
  }, [type]);
  useEffect(() => {}, [meterType]);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Обрезаем путь до нужной точки

  useEffect(() => {
    if (deleteError) {
      dispatch(setMeterTrashId(null));
    }
  }, [deleteError]);
  useEffect(() => {
    dispatch(setMeterShow(result.data.data));
  }, [result.data.data]);
  const onUpdateClick = () => {
    if (!enteredFromCondominium) {
      dispatch(setMeterIdToUpdate(id));
      dispatch(setUpdateMode("default"));
      dispatch(setMeterShow(result.data.data));
      navigate("edit");
    }
    if (enteredFromCondominium) {
      localStorage.setItem("meterShow", JSON.stringify(result.data.data));
      window.open(`/meters/${MeterIdToUpdate}/edit`, "_blank");
    }
  };
  const onTrashClick = () => {
    setTrashId(id);
    setModal(true);
  };

  const meterTypeWrapper = (type) => {
    switch (type) {
      case "Frigorie":
        return t("frigories");
      case "Calorie":
        return t("calories");
      case "CALORIE/FRIGORIE":
      case "CALORIE / FRIGORIE":
        return t("cal_fri");
      case "RIPARTITORE":
        return t("distributor");
      default:
        return type;
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems="center"
          rowSpacing={5}
          direction="row"
          spacing={5}
          xs={offMap ? 12 : 8}
        >
          <Grid item xs={3}>
            <Typography style={{ width: "100%" }} variant="subtitle2">
              {t("key")} :
            </Typography>
            <Input
              style={{ width: "100%" }}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={fields?.key ?? "--"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("class")} :</Typography>
            <Typography variant="subtitle1">
              {nameTransform(
                result?.data?.data?.attributes?.fields?.class ===
                  "lorawan_device"
                  ? "LoRaWAN"
                  : result?.data?.data?.attributes?.fields?.class
              ) ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t("digital_twin_name")} :
            </Typography>
            <Typography variant="subtitle1">
              {result?.data?.data?.attributes?.fields?.digital_twin_name ??
                "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("serial")} :</Typography>
            <Typography variant="subtitle1">
              {result?.data?.data?.attributes?.fields?.serial ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t(result?.data?.data?.attributes?.fields?.identifier_type)}
            </Typography>
            <Typography>
              {result?.data?.data?.attributes?.fields?.[key]}
            </Typography>
          </Grid>
          {fields?.meter_type && (
            <Grid item xs={3}>
              <Typography variant="subtitle2">{t("meter_type")} :</Typography>
              <Typography variant="subtitle1">
                {Array.isArray(fields?.meter_type) ? (
                  fields?.meter_type?.map((item) => (
                    <Chip label={meterTypeWrapper(item)} />
                  ))
                ) : (
                  <Chip label={meterTypeWrapper(fields?.meter_type)} />
                )}
              </Typography>
            </Grid>
          )}
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("coordinates")} :</Typography>
            <Typography variant="subtitle1">{`Lat : ${fields?.coordinates?.coordinates[1]}`}</Typography>
            <Typography variant="subtitle1">{`Lng : ${fields?.coordinates?.coordinates[0]}`}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t("instalation_data")} :{" "}
            </Typography>
            <Typography variant="subtitle1">
              {t("dt", {
                val: new Date(result?.data?.data?.attributes?.inserted_at),
              })}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t("replacement_date")} :
            </Typography>
            <Typography variant="subtitle1">
              {t("dt_m", {
                val: new Date(
                  result?.data?.data?.attributes?.meta_data?.replace_date
                ),
              })}
            </Typography>
            <Typography variant="subtitle2">{`${t("last_prev_val")}: ${
              result?.data?.data?.attributes?.meta_data
                ?.last_previous_reading_value
                ? result?.data?.data?.attributes?.meta_data
                    ?.last_previous_reading_value
                : "-"
            } `}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("pod")} : </Typography>
            <Typography variant="subtitle1">{fields?.pod ?? "--"}</Typography>
          </Grid>
          {/*  <Grid item xs={3}>
            <Typography variant="subtitle2">{t("diameter")} : </Typography>
            <Typography variant="subtitle1">
              {fields?.diameter ?? "--"}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("street_address")} :</Typography>
            <Typography variant="subtitle1">
              {fields?.address ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("type")} : </Typography>
            <Typography variant="subtitle1">
              {filteredType?.[0]?.name ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("notes")} : </Typography>
            <Typography variant="subtitle1">{fields?.note ?? "--"}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t("configuration_date")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields?.placing_data_utc
                ? t("dt", {
                    val: new Date(fields?.placing_data_utc),
                  })
                : "--"}
            </Typography>
          </Grid>

          {(meterType === "waterMeter" || meterType === "heatCost") && (
            <Grid item xs={3}>
              <Typography variant="subtitle2">
                {t("last_reading_value")} :
              </Typography>
              <Typography variant="subtitle1">
                {fields?.last_reading
                  ? `${t("number", {
                      val:
                        fields?.type == "08"
                          ? fields?.last_reading?.value?.toFixed(0)
                          : fields?.last_reading?.value,
                    })} ${
                      fields?.last_reading?.unit &&
                      fields?.last_reading?.unit !== "J"
                        ? fields?.last_reading?.unit == "m^3"
                          ? "m³"
                          : fields?.last_reading?.unit
                        : ""
                    } ${
                      fields?.last_reading?.units &&
                      fields?.last_reading?.units !== "J"
                        ? fields?.last_reading?.units == "m^3"
                          ? "m³"
                          : fields?.last_reading?.units
                        : ""
                    }  `
                  : "--"}
              </Typography>
              <Typography variant="subtitle2">
                {fields?.last_reading
                  ? t("dt", {
                      val: new Date(fields?.last_reading?.datetime_utc),
                    })
                  : "--"}
              </Typography>
            </Grid>
          )}
          {meterType === "colorMeter" && (
            <>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {t("last_reading_heat_val")} :
                </Typography>
                <Typography variant="subtitle1">
                  {fields?.heat_last_reading?.value
                    ? fields?.heat_last_reading?.unit === "Wh"
                      ? `${t("number", {
                          val: fields?.heat_last_reading?.value / 1000,
                        })} ${"kWh"} `
                      : `${t("number", {
                          val: fields?.heat_last_reading?.value,
                        })} ${fields?.heat_last_reading?.unit} `
                    : "--"}
                </Typography>
                <Typography variant="subtitle2">
                  {fields?.heat_last_reading?.value
                    ? t("dt", {
                        val: new Date(fields?.heat_last_reading?.datetime_utc),
                      })
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {t("last_reading_cold_val")} :
                </Typography>
                <Typography variant="subtitle1">
                  {fields?.cold_last_reading?.value
                    ? fields?.cold_last_reading?.unit === "Wh"
                      ? `${t("number", {
                          val: fields?.cold_last_reading?.value / 1000,
                        })} ${"kWh"} `
                      : `${t("number", {
                          val: fields?.cold_last_reading?.value,
                        })} ${fields?.cold_last_reading?.unit} `
                    : "--"}
                </Typography>
                <Typography variant="subtitle2">
                  {fields?.cold_last_reading?.value
                    ? t("dt", {
                        val: new Date(fields?.cold_last_reading?.datetime_utc),
                      })
                    : "--"}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("time_zone")} :</Typography>
            <Typography variant="subtitle1">
              {/* {fields?.time_zone ?? "--"} */}
              Europe/Rome
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("battery_level")} :</Typography>
            <Typography variant="subtitle1">
              {fields?.battery_level
                ? `${fields?.battery_level?.value} ${fields?.battery_level?.unit}`
                : "--"}
            </Typography>
            <Typography variant="subtitle2">
              {fields?.battery_level
                ? t("dt", {
                    val: new Date(fields?.battery_level?.datetime_utc),
                  })
                : "--"}
            </Typography>
          </Grid>
          {meterType === "waterMeter" && (
            <Grid item xs={3}>
              <Typography variant="subtitle2">
                {t("mounted_upside_down")} :
              </Typography>
              <Typography variant="subtitle1">
                {fields?.reverse === true ? "True" : "False"}
              </Typography>
            </Grid>
          )}
          {meterType === "colorMeter" && (
            <Grid item xs={3}>
              <Typography variant="subtitle2">{t("installed")} :</Typography>
              <Typography variant="subtitle1">
                {fields?.reverse === true ? "Mandata" : "Ritorno"}
              </Typography>
            </Grid>
          )}
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t("alarm_list")} :</Typography>
            <Typography variant="subtitle1">
              {fields?.alarms
                ? fields?.alarms?.map((item) => (
                    <Chip color="error" sx={{ mt: 1 }} label={item} />
                  ))
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t("associated_groups")} :
            </Typography>
            <Typography variant="subtitle1">--</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t("associated_districts")} :
            </Typography>
            <Typography variant="subtitle1">--</Typography>
          </Grid>
          {fields?.room && (
            <Grid item xs={3}>
              <Typography variant="subtitle2">{t("room")} :</Typography>
              <Typography variant="subtitle1">{fields?.room}</Typography>
            </Grid>
          )}
          {fields?.valve_status_v2 && (
            <Grid item xs={3}>
              <Typography variant="subtitle2">{t("valve_status")} :</Typography>

              <Box width={"100%"} display={"flex"}>
                {fields?.valve_status_v2?.map((item) => (
                  <Chip sx={{ ml: 1, mt: 1 }} label={t(item)} />
                ))}
              </Box>
            </Grid>
          )}

          {fields?.valve_opening_angle &&
            fields?.valve_opening_angle !== null && (
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {t("valve_opening_angle")} :
                </Typography>
                <Typography variant="subtitle1">
                  {fields.valve_opening_angle}
                </Typography>
              </Grid>
            )}
          <Grid item xs={3}>
            <Typography variant="subtitle2">{t(`security`)} :</Typography>

            {fields?.security?.acl?.map((item) => (
              <Box display={"flex"} sx={{ mt: 1 }}>
                <Typography>{item.name} </Typography>
                {item?.scopes?.map((item) => (
                  <Chip label={item} sx={{ ml: 2 }} />
                ))}
              </Box>
            ))}
          </Grid>
        </Grid>
        {!offMap && (
          <Grid container xs={4} sx={{ ml: 4 }}>
            <Grid item xs={12}>
              <Box>
                <MapLeaflet
                  pos={result?.data?.data?.attributes?.fields?.coordinates}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* {!impulsiveMeterId && !lora && (
        <IwdAuthWrapper
          children={
            <Button
              variant={window.BUTTON_TYPE}
              sx={{  mt: 6 }}
              onClick={onUpdateClick}
            >
              {t("upd_meter")}
            </Button>
          }
          section={"upd_meter_btn"}
        />
      )}
      <IwdAuthWrapper
        section={"delete_meter"}
        children={
          <Button
            onClick={onTrashClick}
            sx={{
              float: "right",
              mt: 7.5,
              background: theme.palette.error.main,
              "&:hover": {
                background: theme.palette.error.main,
              },
            }}
          >
            {t("delete_meter")}
          </Button>
        }
      /> */}
      {lora && (
        <Button
          sx={{ mt: 2 }}
          onClick={() =>
            window.open("http://trilliant.altioriot.com:8081/admin", "_blank")
          }
          variant={window.BUTTON_TYPE}
        >
          {t("go_to_server")}
        </Button>
      )}
    </>
  );
};

export default Registri;
