/**
 * This function take like a input a GeoJson polygon object and change the coordiantes order.
 * It change from [lat lng] to [lng lat]
 */
export const geoJsonPolygonSwapCoordinates = (obj) => {
  debugger;
  if (obj.type == "Polygon") {
    const newCoordinates = obj.coordinates?.map((coords) =>
      coords?.map((coordinate) => [coordinate[1], coordinate[0]])
    );
    return { coordinates: newCoordinates, type: obj.type };
  }
  return obj;
};
