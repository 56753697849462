import React, { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { actions } from "../redux/slices/authSlice";
import { useAuth } from "./AuthProvider";
import { useDispatch, useSelector } from "react-redux";
const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let auth = useAuth();
  const logged = useSelector((state) => state?.authSlice?.logged);

  useEffect(() => {
    if (logged == false) {
      navigate("/login");
    }
  }, [logged]);

  return <> {children} </>;
};

export default RequireAuth;
