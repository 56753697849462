import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { notesActions } from "../../redux/slices/notesSlice";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { setMessage } from "../../redux/slices/snackbarSlice";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LongNameWrapper from "../../utils/LongNameWrapper";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
const GatewayNotes = ({ idFromCondominium }) => {
  const navigate = useNavigate();
  const gatewayId = useSelector((state) => state?.gatewaySlice?.gatewayId);
  const dispatch = useDispatch();
  const noteTrashId = useSelector((state) => state?.notesSlice?.noteTrashId);
  const [modal, setModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const [page, setPage] = useState(1);
  const count = useSelector((state) => state?.notesSlice?.notesCount);
  const [perPage, setPerPage] = useState(10);
  const notes = useSelector((state) => state?.notesSlice?.notes);
  const { t } = useTranslation();
  const { id } = useParams();
  const [getNotes] = store.useLazyGetNotesQuery();
  const [deleteNotes, { isSuccess: deleteSuccess }] =
    store.useDeleteNoteMutation();
  useEffect(() => {
    if (!notes) {
      getNotes({ device_id: gatewayId });
    }
  }, [notes, id]);
  const onTrashClick = (id) => {
    setTrashId(id);
    setModal(true);
  };
  useEffect(() => {
    if (noteTrashId) {
      deleteNotes(noteTrashId);
    }
  }, [noteTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      getNotes({ device_id: gatewayId });
      dispatch(notesActions.setNoteTrashId(null));
    }
  }, [deleteSuccess]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "inserted_at",
      headerName: t("inserted_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    /*    {
      field: "updated_at",
      headerName: t("updated_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    }, */
    {
      field: "note",
      headerName: t("note"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 4,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"}>
            <LongNameWrapper
              text={params?.value}
              size={"100%"}
              /*   variant={"subtitle2"} */
              maxLength={200}
            />
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton
              onClick={() =>
                navigate(`/gateways/${gatewayId}/note/${params?.value}`)
              }
            >
              <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
            </IconButton>
            <IconButton onClick={() => onTrashClick(params?.value)}>
              <DeleteIcon sx={{ fill: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = notes?.map((note) => ({
    id: note?.id,
    note: note?.attributes?.note,
    inserted_at: t("dt", { val: new Date(note.attributes?.inserted_at) }),
    updated_at: t("dt", { val: new Date(note.attributes?.updated_at) }),
    actions: note?.id,
  }));
  return (
    <Box height={580}>
      <DeleteConfirmModal
        trashModal={modal}
        setTrashModal={setModal}
        text={t("note_delete_text")}
        title={t("note_delete_title")}
        deleteFunc={() => notesActions.setNoteTrashId(trashId)}
      />
      <IwdDataGrid
        rows={rows ?? []}
        columns={columns}
        perPage={perPage}
        setPage={setPage}
        page={page}
        count={count}
        paginationMode={"server"}
        sort={false}
        height={580}
        tableName={"gateway_notes"}
      />
      {/*  <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        rowCount={count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        getRowHeight={() => "auto"}
      /> */}
      {!idFromCondominium && (
        <Button
          onClick={() => navigate("createnote")}
          variant={window.BUTTON_TYPE}
          sx={{ mt: 2, float: "right" }}
        >
          {t("create_note")}
        </Button>
      )}
    </Box>
  );
};
export default GatewayNotes;
