import { useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { CondominiumTree } from "./CondominiumTree";
import { store } from "../../redux/store";
import { setAppIndex } from "../../redux/slices/condominiumSlice";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import {
  setTreeItem,
  setBtnDetector,
} from "../../redux/slices/condominiumSlice";
import ShowAppMeter from "../metrics/ShowAppMeter";
import MeterShow from "../metrics/MeterShow";
import { debug } from "util";
import { setAppMeter } from "../../redux/slices/meterSlice";
const ApartamentMterShow = () => {
  const dispatch = useDispatch();

  const { id, subCondominumName, apartmentName, serial } = useParams();
  const [getCondominiumShow] = store.useLazyCondominiumShowQuery();
  /*   const meterUpdatedFromCondominium = localStorage.getItem(
    "meterUpdatedFromCondominium"
  ); */
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const condominiumTreeData = useSelector(
    (state) => state?.condominiumSlice?.condominiumTreeData
  );
  const subcondominium = useMemo(() => {
    return (condominiumShow?.children?.filter(
      (c) => c.attributes?.name === decodeURIComponent(subCondominumName)
    ) ?? [])[0];
  }, [condominiumShow, subCondominumName]);
  const apartment = useMemo(() => {
    return (subcondominium?.children?.filter(
      (c) => c.attributes?.name === decodeURIComponent(apartmentName)
    ) ?? [])[0];
  }, [apartmentName, subcondominium]);
  const meter = useMemo(() => {
    return (apartment?.children?.filter(
      (c) => c.attributes?.serial === serial
    ) ?? [])[0];
  }, [apartment, serial]);
  useEffect(() => {
    if (id && !condominiumShow) {
      getCondominiumShow(id);
    }
  }, [id, condominiumShow]);

  useEffect(() => {
    dispatch(setBtnDetector(5));
  }, []);
  useEffect(() => {
    dispatch(setTreeItem(serial));
  }, []);
  useEffect(() => {}, [meter]);
  useEffect(() => {}, [meter]);
  useEffect(() => {
    if (meter) {
      dispatch(setAppMeter(meter));
    }
  }, [meter]);
  useEffect(() => {
    if (meter) {
      debugger;
      dispatch(
        setBreadCrumbs({
          id: condominiumShow?.name,
          subCondominumName: subcondominium?.attributes?.name,
          apartmentName: apartment?.attributes?.name,
          serial: meter?.attributes?.serial,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [meter]);
  return (
    <>
      {
        <Typography sx={{ mb: 4 }} variant="h4">
          {meter?.attributes?.serial}
        </Typography>
      }
      <Grid container>
        <Grid item xs={2}>
          <Box sx={{ display: "flex", justifyContent: "end", ml: 2 }}>
            {condominiumShow && (
              <CondominiumTree
                condominium={condominiumTreeData}
                subcondominiumName={subCondominumName}
                apartmentName={apartmentName}
                serial={meter?.attributes?.serial}
                apartment={apartment}
                subcondominium={subcondominium}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={9} sx={{ ml: 2 }}>
          {meter && (
            <MeterShow
              apartamentMeterId={meter?.attributes?.identifier}
              enteredFromCondominium={true}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default ApartamentMterShow;
