import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DoneIcon from "@mui/icons-material/Done";
import theme from "../../theme";
import CustomSnackbar from "../../utils/Snackbars";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
const StatisticParametersHandler = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const statistic = useSelector((state) => state?.statisticsSlice?.statistic);
  const statisticUpdating = useSelector(
    (state) => state?.statisticsSlice?.updating
  );
  const [showPasswordMap, setShowPasswordMap] = useState({});
  const isUpdating = useSelector((state) => state?.statisticsSlice?.updating);
  const [parameters, setParameters] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [parameterKey, setParameterKey] = useState("");
  const [parameterType, setParameterType] = useState("");
  const [open, setOpen] = useState(false);
  const [parameterValue, setParameterValue] = useState("");
  const [saved, setSaved] = useState(false);
  const [addParam, setAddParam] = useState(false);

  const patamTypeHandler = (param, type) => {
    switch (type) {
      case "string":
        return param;
      case "integer":
        return +param;
      case "float":
        return parseFloat(param);
    }
  };
  const isNotConvertibleToIntOrFloat = (value) => {
    return isNaN(parseFloat(value)) || !isFinite(value);
  };

  const transformParams = parameters?.map((item) => ({
    [item[0]]: item[2] ? patamTypeHandler(item[1], item[2]) : item[1],
  }));
  const transformedParams = Object.assign({}, ...transformParams);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  let readyToSave = parameterType && parameterType && parameterKey;
  const toggleShowPassword = (parameterKey) => {
    setShowPasswordMap((prevShowPasswordMap) => ({
      ...prevShowPasswordMap,
      [parameterKey]: !prevShowPasswordMap[parameterKey],
    }));
  };
  const onSaveClick = (props) => {
    if (parameterKey !== "" && parameterValue !== "") {
      setParameters((prev) => [
        ...prev,
        [parameterKey, parameterValue, parameterType],
      ]);
      setParameterKey("");
      setParameterValue("");
      setParameterType("");
    }
    dispatch(setMessage(t("saved_params")));
    setAddParam(false);

    setSaved(true);
  };
  useEffect(() => {
    if (statistic) {
      setParameters(Object.entries(statistic.connection_parameters));
    }
  }, [statistic]);
  useEffect(() => {
    if (!statisticUpdating) {
      setParameters([]);
    }
  }, [statisticUpdating]);
  useEffect(() => {
    if (saved || (isUpdating && parameters.length > 0)) {
      props.onChange(transformedParams);
      setSaved(false);
    }
  }, [saved, isUpdating, parameters.length]);

  const onAddClick = () => {
    if (parameterKey !== "" && parameterValue !== "") {
      setParameters((prev) => [...prev, [parameterKey, parameterValue]]);
      setParameterKey("");
      setParameterValue("");
    }
    if (!addParam) {
      setAddParam(true);
    }
  };
  const onDeleteClick = (index) => {
    setParameters((prev) => prev?.filter((item, i) => i !== index));
    setSaved(true);
  };
  useEffect(() => {}, [parameters]);

  const handleChange = (event) => {
    setParameterType(event.target.value);
  };
  useEffect(() => {
    setParameterType("");
  }, [parameterValue]);
  function identifyValueType(value) {
    if (typeof value === "number" && Number.isInteger(value)) {
      return "integer";
    }

    if (typeof value === "number" && !Number.isInteger(value)) {
      return "float";
    }

    if (typeof value === "string") {
      return "string";
    }

    return "unknown";
  }

  // Примеры использования
  // 'integer'
  // 'float'
  // 'string-integer'
  // 'string-float'
  // 'string'
  // 'unknown'
  // 'unknown'
  // 'unknown'

  return (
    <>
      {isUpdating ? (
        statistic && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t("connection_parameters")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {parameters?.map((item, i) => (
                <Grid container>
                  <Grid item xs={4} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      label="key"
                      value={item[0]}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      type={showPasswordMap[item[0]] ? "text" : "password"}
                      label="value"
                      value={item[1]}
                    />
                  </Grid>
                  <Grid item xs={0.5} sx={{ mt: 3, mr: 3 }}>
                    <Tooltip /* title={t("delete_param")} */>
                      <IconButton onClick={() => toggleShowPassword(item[0])}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      label={t("type")}
                      value={identifyValueType(item[1])}
                    />
                  </Grid>
                  <Grid item xs={0.5} sx={{ mt: 3 }}>
                    <Tooltip title={t("delete_param")}>
                      <IconButton onClick={() => onDeleteClick(i)}>
                        <DeleteIcon sx={{ color: theme.palette.error.main }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
              {(addParam || parameters.length == 0) && (
                <>
                  <Grid container>
                    <Grid item xs={5} sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: "95%" }}
                        error={props?.rawErrors?.length > 0 && !parameterKey}
                        label="key"
                        value={parameterKey}
                        onChange={(e) => setParameterKey(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5} sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: "95%" }}
                        label="value"
                        error={props?.rawErrors?.length > 0 && !parameterValue}
                        value={parameterValue}
                        onChange={(e) => setParameterValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={parameterType}
                          label={t("type")}
                          onChange={handleChange}
                          disabled={!parameterValue}
                        >
                          <MenuItem value={"string"}>String</MenuItem>
                          <MenuItem
                            value={"integer"}
                            disabled={isNotConvertibleToIntOrFloat(
                              parameterValue
                            )}
                          >
                            Integer
                          </MenuItem>
                          <MenuItem
                            value={"float"}
                            disabled={isNotConvertibleToIntOrFloat(
                              parameterValue
                            )}
                          >
                            Float
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
              <Box
                display={"flex"}
                justifyContent="space-between"
                sx={{ mt: 1, width: "100%" }}
              >
                <Tooltip title={t("add_param")}>
                  <IconButton
                    onClick={onAddClick}
                    disabled={
                      (parameterKey == "" || parameterValue == "") &&
                      addParam == true
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("save_params")}>
                  <IconButton
                    disabled={
                      !parameterKey || !parameterValue || !parameterType
                    }
                    onClick={onSaveClick}
                    sx={{ mr: 2 }}
                  >
                    <DoneIcon
                      sx={{ color: readyToSave && theme.palette.success.main }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t("connection_parameters")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parameters?.map((item, i) => (
              <Grid container>
                <Grid item xs={4} sx={{ mt: 2 }}>
                  <TextField
                    sx={{ width: "95%" }}
                    label="key"
                    value={item[0]}
                  />
                </Grid>
                <Grid item xs={4} sx={{ mt: 2 }}>
                  <TextField
                    sx={{ width: "95%" }}
                    label="value"
                    type={showPasswordMap[item[0]] ? "text" : "password"}
                    value={item[1]}
                  />
                </Grid>
                <Grid item xs={0.5} sx={{ mt: 3, mr: 3 }}>
                  <Tooltip /* title={t("delete_param")} */>
                    <IconButton onClick={() => toggleShowPassword(item[0])}>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={2} sx={{ mt: 2 }}>
                  <TextField
                    sx={{ width: "95%" }}
                    label={t("type")}
                    value={item[2]}
                  />
                </Grid>
                <Grid item xs={0.5} sx={{ mt: 3 }}>
                  <Tooltip title={t("delete_param")}>
                    <IconButton onClick={() => onDeleteClick(i)}>
                      <DeleteIcon sx={{ color: theme.palette.error.main }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
            {(addParam || parameters.length == 0) && (
              <>
                <Grid container>
                  <Grid item xs={5} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      error={props?.rawErrors?.length > 0 && !parameterKey}
                      label="key"
                      value={parameterKey}
                      onChange={(e) => setParameterKey(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 2 }}>
                    <TextField
                      sx={{ width: "95%" }}
                      error={props?.rawErrors?.length > 0 && !parameterValue}
                      label="value"
                      /*      type="password" */
                      value={parameterValue}
                      onChange={(e) => setParameterValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("type")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={parameterType}
                        label={t("type")}
                        onChange={handleChange}
                        disabled={!parameterValue}
                      >
                        <MenuItem value={"string"}>String</MenuItem>
                        <MenuItem
                          value={"integer"}
                          disabled={isNotConvertibleToIntOrFloat(
                            parameterValue
                          )}
                        >
                          Integer
                        </MenuItem>
                        <MenuItem
                          value={"float"}
                          disabled={isNotConvertibleToIntOrFloat(
                            parameterValue
                          )}
                        >
                          Float
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            <Box
              display={"flex"}
              justifyContent="space-between"
              sx={{ mt: 1, width: "100%" }}
            >
              <Tooltip title={t("add_param")}>
                <IconButton
                  onClick={onAddClick}
                  disabled={
                    (parameterKey == "" || parameterValue == "") &&
                    addParam == true
                  }
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("save_params")}>
                <IconButton
                  disabled={!parameterKey || !parameterValue || !parameterType}
                  onClick={onSaveClick}
                  sx={{ mr: 2 }}
                >
                  <DoneIcon
                    sx={{ color: readyToSave && theme.palette.success.main }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default StatisticParametersHandler;
