import { useEffect } from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useParams } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DraftsIcon from "@mui/icons-material/Drafts";
import Tooltip from "@mui/material/Tooltip";
import ModalDialog from "../meters/ModalDialog";
import { store } from "../../redux/store";
import CopyTextButton from "./CopyTextButton";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import IwdDataGrid from "../../pages/IwdDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import theme from "../../theme";
import {
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { nameTransform, typeTransform } from "../../utils/utilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { setReadingRange } from "../../redux/slices/meterSlice";
function compareDates(a, b) {
  const dateA = new Date(a.attributes.message_date);
  const dateB = new Date(b.attributes.message_date);
  return dateB - dateA;
}
const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const ReadedMeters = ({ idFromCondominium }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const gatewayId = useSelector((state) => state?.gatewaySlice?.gatewayId);
  const [sorts, setSorts] = useState([]);
  const [message, setMessage] = useState(null);
  const [formData, setFormData] = useState({});
  const [unreadFormData, setUnreadFormData] = useState({});
  const [errorFormData, setErrorFormData] = useState();
  const [unhandledField, setUnhandledField] = useState("");
  const [unhandOrder, setUnhandledOrder] = useState("");
  const [handledField, setHandledField] = useState("");
  const [getErrorMeterMessages] = store.useLazyGetErrorMeterMessagesQuery();
  const [getWLUnseenMessages] = store.useLazyGetUnseenGwMessagesQuery();
  const [handOrder, setHandledOrder] = useState("");
  const [modalMessage, setModalMessage] = useState(false);
  const blockId = useSelector(
    (state) => state?.gatewaySlice?.gateway?.fields?.condominium_identifier
  );
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [unreadPage, setUnreadPage] = useState(1);
  const [unseenGwWlPage, setUnseenWlPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [errorPage, setErrorPage] = useState(1);
  const [allMessages, setAllMessages] = useState([]);
  const readedMessages = useSelector(
    (state) => state?.gatewaySlice?.readedMessages
  );
  const errorMessages = useSelector(
    (state) => state?.gatewaySlice?.errorMetersMessages
  );
  const unreadedMessages = useSelector(
    (state) => state?.gatewaySlice?.unreadedMessages
  );
  const unreadedMessagesCount = useSelector(
    (state) => state?.gatewaySlice?.unreadedMessagesCount
  );
  const readedMessagesCount = useSelector(
    (state) => state?.gatewaySlice?.readedMessagesCount
  );
  const readedMessagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.readedMessagesFirstRender
  );
  const unreadedMessagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.unreadedMessagesFirstRender
  );
  const errorMessagesCount = useSelector(
    (state) => state?.gatewaySlice?.errorMessagesCount
  );
  const errorMessagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.errorMessagesFirstRender
  );
  const gwWlUnseenMsg = useSelector(
    (state) => state?.gatewaySlice?.unseenGwmessages
  );
  const gwWlUnseenCount = useSelector(
    (state) => state?.gatewaySlice?.unseenGwCount
  );
  const gwWlUnseenFirstRender = useSelector(
    (state) => state?.gatewaySlice?.unseenGwFirstRender
  );
  const sortedMessagesByData = readedMessages?.slice()?.sort(compareDates);
  const handledMessages = useSelector(
    (state) => state?.gatewaySlice?.handledMeterMessages
  );
  const condominumId = useSelector((state) => state?.gatewaySlice?.gateway)
    ?.fields?.condominium_identifier;
  const unhandledMeterMesages =
    useSelector((state) => state?.gatewaySlice?.messages)?.filter(
      (item) => item?.attributes?.values?.values?.last_message_type === "data"
    )?.[0]?.attributes?.values?.values?.unhandled_meters ?? [];

  const gwType = useSelector(
    (state) => state?.gatewaySlice?.gateway?.fields?.digital_twin_name
  );
  /*     const unhandledMeterMesages =
    useSelector((state) => state?.gatewaySlice?.messages)
      ?.filter
(

        (item) => item?.attributes?.values?.values?.last_message_type === "data"
      )
      ?.filter
(

        (item) => item.attributes?.values?.values?.unhandled_meters.length > 0
      )[0]?.attributes?.values?.values?.unhandled_meters ?? [];   */
  const transformName = (name) => {
    const nameInfo = name.split(":");
    const firstPart = parseInt(nameInfo[1], 16);
    const secondPart = parseInt(nameInfo[2], 16);
    const result = `${nameInfo[0]}:${firstPart}:${secondPart}`;

    return result;
  };
  const handleChange = (event, newValue) => {
    setSorts([]);
    setValue(newValue);
  };
  const transformedHandledMassages =
    handledMessages?.length > 0
      ? handledMessages?.map((obj) => ({
          ...obj,
          attributes: {
            device_id: obj.id,
            handled: true,
            values: {
              class: obj.attributes.class,
              serial_number: obj.attributes.serial_number,
              values: {
                condominium_name: obj.attributes.fields.condominium_name,
                apartment_name: obj.attributes.fields.apartment_name,
              },
            },
          },
        }))
      : [];
  const [getMessages] = store.useLazyGetMeterReadedMessagesQuery();

  const [getUnreadedMessages] = store.useLazyGetMeterUnreadedMessagesQuery();
  useEffect(() => {
    if (value == 0 && readedMessagesFirstRender && gatewayId) {
      getMessages({
        id: gatewayId,
        params: {
          status: "ok",
          per_page: perPage,
          condominium_identifier: blockId,
          page: page,

          ...formData,
        },
      });
    }
  }, [value, readedMessagesFirstRender, gatewayId]);
  useEffect(() => {
    if (value == 0 && !readedMessagesFirstRender && gatewayId) {
      getMessages({
        id: gatewayId,
        params: {
          status: "ok",
          per_page: perPage,
          condominium_identifier: blockId,
          page: page,

          ...formData,
        },
      });
    }
  }, [page]);

  useEffect(() => {
    if (value == 3 && gwWlUnseenFirstRender && gatewayId) {
      getWLUnseenMessages({
        id: gatewayId,
        params: {
          device_in_withe_list_not_seen: gatewayId,
        },
      });
    }
  }, [value, gwWlUnseenFirstRender, gatewayId]);
  useEffect(() => {
    if (value == 3 && gwWlUnseenFirstRender && gatewayId) {
      getWLUnseenMessages({
        id: gatewayId,
        params: {
          device_in_withe_list_not_seen: gatewayId,
        },
      });
    }
  }, [unseenGwWlPage]);

  useEffect(() => {
    if (value == 1 && unreadedMessagesFirstRender && gatewayId) {
      getUnreadedMessages({
        id: id,
        params: {
          page: unreadPage,
          per_page: perPage,

          ...unreadFormData,
        },
      });
    }
  }, [value, unreadedMessagesFirstRender, gatewayId]);
  useEffect(() => {
    if (value == 1 && !unreadedMessagesFirstRender && gatewayId) {
      getUnreadedMessages({
        id: id,
        params: {
          page: unreadPage,
          per_page: perPage,

          ...unreadFormData,
        },
      });
    }
  }, [unreadPage]);
  useEffect(() => {
    if (value == 2 && errorMessagesFirstRender) {
      getErrorMeterMessages({
        id: gatewayId,
        params: {
          status: "error_val",
          per_page: perPage,
          /*     condominium_identifier: blockId, */
          page: page,

          ...errorFormData,
        },
      });
    }
  }, [value, errorMessagesFirstRender]);
  useEffect(() => {
    if (value == 2 && !errorMessagesFirstRender) {
      getErrorMeterMessages({
        id: gatewayId,
        params: {
          status: "error_val",
          per_page: perPage,
          /*    condominium_identifier: blockId, */
          page: page,

          ...errorFormData,
        },
      });
    }
  }, [errorPage]);

  const styleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    /*     if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    } */
    if (
      params?.row?.conid !== "--" &&
      params?.row?.conid !== condominumId &&
      params?.row?.conid !== ""
    ) {
      return `super-app-theme--Rejected custom-colored`;
    }
    if (!params.row.message_date) {
      return `super-app-theme--Blue custom-colored`;
    }
  };

  useEffect(() => {
    transformName("MAD:50:07");
  }, []);
  const onMailClick = (msg) => {
    setMessage(msg);
    setModalMessage(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "handled",
      headerName: "handled",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      nosort: true,
    },
    {
      field: "conid",
      headerName: "conid",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      nosort: true,
    },

    {
      field: "class",
      headerName: t("meter"),
      type: "number",

      headerAlign: "left",

      flex: 3,
    },
    {
      field: "registry",
      headerName: t("registry"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
      nosort: true,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "number",

      headerAlign: "left",
      flex: 3,
    },

    {
      field: "message_date",
      headerName: t("last_telegram"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "value",
      headerName: t("reading"),
      type: "number",
      sortable: true,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "uncoverted_value",
      headerName: t("unconverted_value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      sortable: true,
      flex: 2,
    },
    {
      field: "k",
      headerName: t("k"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "heat_value",
      headerName: t("calories"),
      type: "number",
      sortable: true,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "cold_value",
      headerName: t("frigories"),
      type: "number",
      sortable: true,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "filtered",
      headerName: t("filtered"),
      editable: false,
      sortable: true,
      nosort: true,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        }
      },
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      sortable: true,
      nosort: true,
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <>
              {params.value.deviceId && (
                <IconButton
                  onClick={() =>
                    window.open(`/meters/${params.value.deviceId}`, "_blank")
                  }
                >
                  <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                </IconButton>
              )}
              {params.value.raw && (
                <IconButton onClick={() => onMailClick(params.value.raw)}>
                  <DraftsIcon />
                </IconButton>
              )}
            </>
          </Box>
        );
      },
    },
  ];
  const errorColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "handled",
      headerName: "handled",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      nosort: true,
    },
    {
      field: "conid",
      headerName: "conid",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      nosort: true,
    },

    {
      field: "class",
      headerName: t("meter"),
      type: "number",
      sortable: true,
      headerAlign: "left",

      flex: 3,
    },
    {
      field: "registry",
      headerName: t("registry"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
      nosort: true,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "message_date",
      headerName: t("last_telegram"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "value",
      headerName: t("reading"),
      type: "number",
      sortable: true,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "uncoverted_value",
      headerName: t("unconverted_value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      sortable: true,
      flex: 2,
    },
    {
      field: "k",
      headerName: t("k"),
      type: "number",
      headerAlign: "left",
      editable: false,
      sortable: true,
      flex: 2,
    },
    {
      field: "heat_value",
      headerName: t("calories"),
      type: "number",
      sortable: true,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "cold_value",
      headerName: t("frigories"),
      type: "number",
      sortable: true,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "filtered",
      headerName: t("filtered"),
      editable: false,
      sortable: true,
      nosort: true,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        }
      },
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      sortable: true,
      nosort: true,
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <>
              {params.value.deviceId && (
                <IconButton
                  onClick={() =>
                    window.open(`/meters/${params.value.deviceId}`, "_blank")
                  }
                >
                  <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                </IconButton>
              )}
              {params.value.raw && (
                <IconButton onClick={() => onMailClick(params.value.raw)}>
                  <DraftsIcon />
                </IconButton>
              )}
            </>
          </Box>
        );
      },
    },
  ];

  const renderUnit = (unit) => {
    if (unit === "J") {
      return "";
    }
    if (unit === "Wh") {
      return "kWh";
    }
    if (unit == "m^3") {
      return "m³";
    }
    return unit;
  };
  const errorMsgRows = errorMessages?.map((item, i) => ({
    id: i,
    class: item?.attributes?.class,

    serial_number: item?.attributes?.serial_number,

    registry: item?.attributes?.condominium_name
      ? `${t("condominium")}: ${item?.attributes?.condominium_name} - ${t(
          "apartament"
        )}: ${item?.attributes?.apartment_name}`
      : "--",

    message_date: item?.attributes?.message_date
      ? t("dt_n", { val: new Date(item?.attributes?.message_date) })
      : null,

    value: item?.attributes?.value
      ? `${t("number", {
          val: item?.attributes?.value,
        })}`
      : "--",
    cold_value:
      item?.attributes?.cold_value !== null
        ? item?.attributes?.unit === "Wh"
          ? t("number", {
              val: item?.attributes?.cold_value / 1000,
            })
          : t("number", {
              val: item?.attributes?.cold_value,
            })
        : "--",
    heat_value:
      item?.attributes?.heat_value !== null
        ? item?.attributes?.unit === "Wh"
          ? t("number", {
              val: item?.attributes?.heat_value / 1000,
            })
          : t("number", {
              val: item?.attributes?.heat_value,
            })
        : "--",
    uncoverted_value: item?.attributes?.uncoverted_value ?? "--",
    k: item?.attributes?.k ?? "--",
    filtered: item?.attributes?.filtered,
    unit: item?.attributes?.unit ? renderUnit(item?.attributes?.unit) : "--",

    conid: item?.attributes?.condominium_id ?? "--",

    actions: {
      deviceId: item?.attributes?.device_id,
      raw: item?.attributes?.raw,
    },
  }));

  const rows = readedMessages?.map((item, i) => ({
    id: i,
    class: item?.attributes?.class,

    serial_number: item?.attributes?.serial_number,

    registry: item?.attributes?.condominium_name
      ? `${t("condominium")}: ${item?.attributes?.condominium_name} - ${t(
          "apartament"
        )}: ${item?.attributes?.apartment_name}`
      : "--",

    message_date: item?.attributes?.message_date
      ? t("dt_n", { val: new Date(item?.attributes?.message_date) })
      : null,

    value: item?.attributes?.value
      ? `${t("number", {
          val: item?.attributes?.value,
        })}`
      : "--",
    cold_value:
      item?.attributes?.cold_value !== null
        ? item?.attributes?.unit === "Wh"
          ? t("number", {
              val: item?.attributes?.cold_value / 1000,
            })
          : t("number", {
              val: item?.attributes?.cold_value,
            })
        : "--",
    heat_value:
      item?.attributes?.heat_value !== null
        ? item?.attributes?.unit === "Wh"
          ? t("number", {
              val: item?.attributes?.heat_value / 1000,
            })
          : t("number", {
              val: item?.attributes?.heat_value,
            })
        : "--",
    uncoverted_value: item?.attributes?.uncoverted_value ?? "--",
    k: item?.attributes?.k ?? "--",
    filtered: item?.attributes?.filtered,
    unit: item?.attributes?.unit ? renderUnit(item?.attributes?.unit) : "--",

    conid: item?.attributes?.condominium_id ?? "--",

    actions: {
      deviceId: item?.attributes?.device_id,
      raw: item?.attributes?.raw,
    },
  }));

  const unreadColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: true,
      flex: 1,
      hide: true,
    },

    {
      field: "class",
      headerName: t("meter"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "serial_number",
      headerName: t("serial"),
      type: "number",

      headerAlign: "left",
      flex: 2,
    },

    {
      field: "message_date",
      headerName: t("last_telegram"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "filtered",
      headerName: t("filtered"),
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        }
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            {params.value && (
              <IconButton onClick={() => onMailClick(params.value)}>
                <DraftsIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const unreadRows = unreadedMessages?.map((item, i) => ({
    id: i,
    class: item?.attributes?.class,
    message_date: item?.attributes?.message_date
      ? t("dt_n", { val: new Date(item?.attributes?.message_date) })
      : null,
    serial_number: item?.attributes?.serial_number,
    filtered: item?.attributes?.values?.values?.value?.filtered,
    actions: item?.attributes?.raw,
  }));
  useEffect(() => {}, [readedMessages]);
  useEffect(() => {}, [message]);

  const unreadedSchema = {
    title: "New Device",
    type: "object",
    properties: {
      serial_number: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial_number"),
      },
      /* manufacturer: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("manufacturer"),
      },
      meter_type: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("type"),
      },
      version: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("version"),
      }, */
    },
  };
  const readedSchema = {
    title: "New Device",
    type: "object",
    properties: {
      serial_number: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial_number"),
      },
      /* class: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("class"),
      }, */
    },
  };
  const onSubmitClick = () => {
    setUnreadPage(1);
    setPage(1);
    setErrorPage(1);

    if (value == 0) {
      getMessages({
        id: gatewayId,
        params: {
          status: "ok",
          per_page: perPage,
          condominium_identifier: blockId,
          page: page,

          ...formData,
        },
      });
    }
    if (value == 1) {
      getUnreadedMessages({
        id: id,
        params: {
          page: unreadPage,
          per_page: perPage,

          ...unreadFormData,
        },
      });
    }
    if (value == 2) {
      getErrorMeterMessages({
        id: gatewayId,
        params: {
          status: "error_val",
          per_page: perPage,
          /*   condominium_identifier: blockId, */
          page: page,

          ...errorFormData,
        },
      });
    }
    if (value == 3) {
      getWLUnseenMessages({
        id: gatewayId,
        params: {
          device_in_withe_list_not_seen: gatewayId,
        },
      });
    }
  };

  const wlUnseenColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "handled",
      headerName: "handled",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      nosort: true,
    },
    {
      field: "conid",
      headerName: "conid",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      nosort: true,
    },

    {
      field: "class",
      headerName: t("meter"),
      type: "number",

      headerAlign: "left",

      flex: 3,
    },
    {
      field: "registry",
      headerName: t("registry"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
      nosort: true,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "number",

      headerAlign: "left",
      flex: 3,
    },

    {
      field: "filtered",
      headerName: t("filtered"),
      editable: false,
      sortable: true,
      nosort: true,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        }
      },
    },
    /*  {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      sortable: true,
      nosort: true,
      flex: 2,
    }, */

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <>
              {params.value.deviceId && (
                <IconButton
                  onClick={() =>
                    window.open(`/meters/${params.value.deviceId}`, "_blank")
                  }
                >
                  <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
                </IconButton>
              )}
              {params.value.raw && (
                <IconButton onClick={() => onMailClick(params.value.raw)}>
                  <DraftsIcon />
                </IconButton>
              )}
            </>
          </Box>
        );
      },
    },
  ];

  const wlUnseenRows = gwWlUnseenMsg?.map((item, i) => ({
    id: i,
    class: item?.attributes?.class,

    serial_number: item?.attributes?.serial_number,

    registry: item?.attributes?.condominium_name
      ? `${t("condominium")}: ${item?.attributes?.condominium_name} - ${t(
          "apartament"
        )}: ${item?.attributes?.apartment_name}`
      : "--",

    message_date: item?.attributes?.message_date
      ? t("dt_n", { val: new Date(item?.attributes?.message_date) })
      : null,

    value: item?.attributes?.value
      ? `${t("number", {
          val: item?.attributes?.value,
        })}`
      : "--",
    cold_value:
      item?.attributes?.cold_value !== null
        ? item?.attributes?.unit === "Wh"
          ? t("number", {
              val: item?.attributes?.cold_value / 1000,
            })
          : t("number", {
              val: item?.attributes?.cold_value,
            })
        : "--",
    heat_value:
      item?.attributes?.heat_value !== null
        ? item?.attributes?.unit === "Wh"
          ? t("number", {
              val: item?.attributes?.heat_value / 1000,
            })
          : t("number", {
              val: item?.attributes?.heat_value,
            })
        : "--",
    uncoverted_value: item?.attributes?.uncoverted_value ?? "--",
    k: item?.attributes?.k ?? "--",
    filtered: item?.attributes?.filtered,
    unit: item?.attributes?.unit ? renderUnit(item?.attributes?.unit) : "--",

    conid: item?.attributes?.condominium_id ?? "--",

    actions: {
      deviceId: item?.attributes?.device_id,
      raw: item?.attributes?.raw,
    },
  }));

  const sortUnhandledMsg = (newModel) => {
    setUnhandledField(newModel[0]?.field);
    setUnhandledOrder(newModel[0]?.sort);

    getUnreadedMessages({
      id: id,
      params: {
        page: unreadPage,
        per_page: perPage,
        sort: newModel[0]?.field,
        order: newModel[0]?.sort,
        ...unreadFormData,
      },
    });
  };
  const sortErrordMsg = (newModel) => {
    getErrorMeterMessages({
      id: gatewayId,
      params: {
        status: "error_val",
        per_page: perPage,
        page: page,
        sort: newModel[0]?.field,
        order: newModel[0]?.sort,
        ...errorFormData,
      },
    });
  };
  const sortHandledMsg = (newModel) => {
    setHandledField(newModel?.[0]?.field);
    setHandledOrder(newModel?.[0]?.sort);
    getMessages({
      id: gatewayId,
      params: {
        status: "ok",
        per_page: perPage,
        condominium_identifier: blockId,
        page: page,

        sort: newModel[0]?.field,
        order: newModel[0]?.sort,
        ...formData,
      },
    });
  };
  const sortWlUnseenMsg = (newModel) => {
    setHandledField(newModel?.[0]?.field);
    setHandledOrder(newModel?.[0]?.sort);
    getWLUnseenMessages({
      id: gatewayId,
      params: {
        device_in_withe_list_not_seen: gatewayId,
        sort: newModel[0]?.field,
        order: newModel[0]?.sort,
      },
    });
  };
  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  };
  const formDataSelector = () => {
    if (value == 0) {
      return formData;
    }
    if (value == 1) {
      return unreadFormData;
    }
    if (value == 2) {
      return errorFormData;
    }
  };
  const formDataChange = (val) => {
    if (value == 0) {
      setFormData(val);
    }
    if (value == 1) {
      setUnreadFormData(val);
    }
    if (value == 2) {
      setErrorFormData(val);
    }
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {/* <Accordion sx={{ mt: 2, mb: 2 }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Search form
          </AccordionSummary>
          <AccordionDetails>
            <Form
              schema={value == 0 ? readedSchema : unreadedSchema}
              formData={formDataSelector()}
              onChange={(changeEvent) => formDataChange(changeEvent.formData)}
              onSubmit={(values) => onSubmitClick(values.formData)}
              validator={validator}
              uiSchema={uiSchm}
        
              showErrorList={false}
         
              noHtml5Validate
            />
          </AccordionDetails>
        </Accordion> */}
        <ModalDialog
          open={modalMessage}
          close={() => setModalMessage(false)}
          title={"Message"}
        >
          <Box>
            <Typography variant="subtitle2">
              <pre
                style={{
                  overflow: "auto",
                  background: "#c0c0c0",

                  padding: "9px",
                }}
              >
                {JSON.stringify(message, null, 10)}
              </pre>
            </Typography>
            <CopyTextButton text={message?.frame ?? message} />
          </Box>
        </ModalDialog>
        <Box /* sx={{ borderBottom: 1, borderColor: "divider" }} */>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("handled_meters")} />
            <Tab label={t("unhandled_meters")} />
            <Tab label={t("error_meters")} />
            {gwType == "RBM Box" && <Tab label={t("white_list_not_seen")} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {readedMessages?.length > 0 && (
            <IwdDataGrid
              columns={columns}
              perPage={perPage}
              setPage={setPage}
              rows={rows}
              page={page}
              count={readedMessagesCount}
              setSorts={setSorts}
              sorts={sorts}
              height={600}
              styles={styleSelector}
              customSortFn={sortHandledMsg}
              paginationMode={"server"}
              tableName={"readed_meters"}
            />
          )}
          {readedMessages?.length === 0 && (
            <Box sx={{ mt: 7 }}>
              <Typography variant="h4" align="center">
                {t("no_available_data")}
              </Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {unreadedMessages?.length > 0 && (
            /*    <Box sx={{ height: "60vh", width: "99%" }}>
              <StyledDataGrid
             
                disableColumnMenu
                rows={unreadRows}
                columns={unreadColumns}
                rowCount={unreadedMessagesCount}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                onSortModelChange={sortUnhandledMsg}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setUnreadPage(pageNumber + 1);
                }}
                page={unreadPage - 1}
                paginationMode="server"
                sortingMode="server"
              />
            </Box> */
            <IwdDataGrid
              columns={unreadColumns}
              perPage={perPage}
              setPage={setUnreadPage}
              rows={unreadRows}
              page={unreadPage}
              count={unreadedMessagesCount}
              setSorts={setSorts}
              customSortFn={sortUnhandledMsg}
              sorts={sorts}
              height={600}
              paginationMode={"server"}
              tableName={"unreaded_meters"}
            />
          )}
          {unreadedMessages?.length === 0 && (
            <Box sx={{ mt: 7 }}>
              <Typography variant="h4" align="center">
                {t("no_available_data")}
              </Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {errorMessages?.length > 0 && (
            /* <Box sx={{ height: "60vh", width: "99%" }}>
              <StyledDataGrid
            
                disableColumnMenu
                rows={errorMsgRows}
                columns={errorColumns}
                rowCount={errorMessagesCount}
                pageSize={perPage}
              
                sortingMode="server"
                paginationMode="server"
                rowsPerPageOptions={[perPage]}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setErrorPage(pageNumber + 1);
                }}
                page={errorPage - 1}
              />

            </Box> */
            <IwdDataGrid
              columns={errorColumns}
              perPage={perPage}
              setPage={setErrorPage}
              rows={errorMsgRows}
              page={errorPage}
              count={errorMessagesCount}
              setSorts={setSorts}
              sorts={sorts}
              height={600}
              paginationMode={"server"}
              customSortFn={sortErrordMsg}
              tableName={"error_meters"}
            />
          )}
          {errorMessages?.length === 0 && (
            <Box sx={{ mt: 7 }}>
              <Typography variant="h4" align="center">
                {t("no_available_data")}
              </Typography>
            </Box>
          )}
        </TabPanel>
        {gwType == "RBM Box" && (
          <TabPanel value={value} index={3}>
            {gwWlUnseenMsg?.length > 0 && (
              <IwdDataGrid
                columns={wlUnseenColumns}
                perPage={perPage}
                setPage={setUnseenWlPage}
                rows={wlUnseenRows}
                page={unseenGwWlPage}
                count={gwWlUnseenCount}
                customSortFn={sortWlUnseenMsg}
                height={600}
                paginationMode={"server"}
                tableName={"unseen_meters"}
              />
            )}
            {gwWlUnseenMsg?.length === 0 && (
              <Box sx={{ mt: 7 }}>
                <Typography variant="h4" align="center">
                  {t("no_available_data")}
                </Typography>
              </Box>
            )}
          </TabPanel>
        )}
      </Box>
    </>
  );
};
export default ReadedMeters;
