import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
const initialState = {
  signout: false,
  currentToken: null,
  logged: null,
  unauthorized: false,
};

const authSlice = createSlice({
  name: "iwdWebSocketSlice",
  initialState,
  reducers: {
    signin(state, action) {
      localStorage.setItem("user_token", action.payload);
      localStorage.setItem("banner", "active");
      state.currentToken = action.payload;
      state.logged = true;
    },
    signout(state) {
      state.signout = true;
    },
    getFrom() {
      /* return JSON.parse(localStorage.getItem("from")); */
    },
    user() {
      return JSON.parse(localStorage.getItem("user_info"));
    },
    setFrom(state, action) {
      localStorage.setItem("from", JSON.stringify(action.payload));
    },
    isLogged(state) {
      const newToken = localStorage.getItem("user_token");
      const userInfo = localStorage.getItem("user_info");
      if (newToken && userInfo) {
        state.logged = true;
      } else {
        state.logged = false;
      }
    },
    logout(state) {
      state.logged = false;
      localStorage.removeItem("user_token");
      localStorage.removeItem("user_info");
      localStorage.removeItem("user_preferences");
      /*     localStorage.setItem("banner", "inactive"); */
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.authEndpoints.signout.matchFulfilled,
      (state, action) => {
        localStorage.removeItem("user_token");
        localStorage.removeItem("user_info");

        localStorage.removeItem("user_preferences");

        state.currentToken = null;
        state.logged = false;
        state.signout = false;
      }
    );
  },
});

//TODO: try to do it better
export const getUserToken = () => {
  return localStorage.getItem("user_token");
};
export const actions = authSlice.actions;
export default authSlice.reducer;
