import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  display: "flex",
  alignItems: "center",
}));

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: "auto",
  marginRight: "16px",
  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem",
  },
});

const CustomListItemText = styled(ListItemText)({
  flex: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const IwdClosedItem = ({ items }) => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <List>
      {items.map((item, index) => (
        <CustomListItem
          button
          key={index}
          onClick={() => handleClick(item.link)}
        >
          <StyledListItemIcon>{item.icon}</StyledListItemIcon>
          <CustomListItemText primary={item.name} />
        </CustomListItem>
      ))}
    </List>
  );
};

export default IwdClosedItem;
