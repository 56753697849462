import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
//import { TreeItem, TreeView } from "@mui/lab";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { Box } from "@mui/system";
import { ChevronRightOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import LongNameWrapper from "../../utils/LongNameWrapper";
import { autoloadingOn } from "../../redux/slices/loadingSlice";
import { setMeterShow } from "../../redux/slices/meterSlice";
import DeleteButton from "../../utils/DeleteButton";
import { v4 as uuidv4 } from "uuid";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { styled } from "@mui/system";
import { cleanFreeData } from "../../redux/slices/condominiumSlice";
import ModalDialog from "../meters/ModalDialog";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { cleanGatewayData } from "../../redux/slices/gatewaySlice";
import { setMeterDissocId } from "../../redux/slices/condominiumSlice";
import {
  setApartametnMeterModal,
  setSubCondominiumGatewayModal,
  setTreeExpanded,
  setPosDirection,
  setCondomiumTabValue,
  resetApartmentReadings,
  resetMeterId,
  setShowMode,
  resetSubcondominiumReadings,
} from "../../redux/slices/condominiumSlice";
import { setTreeItem } from "../../redux/slices/condominiumSlice";
import {
  setSubComIndex,
  setAppIndex,
} from "../../redux/slices/condominiumSlice";
import { setcondominiumTrashId } from "../../redux/slices/condominiumSlice";
import SubstitutionModal from "./SubstitutionModal";
import { Button, Typography } from "@mui/material";
import { setBtnDetector } from "../../redux/slices/condominiumSlice";
import theme from "../../theme";

import { cleanMeterId } from "../../redux/slices/condominiumSlice";
import { closeModal } from "../../redux/slices/trashSlice";
import { type } from "os";

// selectedName should be the name of the subcondominium or apartment selected in the tree, condominium is the condominium object as
// returned by the API
const StyledTreeItem = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer svg": {
    color: theme.palette.blockArrow,
  },
});
export const CondominiumTree = ({
  condominium,
  subcondominiumName,
  apartmentName,
  serial,
  apartment,
  subcondominium,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(["condominum"]);
  const [trashId, setTrashId] = useState(null);
  const condominiumId = useSelector(
    (state) => state?.condominiumSlice?.condominiumId
  );
  const gatewayMode = useSelector(
    (state) => state?.condominiumSlice?.gatewayMode
  );
  const selectedMeterType = useSelector(
    (state) => state.meterSlice?.meterShow?.attributes?.fields?.type
  );
  const meterId = useSelector(
    (state) => state.meterSlice?.meterShow?.attributes?.identifier
  );
  const meterDissocId = useSelector(
    (state) => state?.condominiumSlice?.meterDissocId
  );
  const [substitutinModal, setSubstitutinModal] = useState(false);

  const [deleteCondominium, { isSuccess: deleteSuccess }] =
    store.useDeleteCondominiumMutation();
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const [deleteApartment, { isSuccess: deleteAppsuccess }] =
    store.useDeleteApartmentMutation();
  const [dissocMeterModal, setDissocMetermodal] = useState(false);
  const [dissociateMeter, { isSuccess: dissocSuccess }] =
    store.useDissociateMeterMutation();

  const [getFreeMeters] = store.useLazyGetFreeMetersQuery();
  const [deleteSubblock, { isSuccess: deleteSubSuccess }] =
    store.useDeleteSubblockMutation();

  const [deleteOption, setDeleteOption] = useState("");
  const [deleteType, setDeleteType] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const handleNodeToggle = (event, itemIds) => {
    if (itemIds !== "new") {
      setExpanded(itemIds);
    }
  };
  const condominiumTrashId = useSelector(
    (state) => state?.condominiumSlice?.condominiumTrashId
  );
  const botSubPos = useSelector((state) => state?.condominiumSlice?.botSubPos);
  const topSubPos = useSelector((state) => state?.condominiumSlice?.topSubPos);
  const subCondominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumIdentifier
  );
  const apartamentIdentifier = useSelector(
    (state) => state?.condominiumSlice?.apartamentIdentifier
  );
  const [deleteModal, setDeletemodal] = useState(false);
  const notCreating = !window.location.href.includes("new");
  const selectedItem = useSelector(
    (state) => state?.condominiumSlice?.selectedTreeItem
  );
  const treeExpanded = useSelector(
    (state) => state.condominiumSlice.treeExpanded
  );
  const subCondominiumTopAllowed = useSelector(
    (state) => state.condominiumSlice.subCondominiumTopAllowed
  );
  const subCondominiumBotAllowed = useSelector(
    (state) => state.condominiumSlice.subCondominiumBotAllowed
  );
  const appTopAllowed = useSelector(
    (state) => state.condominiumSlice.appTopAllowed
  );
  const appBotAllowed = useSelector(
    (state) => state.condominiumSlice.appBotAllowed
  );
  const btnNameDetector = useSelector(
    (state) => state.condominiumSlice.btnDetector
  );
  const handleToggle = (event, itemIds, isExpanded) => {
    if (isExpanded) {
      dispatch(setTreeExpanded([...treeExpanded, itemIds]));
    } else {
      const index = treeExpanded.indexOf(itemIds);
      const copyTreeExpanded = [...treeExpanded];
      const x = copyTreeExpanded.splice(index, 1);
      dispatch(setTreeExpanded(copyTreeExpanded));
    }
    //dispatch(setTreeExpanded(itemIds)
    sessionStorage.setItem("TreeExpanded", treeExpanded);
  };
  useEffect(() => {}, [selectedMeterType]);
  const deleteOnlyCondominum = () => {
    setDeleteOption("condominum");
    setConfirmDeleteModal(true);
    setDeletemodal(false);
  };
  const deleteCondWithMeters = () => {
    setDeleteOption("all");
    setConfirmDeleteModal(true);
    setDeletemodal(false);
  };
  const onDeleteClick = (id, type) => {
    setTrashId(id);
    setDeleteType(type);
    if (
      (type == "condominum" && blockMeters?.length > 0) ||
      (type == "subcondominum" && subChildren?.length > 0) ||
      (type == "apartment" && apartment?.children?.length > 0)
    ) {
      setDeletemodal(true);
    } else {
      setConfirmDeleteModal(true);
    }
  };
  useEffect(() => {
    sessionStorage.setItem("TreeExpanded", treeExpanded);
  }, [treeExpanded]);
  useEffect(() => {}, [selectedItem]);
  useEffect(() => {
    if (
      condominiumTrashId &&
      (deleteOption === "condominum" || deleteOption === "") &&
      deleteType == "condominum"
    ) {
      deleteCondominium({
        id: condominiumTrashId,
        delete_meters: false,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    if (
      condominiumTrashId &&
      deleteOption === "all" &&
      deleteType == "condominum"
    ) {
      deleteCondominium({
        id: condominiumTrashId,
        delete_meters: true,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    if (
      condominiumTrashId &&
      (deleteOption === "condominum" || deleteOption === "") &&
      deleteType == "subcondominum"
    ) {
      let attributes = {
        identifier: condominiumTrashId,
        delete_meters: false,
      };
      deleteSubblock({
        id: condominiumTrashId,
        attributes: attributes,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {}, [deleteType]);
  useEffect(() => {
    if (
      condominiumTrashId &&
      deleteOption === "all" &&
      deleteType == "subcondominum"
    ) {
      let attributes = {
        identifier: condominiumTrashId,
        delete_meters: true,
      };
      deleteSubblock({
        id: condominiumTrashId,
        attributes: attributes,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    if (
      condominiumTrashId &&
      (deleteOption === "condominum" || deleteOption === "") &&
      deleteType == "apartment"
    ) {
      let attributes = {
        identifier: condominiumTrashId,
        delete_meters: false,
      };
      deleteApartment({
        id: apartamentIdentifier,
        attributes: attributes,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    if (
      condominiumTrashId &&
      deleteOption === "all" &&
      deleteType == "apartment"
    ) {
      let attributes = {
        identifier: condominiumTrashId,
        delete_meters: true,
      };
      deleteApartment({
        id: apartamentIdentifier,
        attributes: attributes,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate("/condominiums");
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (deleteAppsuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      getCondominiumShow(id);
      navigate(`/condominiums/${id}/subcondominiums/${subcondominiumName}`);
    }
  }, [deleteAppsuccess]);
  useEffect(() => {
    if (deleteSubSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      getCondominiumShow(id);
      navigate(`/condominiums/${id}`);
      dispatch(setBtnDetector(1));
    }
  }, [deleteSubSuccess]);
  const handleSelect = (event, itemId) => {
    dispatch(setTreeItem(itemId));
  };
  useEffect(() => {}, [apartment]);
  const blockMeters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat();

  const subChildren = subcondominium?.children
    ?.map((item) => item)
    ?.map((item) => item?.children)
    .flat();

  useEffect(() => {}, [apartment]);
  useEffect(() => {}, [blockMeters]);
  const sortChildrenByPosition = (children) => {
    return [...children]?.sort((a, b) => {
      const positionA = a.attributes?.position;
      const positionB = b.attributes?.position;

      if (positionA === undefined) return 1;
      if (positionB === undefined) return -1;

      return positionA - positionB;
    });
  };

  /*  const [btnNameDetector, setBtnNameDetector] = useState(1); */
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  useEffect(() => {}, [btnNameDetector]);

  useEffect(() => {}, []);
  useEffect(() => {}, [expanded]);
  const deleteModalTextSelector = () => {
    switch (deleteType) {
      case "condominum":
        return t("delete_condominum");
      case "subcondominum":
        return t("delete_subblock");
      case "apartment":
        return t("delete_apartment");
    }
  };

  const onSubstitutionClick = () => {
    const attributes = {
      parent_is_nil: true,
      decommissioned: false,
      type: JSON.stringify([`${selectedMeterType}`]),
    };
    /*  getFreeMeters(attributes); */
    setSubstitutinModal(true);
  };
  const closeSubModal = () => {
    /*   dispatch(setMeterShow(null)); */
    setSubstitutinModal(false);
    dispatch(cleanFreeData());
  };

  const onDissocMeterClick = () => {
    setDissocMetermodal(true);
  };
  useEffect(() => {
    if (meterDissocId) {
      dissociateMeter({
        id: id,
        attributes: {
          device_id: meterDissocId,
        },
      });
    }
  }, [meterDissocId]);
  useEffect(() => {
    if (dissocSuccess) {
      dispatch(setMessage(t("dissoc_meter_success")));
      dispatch(setMeterDissocId(null));
      getCondominiumShow(id);
    }
  }, [dissocSuccess]);
  const getDeleteOnlyBlockName = (type) => {
    if (type === "condominum") {
      return t("del_con");
    } else if (type === "subcondominum") {
      return t("del_subcon");
    } else if (type === "apartment") {
      return t("del_app");
    }
    return ""; // Default return value if none match
  };
  const getDeleteWithMetName = (type) => {
    switch (type) {
      case "condominum":
        return t("del_con_with_met");
      case "subcondominum":
        return t("del_subcon_with_met");
      case "apartment":
        return t("del_app_with_met");
      default:
        return ""; // Default return value if none match
    }
  };

  return (
    <Box
      sx={{
        padding: "0.5rem",
        marginRight: "0.3rem",

        width: "22rem",
        border: "1px solid gainsboro",
        height: "100%",
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalDialog
        open={substitutinModal}
        title={t("meter_replaсe")}
        close={closeSubModal}
      >
        <SubstitutionModal open={substitutinModal} close={closeSubModal} />
      </ModalDialog>
      <ModalDialog
        open={deleteModal}
        title={deleteModalTextSelector()}
        close={() => setDeletemodal(false)}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ width: 700, height: 70, mb: 2 }}
        >
          <IwdAuthWrapper
            children={
              <DeleteButton
                name={getDeleteOnlyBlockName(deleteType)}
                styles={{ height: 40, mt: 6 }}
                onClick={deleteOnlyCondominum}
              />
              /*      <Button
                onClick={deleteOnlyCondominum}
                sx={{ height: 40, mt: 6 }}
                variant={window.BUTTON_TYPE}
              >
                {deleteType == "condominum" && t("del_con")}
                {deleteType == "subcondominum" && t("del_subcon")}
                {deleteType == "apartment" && t("del_app")}
              </Button> */
            }
            section={"del_only_block"}
          />
          <IwdAuthWrapper
            children={
              <DeleteButton
                name={getDeleteWithMetName(deleteType)}
                styles={{ height: 40, mt: 6 }}
                onClick={deleteCondWithMeters}
              />
              /* <Button
                onClick={deleteCondWithMeters}
                sx={{ height: 40, mt: 6 }}
                variant={window.BUTTON_TYPE}
              >
                {deleteType == "condominum" && t("del_con_with_met")}
                {deleteType == "subcondominum" && t("del_subcon_with_met")}
                {deleteType == "apartment" && t("del_app_with_met")}
              </Button> */
            }
            section={"block_del_cond_with_mtrs"}
          />
        </Box>
      </ModalDialog>
      <DeleteConfirmModal
        trashModal={confirmDeleteModal}
        setTrashModal={setConfirmDeleteModal}
        title={""}
        text={t("cond_delete_text")}
        deleteFunc={() => setcondominiumTrashId(trashId)}
      />
      <DeleteConfirmModal
        trashModal={dissocMeterModal}
        setTrashModal={setDissocMetermodal}
        title={""}
        text={t("meter_dissoc_text")}
        deleteFunc={() => setMeterDissocId(meterId)}
      />
      <SimpleTreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreOutlined />}
        defaultExpandIcon={<ChevronRightOutlined />}
        expandedItems={treeExpanded}
        onItemExpansionToggle={handleToggle}
        onNodeSelect={handleSelect}
        sx={{ height: 500, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
      >
        <StyledTreeItem
          itemId="condominum"
          /*     label={condominium?.attributes?.name ?? ""} */
          label={
            <LongNameWrapper
              text={condominium?.attributes?.name}
              size={"10vw"}
              maxLength={100}
            />
          }
          onClick={() => {
            id &&
              window.sessionStorage.setItem(
                "bcName",
                condominium?.attributes?.name
              );
            dispatch(setBtnDetector(1));
            dispatch(setTreeItem("condominum"));
            navigate(`/condominiums/${id}`);
          }}
        >
          {condominium?.attributes?.children?.length > 0 &&
            sortChildrenByPosition(condominium?.attributes?.children)?.map(
              (subCondominium, index) => (
                <StyledTreeItem
                  /*  label={subCondominium?.attributes?.name} */
                  label={
                    <LongNameWrapper
                      text={subCondominium?.attributes?.name}
                      size={"8vw"}
                      maxLength={100}
                    />
                  }
                  itemId={subCondominium?.attributes?.identifier}
                  onClick={() => {
                    dispatch(setBtnDetector(2));
                    dispatch(setSubComIndex(index));
                    dispatch(setShowMode("gateway"));
                    /*       dispatch(resetSubcondominiumReadings()); */
                    dispatch(setTreeItem(subCondominium?.attributes?.name));
                    if (
                      subCondominium?.attributes?.name !== subcondominiumName ||
                      apartmentName
                    )
                      navigate(
                        `/condominiums/${id}/subcondominiums/${encodeURIComponent(
                          subCondominium?.attributes?.name
                        )}`
                      );
                  }}
                >
                  {subCondominium?.children?.length > 0 &&
                    sortChildrenByPosition(subCondominium?.children)?.map(
                      (apartment, index) => (
                        <StyledTreeItem
                          itemId={apartment?.attributes?.identifier}
                          /*        label={apartment?.attributes?.name} */
                          label={
                            <LongNameWrapper
                              text={apartment?.attributes?.name}
                              size={"8vw"}
                              maxLength={100}
                            />
                          }
                          onClick={() => {
                            if (apartment?.attributes?.name !== apartmentName)
                              dispatch(setBtnDetector(3));
                            dispatch(setShowMode("gateway"));
                            window.sessionStorage.setItem("bcName", "");
                            dispatch(setAppIndex(index));
                            /*     dispatch(resetApartmentReadings()); */
                            dispatch(setTreeItem(apartment?.attributes?.name));
                            dispatch(cleanGatewayData());
                            dispatch(cleanMeterId());
                            dispatch(autoloadingOn());
                            navigate(
                              `/condominiums/${id}/subcondominiums/${encodeURIComponent(
                                subCondominium?.attributes?.name
                              )}/apartments/${encodeURIComponent(
                                apartment?.attributes?.name
                              )}`
                            );
                          }}
                        >
                          {apartment?.children?.length > 0 &&
                            apartment?.children?.map((meter) => (
                              <StyledTreeItem
                                itemId={meter?.attributes?.serial}
                                /*    label={meter?.attributes?.serial} */
                                label={
                                  <LongNameWrapper
                                    text={
                                      meter?.attributes?.meter_type ===
                                      "RIPARTITORE"
                                        ? `${
                                            meter?.attributes?.room !==
                                            undefined
                                              ? meter?.attributes?.room + " -"
                                              : " "
                                          }  ${meter?.attributes?.serial}`
                                        : meter?.attributes?.serial
                                    }
                                    size={"6vw"}
                                    maxLength={100}
                                  />
                                }
                                onClick={() => {
                                  if (meter?.attributes?.serial !== serial)
                                    dispatch(setBtnDetector(5));
                                  window.sessionStorage.setItem("bcName", "");
                                  dispatch(setShowMode("gateway"));
                                  dispatch(
                                    setTreeItem(meter?.attributes?.serial)
                                  );

                                  navigate(
                                    `/condominiums/${id}/subcondominiums/${encodeURIComponent(
                                      subCondominium?.attributes?.name
                                    )}/apartments/${encodeURIComponent(
                                      encodeURIComponent(
                                        apartment?.attributes?.name
                                      )
                                    )}/apartamentMeter/${encodeURIComponent(
                                      meter?.attributes?.serial
                                    )}`
                                  );
                                }}
                              />
                            ))}
                        </StyledTreeItem>
                      )
                    )}
                </StyledTreeItem>
              )
            )}
        </StyledTreeItem>
      </SimpleTreeView>

      <IwdAuthWrapper
        section={"block_btns"}
        children={
          <>
            {" "}
            {id && btnNameDetector == 1 && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ marginTop: "1rem" }}
              >
                <IwdAuthWrapper
                  children={
                    <Button
                      variant={window.BUTTON_TYPE}
                      sx={{
                        mt: 2,
                      }}
                      onClick={() => {
                        dispatch(setCondomiumTabValue(0));
                        window.sessionStorage.setItem(
                          "bcName",
                          "Nuovo sotto condominio"
                        );
                        dispatch(setTreeItem("condominum"));
                        return navigate(
                          `/condominiums/${id}/subcondominiums/new`
                        );
                      }}
                    >
                      {t("new_subcondominium")}
                    </Button>
                  }
                  section={"add_subblock"}
                />
              </Box>
            )}
            {btnNameDetector == 2 && notCreating && (
              <>
                <IwdAuthWrapper
                  children={
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Button
                        onClick={() => dispatch(setPosDirection("subTop"))}
                        disabled={!topSubPos}
                        variant={window.BUTTON_TYPE}
                        sx={{
                          mt: 2,

                          width: "48%",
                        }}
                      >
                        {t("move_up")}
                      </Button>
                      <Button
                        variant={window.BUTTON_TYPE}
                        disabled={!botSubPos}
                        onClick={() => dispatch(setPosDirection("subDown"))}
                        sx={{
                          mt: 2,

                          width: "48%",
                        }}
                      >
                        {t("move_down")}
                      </Button>
                    </Box>
                  }
                  section={"moove_buttons"}
                />
                <IwdAuthWrapper
                  children={
                    <Button
                      variant={window.BUTTON_TYPE}
                      sx={{
                        mt: 2,
                      }}
                      onClick={() => {
                        dispatch(setBtnDetector(4));
                        dispatch(setCondomiumTabValue(0));
                        dispatch(cleanMeterId());
                        window.sessionStorage.setItem(
                          "bcName",
                          "Nuovo Appartamento"
                        );
                        navigate("apartments/new");
                      }}
                    >
                      {t("new_apartament")}
                    </Button>
                  }
                  section={"ad_appartment"}
                />
                <IwdAuthWrapper
                  children={
                    <Button
                      variant={window.BUTTON_TYPE}
                      sx={{
                        mt: 2,
                      }}
                      onClick={() => {
                        dispatch(setSubCondominiumGatewayModal(true));
                        /*      window.sessionStorage.setItem("bcName", "Nuovo huevo"); */
                        /*  return navigate("/gateways/new"); */
                      }}
                    >
                      {t("add_gateway")}
                    </Button>
                  }
                  section={"associate_gateway"}
                />
              </>
            )}
            {id && btnNameDetector == 3 && notCreating && (
              <>
                <IwdAuthWrapper
                  children={
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Button
                        disabled={!appTopAllowed}
                        variant={window.BUTTON_TYPE}
                        onClick={() => dispatch(setPosDirection("appTop"))}
                        sx={{
                          mt: 2,

                          width: "48%",
                        }}
                      >
                        {t("move_up")}
                      </Button>
                      <Button
                        variant={window.BUTTON_TYPE}
                        disabled={!appBotAllowed}
                        onClick={() => dispatch(setPosDirection("appDown"))}
                        sx={{
                          mt: 2,

                          width: "48%",
                        }}
                      >
                        {t("move_down")}
                      </Button>
                    </Box>
                  }
                  section={"moove_buttons"}
                />

                {!gatewayMode && (
                  <IwdAuthWrapper
                    children={
                      <Button
                        onClick={() => dispatch(setApartametnMeterModal(true))}
                        variant={window.BUTTON_TYPE}
                        sx={{
                          mt: 2,
                        }}
                      >
                        {t("add_meter")}
                      </Button>
                    }
                    section={"add_block_meter"}
                  />
                )}
              </>
            )}
            {id && btnNameDetector === 5 && notCreating && (
              <>
                <IwdAuthWrapper
                  children={
                    <Button
                      onClick={onSubstitutionClick}
                      variant={window.BUTTON_TYPE}
                      sx={{}}
                    >
                      {t("replace")}
                    </Button>
                  }
                  section={"replace_meter"}
                />
                <IwdAuthWrapper
                  children={
                    <Button
                      onClick={onDissocMeterClick}
                      variant={window.BUTTON_TYPE}
                      sx={{
                        mt: 2,
                        /*  background: theme.palette.error.main,
                        color: "white",
                        "&:hover": {
                          backgroundColor: theme.palette.error.main,
                        }, */
                      }}
                    >
                      {t("dissociate_meter")}
                    </Button>
                  }
                  section={"dissociate_meter"}
                />
              </>
            )}
            {id && btnNameDetector === 1 && notCreating && (
              <IwdAuthWrapper
                children={
                  <DeleteButton
                    name={t("delete_condominium")}
                    styles={{ mt: 2 }}
                    onClick={() => onDeleteClick(id, "condominum")}
                  />
                  /*  <Button
                    sx={{
                      mt: 2,
                     
                    }}
                    variant={window.BUTTON_TYPE}
                    onClick={() => onDeleteClick(id, "condominum")}
                  >
                    {t("delete_condominium")}
                  </Button> */
                }
                section={"delete_condominum"}
              />
            )}
            {id && btnNameDetector === 2 && notCreating && (
              <IwdAuthWrapper
                children={
                  <DeleteButton
                    name={t("delete_subblock")}
                    styles={{ mt: 2 }}
                    onClick={() =>
                      onDeleteClick(subCondominiumIdentifier, "subcondominum")
                    }
                  />
                  /*                   <Button
                    sx={{
                      mt: 2,
                      
                    }}
                    variant={window.BUTTON_TYPE}
                    onClick={() =>
                      onDeleteClick(subCondominiumIdentifier, "subcondominum")
                    }
                  >
                    {t("delete_subblock")}
                  </Button> */
                }
                section={"delete_condominum"}
              />
            )}
            {id && btnNameDetector === 3 && notCreating && !gatewayMode && (
              <IwdAuthWrapper
                children={
                  <DeleteButton
                    name={t("delete_apartment")}
                    styles={{ mt: 2 }}
                    onClick={() =>
                      onDeleteClick(apartamentIdentifier, "apartment")
                    }
                  />
                  /* <Button
                    sx={{
                      mt: 2,
                      
                    }}
                    variant={window.BUTTON_TYPE}
                    onClick={() =>
                      onDeleteClick(apartamentIdentifier, "apartment")
                    }
                  >
                    {t("delete_apartment")}
                  </Button> */
                }
                section={"delete_condominum"}
              />
            )}
          </>
        }
      />
    </Box>
  );
};
