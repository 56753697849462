import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { Box } from "@mui/system";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../form/formComponents/DatePicker";
import Point from "../form/formComponents/Point";
import WsConnector from "../../utils/WsConnector";
import Crone from "../Crone";
import ModalDialog from "../meters/ModalDialog";
import { usePostCampaignMutation } from "../../redux/slices/CampaignApi";
import CampaignMapContainer from "./CampaignMapContainer";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { v4 as uuidv4 } from "uuid";
import { size } from "lodash";

const CampaignUpdateForm = ({ startdDate, endDate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const schema = {
    type: "object",
    properties: {
      insertion_date: {
        $id: "3",
        title: t("inserted_at"),
        type: "object",

        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },

      /*  required: ["insertion_date"], */
    },
  };
  const fields = {
    datepicker: DatePicker,
  };
  const uiSchema = {
    /*   "ui:ObjectFieldTemplate": ObjectFieldTemplate, */

    "ui:submitButtonOptions": {
      submitText: t("update"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    insertion_date: {
      "ui:field": "datepicker",
    },
  };

  const customValidate = (formData, errors) => {
    if (formData.insertion_date.from && formData.insertion_date.to) {
      if (
        formData.insertion_date.from !== "" &&
        formData.insertion_date.to !== "" &&
        formData.insertion_date.from >= formData.insertion_date.to
      ) {
        errors.insertion_date.to.addError(t("date_error"));
      }
    } else {
      if (formData.insertion_date.from && !formData.insertion_date.to) {
        errors.insertion_date.to.addError(t("missing_endDate"));
      }
      if (!formData.insertion_date.from && formData.insertion_date.to) {
        errors.insertion_date.from.addError(t("missing_startDate"));
      }
    }
    if (formData.insertion_date.from <= endDate) {
      errors.insertion_date.from.addError(t("upd_startDate_err"));
    }

    return errors;
  };
  const onSubmitClick = (val) => {};
  return (
    <Box sx={{ mt: 2 }}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        onSubmit={onSubmitClick}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        validator={validator}
        customValidate={customValidate}
        showErrorList={false}
        noHtml5Validate
      />
    </Box>
  );
};

export default CampaignUpdateForm;
