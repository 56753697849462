import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../../theme";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { setDexRecords } from "../../redux/slices/dexDataSourceSlice";
import InfiniteTable from "../../pages/InfiniteTable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { setDexPage } from "../../redux/slices/dexDataSourceSlice";
import { Tooltip, Typography } from "@mui/material";

const DexTable = ({ page, setPage, perPage }) => {
  const dexData = useSelector((state) => state?.dexDataSourceSlice?.dex);
  const rowCount = useSelector((state) => state?.dexDataSourceSlice?.count);
  const haveRecords = useSelector(
    (state) => state?.dexDataSourceSlice?.haveRecords
  );
  const executed = useSelector((state) => state?.dexDataSourceSlice?.executed);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  /*   const page = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.page
  );
  const perPage = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.per_page
  ); */

  const columns =
    dexData?.length > 0 &&
    Object?.keys(dexData?.[0])?.map((column) => {
      return {
        field: column,
        headerName: t(column),
        editable: false,
        sortable: false,
        hide: column == "id",
        flex: column == "message_date" || column == "inserted_at" ? 4 : 2,
        renderCell: (params) => {
          if (typeof params.value === "boolean") {
            return params.value ? <ErrorIcon sx={{ fill: "red" }} /> : "--";
          }

          if (column === "alarms") {
            let alarms = [];
            try {
              alarms = JSON.parse(params?.value || "[]");
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {alarms?.length > 0
                  ? alarms?.map((item, index) => (
                      <Chip
                        key={index}
                        sx={{
                          background: theme.palette.error.light,
                          color: theme.palette.error.contrastText,
                          mt: 1,
                        }}
                        label={item}
                      />
                    ))
                  : "--"}
              </Box>
            );
          }
          if (typeof params.value === "object" && params.value) {
            return (
              <ul style={{ padding: 0 }}>
                {Object.keys(params.value)?.map((k) => (
                  <li>
                    {k}: {params.value[k]}
                  </li>
                ))}
              </ul>
            );
          }

          return params.value;
        },
      };
    });

  const fakeData = [
    {
      alarms: '["empty_pipe"]',
      class: "MAD:80:07",
      device_id: 44738,
      device_serial_number: "19060025",
      gateway_serial_number: "008EAC",
      id: 3,
      in_error: true,
      message_date: "2024-02-12T10:30:12.000000",
      parent_device_id: 956,
      unit: "m^3",
      value: "89.458",
    },
    {
      alarms: '["empty_pipe"]',
      class: "MAD:80:07",
      device_id: 44738,
      device_serial_number: "19060025",
      gateway_serial_number: "008EAC",
      id: 6,
      in_error: true,
      message_date: "2024-02-12T10:30:12.000000",
      parent_device_id: 956,
      unit: "m^3",
      value: "89.458",
    },
    {
      alarms: '["reverse_flux"]',
      class: "MAD:01:07",
      device_id: 507,
      device_serial_number: "23001756",
      gateway_serial_number: "009470",
      id: 10,
      in_error: true,
      message_date: "2024-02-08T11:34:54.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.039",
    },
    {
      alarms: '["reverse_flux"]',
      class: "MAD:01:07",
      device_id: 507,
      device_serial_number: "23001756",
      gateway_serial_number: "009470",
      id: 9,
      in_error: true,
      message_date: "2024-02-08T11:34:54.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.039",
    },
    {
      alarms: '["reverse_flux"]',
      class: "MAD:01:07",
      device_id: 487,
      device_serial_number: "23001724",
      gateway_serial_number: "009470",
      id: 3,
      in_error: true,
      message_date: "2024-02-08T11:31:47.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.037",
    },
    {
      alarms: '["reverse_flux"]',
      class: "MAD:01:07",
      device_id: 487,
      device_serial_number: "23001724",
      gateway_serial_number: "009470",
      id: 9,
      in_error: true,
      message_date: "2024-02-08T11:31:47.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.037",
    },
    {
      alarms:
        '["reverse_flux","reverse_flux","reverse_flux","reverse_flux","reverse_flux","reverse_flux","reverse_flux","reverse_flux","reverse_flux","reverse_flux","reverse_flux"]',
      class: "MAD:01:07",
      device_id: 503,
      device_serial_number: "23001676",
      gateway_serial_number: "009470",
      id: 10,
      in_error: true,
      message_date: "2024-02-08T11:31:15.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.034",
    },
    {
      alarms: '["reverse_flux"]',
      class: "MAD:01:07",
      device_id: 503,
      device_serial_number: "23001676",
      gateway_serial_number: "009470",
      id: 5,
      in_error: true,
      message_date: "2024-02-08T11:31:15.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.034",
    },
    {
      alarms: '["reverse_flux"]',
      class: "MAD:01:07",
      device_id: 511,
      device_serial_number: "23001753",
      gateway_serial_number: "009470",
      id: 2,
      in_error: true,
      message_date: "2024-02-08T11:30:10.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.04",
    },
    {
      alarms: '["reverse_flux"]',
      class: "MAD:01:07",
      device_id: 511,
      device_serial_number: "23001753",
      gateway_serial_number: "009470",
      id: 2,
      in_error: true,
      message_date: "2024-02-08T11:30:10.000000",
      parent_device_id: 381,
      unit: "m^3",
      value: "0.04",
    },
  ];
  const tableRows = dexData?.map((item, key) => ({
    id: key,
    device_id: item?.device_id,
    in_error: item?.in_error ?? "--",
    inserted_at: t("dt", { val: new Date(item?.inserted_at) }),
    message_date: t("dt", { val: new Date(item?.message_date) }),
    parent_device_id: item?.parent_device_id,
    unit: item?.unit == "m^3" ? "m³" : item?.unit,
    volume: item?.volume ?? "--",
    alarms: item?.alarms ?? "--",
    class: item?.class ?? "--",
    device_serial_number: item?.device_serial_number ?? "--",
    gateway_serial_number: item?.gateway_serial_number ?? "--",
    serial_number: item?.serial_number ?? "--",
    uptime: item?.uptime ?? "--",
    battery_level: item?.battery_level ?? "--",
    value: t("number", {
      val: +item?.value,
    }),
    count: item ?? "--",
  }));
  useEffect(() => {}, [tableRows]);
  return (
    <>
      {/*     <Box sx={{ height: 610 }}>
      <StyledDataGrid
        rows={tableRows?.length > 0 ? tableRows : []}
        columns={columns?.length > 0 ? columns : []}
        pageSize={perPage}
        rowCount={rowCount}
        page={page - 1}
        rowsPerPageOptions={[perPage]}
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        disableSelectionOnClick
        disableColumnSelector
        disableDensitySelector
        getRowHeight={() => "auto"}
        disableColumnMenu
        paginationMode="server"
      />
    </Box> */}
      {executed && (
        <InfiniteTable
          styles={{ height: 622, mt: 2 }}
          rows={tableRows ?? []}
          columns={columns?.length > 0 ? columns : []}
          page={page}
          perPage={perPage}
          setPage={setPage}
          /*    isSuccess={isFetching} */
          paginationMode={"server"}
          /*   dataLength={balances?.length} */
          records={haveRecords}
          recordsFn={setDexRecords}
        />
      )}
    </>
  );
};

export default DexTable;
